import { create } from 'zustand';
import { DEFAULT_LANGUAGE } from 'src/configs/LocaleConfig';
import { i18n, locale } from 'src/utils/locale';
import { isBrowser } from 'src/utils/env';
import moment from 'src/utils/moment';

type LocaleState = {
  language: string;
  setLanguage: (language: string) => void;
  initialize: () => void;
};

export const useLocaleStore = create<LocaleState>((set, get) => ({
  language: DEFAULT_LANGUAGE,

  // Reducer pour changer la langue
  setLanguage: (language) => {
    set({ language });
    const newLocale = locale.getLocale(language);
    i18n.locale = newLocale;
    moment.locale(newLocale);
  },

  // Initialisation et écouteurs
  initialize: () => {
    if (isBrowser()) {
      const browserLanguage = window.navigator.language;
      get().setLanguage(browserLanguage);

      window.addEventListener('languagechange', () => {
        const browserLanguage = window.navigator.language;
        get().setLanguage(browserLanguage);
      });
    }
  },
}));

// Utilisation du magasin :
// const { language, setLanguage, initialize } = useLocaleStore();

//Pour initialiser, vous pouvez appeler `initialize` dans un effet au démarrage de votre application.
// useEffect(() => {
//   initialize();
// }, []);
