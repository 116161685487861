import React, { ReactNode } from 'react';

import imageList from 'src/assets/images';

import { ImageNameProp } from 'src/types/image';

import { useTheme, Image, ViewProps } from 'app-studio';

import { Center } from 'src/components/Layout';

import svg from 'src/assets/svg';

import { SvgNameProp } from 'src/types/svg';

export const AssetIcon = ({
  name,
  color,
  ...props
}: {
  name: SvgNameProp;
  color?: string;
} & ViewProps) => {
  const { getColor } = useTheme();

  const SVGIcon = svg[name];

  const Colorprops = color ? { fill: getColor(color), stroke: getColor(color) } : {};

  return SVGIcon ? (
    <Center {...props}>
      <SVGIcon
        {...Colorprops}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Center>
  ) : null;
};

export const AssetImage = ({
  name,
  ...props
}: {
  name: ImageNameProp;
} & ViewProps) => {
  const image = imageList[name];

  return <Image src={image} {...props} />;
};
