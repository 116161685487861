import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { Header, PageHeader } from 'src/components/Header';
import { useProjectStates } from './applicationDetails.state';
import { Section } from './applicationDetails.element';
import { useApplicationDetailssRequests } from './applicationDetails.request';
import { useMount } from 'app-studio';
import { useParams } from 'react-router-dom';
import { H5 } from 'src/components/Text';

const ApplicationDetailsPage = () => {
  let section = { id: '', content: '', title: '' };

  const { id, fileId } = useParams();
  const { isEditing, setIsEditing } = useProjectStates();

  const editorCallback = (content) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const updateCallback = () => {
    getApplicationRequest.run(id);
    setIsEditing(false);
    hideModal();
  };

  const applicationDetailsRequests = useApplicationDetailssRequests({ editorCallback, updateCallback });

  const { getApplicationRequest, getGrantRequest, updateApplicationFileSection, generateContentRequest } =
    applicationDetailsRequests;

  useMount(() => {
    getApplicationRequest.run(id);
  });

  const handleButtonSave = () => {
    updateApplicationFileSection.run(section.id, { content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  const handleSubmit = (event) => {
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt: event.prompt, content: section.content });
  };

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        sectionTitle: title,
        isLoading: isLoading,
        sectionContent: content,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: (editedContent: string) => {
          section.content = editedContent;
        },
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const isLoaded =
    !!!getApplicationRequest.loader &&
    getApplicationRequest &&
    getGrantRequest &&
    getApplicationRequest.data &&
    getGrantRequest.data;

  const sections = getApplicationRequest?.data?.applicationFileSections.filter(
    (section) => section.grantFileId === fileId
  );

  // const file = getGrantRequest?.data?.grantFiles.find((file) => file.id === fileId);
  // const fileName = file ? file.name : '-';

  return (
    <Vertical width="100%" padding="60px 0px">
      <PageHeader
        prefixTitle={i18n.t('page.application.application')}
        title={getApplicationRequest?.data?.name ?? ''}
        paddingBottom={50}
        paddingLeft={40}
        regenerateClick={() => {}}
      />
      {isLoaded ? (
        sections.length > 0 &&
        sections.map((section) => (
          <Section
            id={section.id}
            key={section.id}
            sectionId={section.id}
            isEditing={isEditing}
            title={section.title}
            editorIconCallback={handleEditorSelection}
            content={section.content}
          />
        ))
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};

export default ApplicationDetailsPage;
