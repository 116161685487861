/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductParams } from '../models/CreateProductParams';
import type { CreateProductSectionParams } from '../models/CreateProductSectionParams';
import type { UpdateProductParams } from '../models/UpdateProductParams';
import type { UpdateProductSectionParams } from '../models/UpdateProductSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create a product
 * @param requestBody
 * @returns any product created
 * @throws ApiError
 */
export const productControllerCreateProduct = (requestBody: CreateProductParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/product`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific product
 * @param id
 * @returns any product details fetched
 * @throws ApiError
 */
export const productControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/product/${id}`,
    errors: {
      404: `product not found`,
    },
  });
};

/**
 * As an admin, I want to delete a product
 * @param id
 * @returns any product deleted
 * @throws ApiError
 */
export const productControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/product/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `product not found`,
    },
  });
};

/**
 * As an admin, I want to update a product
 * @param id
 * @param requestBody
 * @returns any product updated
 * @throws ApiError
 */
export const productControllerUpdate = (id: string, requestBody: UpdateProductParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/product/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a productSection
 * @param requestBody
 * @returns any productSection created
 * @throws ApiError
 */
export const productSectionControllerCreate = (requestBody: CreateProductSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/product/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific productSection
 * @param id
 * @returns any productSection details fetched
 * @throws ApiError
 */
export const productSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/product/section/${id}`,
    errors: {
      404: `productSection not found`,
    },
  });
};

/**
 * As an admin, I want to delete a productSection
 * @param id
 * @returns any productSection deleted
 * @throws ApiError
 */
export const productSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/product/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `productSection not found`,
    },
  });
};

/**
 * As an admin, I want to update a productSection
 * @param id
 * @param requestBody
 * @returns any productSection updated
 * @throws ApiError
 */
export const productSectionControllerUpdate = (
  id: string,
  requestBody: UpdateProductSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/product/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useProductControllerCreateProductService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateProductParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productControllerCreateProduct, { method, ...options });
};

export const useProductControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productControllerGet, { method, ...options });
};

export const useProductControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productControllerDelete, { method, ...options });
};

export const useProductControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProductParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productControllerUpdate, { method, ...options });
};

export const useProductSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateProductSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerCreate, { method, ...options });
};

export const useProductSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerGet, { method, ...options });
};

export const useProductSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerDelete, { method, ...options });
};

export const useProductSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProductSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerUpdate, { method, ...options });
};
