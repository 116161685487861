import { AssistantService, CustomerService, OfferService } from 'src/services/api';

export const useOffersRequests = ({ handleEditorCallback, editorCallback, getAllCustomerCallback, refresh }) => {
  const getOfferRequest = OfferService.useOfferControllerGetService({});

  const getAllOfferRequest = OfferService.useOfferControllerReadAllService({
    onSuccess: (data) => {
      console.log('inside data');
    },
  });

  const updateSectionRequest = OfferService.useOfferSectionControllerUpdateService({
    onSuccess: () => {
      handleEditorCallback();
    },
  });

  const createOfferRequest = OfferService.useOfferControllerCreateOfferService({
    onSuccess: () => {
      refresh();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  const deleteOfferRequest = OfferService.useOfferControllerDeleteService({
    onSuccess: () => {
      refresh();
    },
  });

  const getAllCustomerRequest = CustomerService.useCustomerControllerReadAllService({
    onSuccess: (data) => {
      getAllCustomerCallback(data);
    },
  });

  return {
    createOfferRequest,
    getAllOfferRequest,
    getOfferRequest,
    updateSectionRequest,
    generateContentRequest,
    deleteOfferRequest,
    getAllCustomerRequest,
  };
};
