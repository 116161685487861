import React from 'react';
import { Horizontal, Vertical, Center, showModal } from '@app-studio/web';

import i18n from 'src/utils/locale';
import { SvgNameProp } from 'src/types/svg';

import { extractInitial } from 'src/utils/format';
import { SVGIcon } from 'src/components/Icon';
import { C4, C2 } from 'src/components/Text';

import { View } from 'app-studio';

export const HeaderSwitcher = ({ user, headerText, onHeaderClick, ...props }) => {
  return (
    <Horizontal gap={10} padding="20px 30px" alignItems="center" wrap="nowrap" {...props} onClick={onHeaderClick}>
      <Center height="40px" width="40px" borderRadius="50%" backgroundColor="theme.primary">
        <C2 color="white"> {i18n.t('page.grant.initial', { initial: extractInitial(user?.name) })}</C2>
      </Center>
      <C4 whiteSpace="nowrap" color="white">
        {headerText}
      </C4>
    </Horizontal>
  );
};

export const MenuScrollIcon = ({
  icon,
  handleMenuScrollClick,
}: {
  icon: SvgNameProp;
  handleMenuScrollClick?: Function;
}) => {
  return (
    <Horizontal
      padding="15px 30px"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      backgroundColor="color.dark.90"
    >
      <SVGIcon name={icon} onClick={handleMenuScrollClick} />
    </Horizontal>
  );
};

export const AddProjectMenu = (props) => {
  return (
    <Center wrap="nowrap" padding={10} {...props}>
      <SVGIcon name="AddAppSvg" size={30} />
    </Center>
  );
};

export const AccountMenu = (props) => {
  return (
    <Center wrap="nowrap" padding={10} {...props}>
      <SVGIcon name="WhiteProfileSvg" size={30} />
    </Center>
  );
};

export const AppMenu = ({
  selected,
  projects,
  handleAppClick,
}: {
  selected?: string;
  projects: any[];
  handleAppClick: Function;
}) => {
  return (
    <Vertical justifyContent="flex-start" borderTopLeftRadius={10} paddingLeft={10} gap={10}>
      {projects.length > 0 &&
        projects.map((project) => {
          const href = `/${project.id}/project/overview`;
          return project.id === selected ? null : (
            <Menu
              key={project.id}
              isHighlighted={selected === project.id}
              href={href}
              icon={project?.projectMedias?.find((media) => media.name === 'logo')?.json.color.url ?? 'WhiteRocketSvg'}
              handleClick={() =>
                handleAppClick({ href, project, path: project.id, name: project.name, id: project.id })
              }
            />
          );
        })}
      <View flex={1} />
    </Vertical>
  );
};

export const SectionMenu = ({
  project,
  selected,
  handleActionClick,
}: {
  project: any;
  selected?: string;
  handleActionClick: Function;
}) => {
  const projectId = project.id;

  const actions = [
    {
      name: 'Project',
      type: 'app',
      icon: 'WhiteFolderSvg',
      path: 'project',
      href: `/${projectId}/project/overview`,
      category: 'folder',
    },
    {
      name: 'Dev',
      path: 'dev',
      icon: 'CubeSvg',
      href: `/${projectId}/dev/landing`,
      category: 'folder',
    },
    {
      name: 'Fund',
      path: 'fund',
      icon: 'FundSvg',
      href: `/${projectId}/fund/grant/grant`,
      category: 'folder',
    },
    {
      name: 'Social',
      path: 'social',
      icon: 'MessageSvg',
      href: `/${projectId}/social/newsletter`,
      category: 'folder',
    },
  ];

  return (
    <Vertical
      wrap="nowrap"
      justifyContent="flex-start"
      backgroundColor="color.dark.90"
      borderTopLeftRadius={10}
      paddingLeft={10}
      paddingRight={20}
      paddingVertical={10}
      marginRight={-10}
    >
      {actions &&
        actions.map((action) => (
          <ActionItem
            menu={action}
            key={`${action.name}`}
            isHighlighted={selected === action.path}
            handleClick={() => {
              handleActionClick(action);
            }}
          />
        ))}
    </Vertical>
  );
};

export const AssistantMenu = ({
  project,
  sectionName,
  selected,
  handleAssistantClick,
}: {
  project: any;
  sectionName: string;
  selected?: string;
  handleAssistantClick: Function;
}) => {
  if (!project) return null;
  const assistants = {
    project: [
      {
        name: project.name,
        path: 'overview',
        icon: 'NewsletterSvg',
        href: `/${project.id}/project/overview`,
      },

      {
        name: 'Brand',
        type: 'app',
        icon: 'BrandSvg',
        path: 'brand',
        href: `/${project.id}/project/brand/brand`,
        category: 'folder',
      },
      {
        name: 'Strategy',
        type: 'app',
        icon: 'StrategySvg',
        path: 'strategy',
        href: `/${project.id}/project/strategy/market`,
        category: 'folder',
      },
    ],
    dev: [
      {
        name: 'Landing',
        icon: 'NewsletterSvg',
        path: 'landing',
        type: 'app',
        href: `/${project.id}/dev/landing`,
      },
    ],
    fund: [
      {
        name: 'Grant',
        path: 'grant',
        icon: 'NewsletterSvg',
        href: `/${project.id}/fund/grant`,
      },
    ],
    social: [
      {
        name: 'Newsletter',
        icon: 'NewsletterSvg',
        type: 'app',
        path: 'newsletter',
        href: `/${project.id}/social/newsletter`,
        category: 'assistant',
      },
    ],
  };

  return (
    <Vertical
      wrap="nowrap"
      borderTopLeftRadius={10}
      paddingLeft={10}
      paddingRight={20}
      paddingVertical={10}
      marginRight={-10}
      width={120}
      backgroundColor="color.dark.80"
    >
      {assistants[sectionName] &&
        assistants[sectionName].map((assistant, index) => (
          <AssistantItem
            key={index}
            menu={assistant}
            isHighlighted={selected === assistant.path}
            handleClick={() => {
              handleAssistantClick(assistant);
            }}
          />
        ))}
    </Vertical>
  );
};

export const AssitantActionMenu = ({
  project,
  assistantName,
  selected,
  handleAssistantClick,
}: {
  project: any;
  assistantName: string;
  selected?: string;
  handleAssistantClick: Function;
}) => {
  if (!project) return null;
  const actions = {
    grant: [
      {
        name: 'Enterprise',
        path: 'enterprise',
        icon: 'CubeSvg',
        href: `/${project.id}/fund/grant/enterprise`,
      },
      {
        name: 'Grant',
        path: 'grant',
        icon: 'NewsletterSvg',
        href: `/${project.id}/fund/grant/grant`,
      },
      {
        name: 'Application',
        icon: 'EditSvg',
        path: 'application',
        type: 'app',
        href: `/${project.id}/fund/grant/application`,
      },
    ],
    brand: [
      {
        name: 'Overview',
        icon: 'BrandSvg',
        type: 'app',
        path: 'brand',
        category: 'assistant',
        href: `/${project.id}/project/brand/brand`,
      },
      {
        name: 'Visual Identity',
        path: 'visualIdentity',
        icon: 'OfferSvg',
        type: 'app',
        href: `/${project.id}/project/brand/visualIdentity`,
      },
      {
        name: 'Positioning',
        icon: 'TargetSvg',
        type: 'app',
        path: 'strategy',
        category: 'assistant',
        href: `/${project.id}/project/brand/strategy`,
      },
      {
        name: 'Messaging',
        icon: 'MessageSvg',
        type: 'app',
        path: 'messaging',
        category: 'assistant',
        href: `/${project.id}/project/brand/messaging`,
      },
      // {
      //   name: 'Visual Identity',
      //   icon: 'IdentitySvg',
      //   type: 'app',
      //   path: 'identity',
      //   category: 'assistant',
      //   href: `/${project.id}/brand/identity`,
      // },
    ],
    strategy: [
      {
        name: 'Market',
        icon: 'WhiteDollarSvg',
        type: 'app',
        path: 'market',
        href: `/${project.id}/project/strategy/market`,
        category: 'assistant',
      },
      {
        name: 'Target Customer',
        icon: 'WhiteTargetSvg',
        type: 'app',
        path: 'customer',
        href: `/${project.id}/project/strategy/customer`,
        category: 'assistant',
      },
      {
        name: 'Product',
        icon: 'WhiteProductSvg',
        type: 'app',
        path: 'product',
        href: `/${project.id}/project/strategy/product`,
        category: 'assistant',
      },
      {
        name: 'Offer',
        icon: 'WhiteTagSvg',
        type: 'app',
        path: 'offer',
        href: `/${project.id}/project/strategy/offer`,
        category: 'assistant',
      },
    ],
  };

  if (!actions[assistantName] || actions[assistantName].length == 0) return null;
  return (
    <Vertical
      wrap="nowrap"
      borderTopLeftRadius={10}
      paddingLeft={10}
      paddingRight={20}
      paddingVertical={10}
      marginRight={-10}
      width={120}
      backgroundColor="color.dark.70"
    >
      {actions[assistantName] &&
        actions[assistantName].map((assistant, index) => (
          <ActionAssistantItem
            key={index}
            menu={assistant}
            isHighlighted={selected === assistant.path}
            handleClick={() => {
              handleAssistantClick(assistant);
            }}
          />
        ))}
    </Vertical>
  );
};

export const AssistantItem = ({
  isHighlighted,
  menu,
  handleClick,
  ...props
}: {
  isHighlighted: boolean;
  menu: any;
  handleClick: Function;
  [x: string]: any;
}) => {
  return (
    <Vertical
      key={menu.href}
      padding="20px 15px"
      gap={15}
      alignItems="center"
      wrap="wrap"
      backgroundColor={isHighlighted ? 'color.dark.90' : 'transparent'}
      textAlign="center"
      borderRadius={10}
      onClick={() => {
        handleClick(menu);
      }}
      {...props}
    >
      <SVGIcon name={menu.icon} color="white" />
      <C4 color="white">{menu.name}</C4>
    </Vertical>
  );
};

export const ActionAssistantItem = ({
  isHighlighted,
  menu,
  handleClick,
  ...props
}: {
  isHighlighted: boolean;
  menu: any;
  handleClick: Function;
  [x: string]: any;
}) => {
  return (
    <Vertical
      key={menu.href}
      padding="20px 15px"
      gap={15}
      alignItems="center"
      wrap="wrap"
      backgroundColor={isHighlighted ? 'color.dark.90' : 'transparent'}
      textAlign="center"
      borderRadius={10}
      onClick={() => {
        handleClick(menu);
      }}
      {...props}
    >
      <SVGIcon name={menu.icon} color="white" />
      <C4 color="white">{menu.name}</C4>
    </Vertical>
  );
};

export const ActionItem = ({
  isHighlighted,
  menu,
  handleClick,
  ...props
}: {
  isHighlighted: boolean;
  menu: any;
  handleClick: Function;
  [x: string]: any;
}) => {
  return (
    <Vertical
      key={menu.href}
      padding="20px 15px"
      gap={15}
      alignItems="center"
      backgroundColor={isHighlighted ? 'color.dark.70' : 'transparent'}
      wrap="wrap"
      borderRadius={10}
      textAlign="center"
      onClick={() => {
        handleClick(menu);
      }}
      {...props}
    >
      <SVGIcon name={menu.icon} />
      <C4 color="white">{menu.name}</C4>
    </Vertical>
  );
};

export const Menu = ({
  isHighlighted,
  href,
  icon,
  handleClick,
  ...props
}: {
  isHighlighted: boolean;
  href: string;
  icon: SvgNameProp;
  handleClick: Function;
  [x: string]: any;
}) => {
  const isimageIcon = icon.includes('https://res.cloudinary.com');
  return (
    <Vertical
      key={href}
      padding={isimageIcon ? 0 : '15px 5px'}
      wrap="wrap"
      borderRadius={10}
      border={'1px solid #222836'}
      marginRight={5}
      opacity={0.8}
      alignItems="center"
      textAlign="center"
      onClick={() => {
        handleClick({ href });
      }}
      {...props}
    >
      {icon.includes('https://res.cloudinary.com') ? (
        <ImageIcon imageIcon={icon} height={60} width={60} />
      ) : (
        <SVGIcon name={icon} size={60} />
      )}
    </Vertical>
  );
};

export const MainApp = ({
  project,
  handleClick,
  ...props
}: {
  project: any;
  handleClick: Function;
  [x: string]: any;
}) => {
  if (!project) return null;

  const href = `/${project.id}/project/overview`;
  const imageIcon = project?.projectMedias?.find((media) => media.name === 'logo')?.json.color.url;

  return (
    <Horizontal key={href} padding={'10px 10px'} borderRadius={10} alignItems="center" {...props}>
      <Horizontal
        wrap="wrap"
        gap={15}
        alignItems="center"
        width={280}
        onClick={() => {
          handleClick({ href });
        }}
      >
        {imageIcon ? <ImageIcon imageIcon={imageIcon} /> : <SVGIcon name={'WhiteRocketSvg'} />}
        <C4 color="white">{project.name}</C4>
      </Horizontal>
      <View
        backgroundColor={'color.dark.70'}
        borderRadius={10}
        padding={10}
        onClick={() => showModal('ListProjectModal')}
      >
        <SVGIcon name={'SwitchSvg'} />
      </View>
    </Horizontal>
  );
};

const ImageIcon = ({ imageIcon, height = 80, width = 80 }) => {
  return (
    <View
      backgroundImage={`url(${imageIcon})`}
      width={width}
      height={height}
      backgroundRepeat="no-repeat"
      backgroundPosition={'center center'}
      backgroundSize={'90%'}
      borderRdaius={10}
    />
  );
};
