interface EnvVars {
  API_URL: string;
  REACT_APP_API_URL: string;
  REACT_APP_NODE_ENV: string;
}

const env: EnvVars = process.env as any;

export const APP_NAME = 'Startup Studio';

export const DEV = window.location.host.indexOf('localhost') >= 0;

export const API_URL = env.REACT_APP_API_URL ? env.REACT_APP_API_URL : 'http://localhost:3000';

export const DEFAULT_LANGUAGE = 'en';
export const PWA = true;

export const AMPLITUDE_KEY = '';
export const BUGSNAG_KEY = '';
export const SMARTLOOK_KEY = '';
export const ONESIGNAL_APP_ID = '';
export const GOOGLE_ANALYTICS_ID = '';
export const GOOGLE_OPTIMIZE_ID = '';
export const GOOGLE_MAPS_KEY = '';
export const PUBLIC_STRIPE_KEY = 'pk_TxAGfn0SnPyRmJZ09KCfI2iBWupiz';
