import { AssistantService, NewsletterService } from 'src/services/api';

export const useNewsletterEditorRequests = ({ refresh, editorCallback }) => {
  const getNewsletterRequest = NewsletterService.useNewsletterControllerReadService({});

  const createContentRequest = NewsletterService.useNewsletterControllerGenerateContentService({
    onSuccess: () => {
      refresh();
    },
  });

  const updateSectionRequest = NewsletterService.useNewsletterSectionControllerUpdateService({
    onSuccess: (data) => {
      console.log(data);
      refresh();
    },
  });
  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getNewsletterRequest,
    updateSectionRequest,
    createContentRequest,
    generateContentRequest,
  };
};
