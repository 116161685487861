import { useParams } from 'react-router-dom';
import { BrandService, ProjectService } from 'src/services/api';
import { useAppStore } from 'src/stores/AppStore';

export const useVisualIdentityRequests = () => {
  const { projectId } = useParams();
  const { setProject } = useAppStore();

  const getProjectRequest = ProjectService.useProjectControllerRetrieveProjectService({
    onSuccess: (data) => {
      setProject(data);
    },
  });
  const regenerateVisualIdentityRequest = BrandService.useBrandControllerRegenerateIndentityService({
    onSuccess: () => {
      getProjectRequest.run(projectId);
    },
  });
  return { regenerateVisualIdentityRequest };
};
