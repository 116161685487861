import React from 'react';
import { Text, TextProps } from 'app-studio';
import { Typography } from 'src/components/Typography';

export const H1 = (props: TextProps) => <Text {...Typography.H1} {...props} />;
export const H2 = (props: TextProps) => <Text {...Typography.H2} {...props} />;
export const H3 = (props: TextProps) => <Text {...Typography.H3} {...props} />;
export const H4 = (props: TextProps) => <Text {...Typography.H4} {...props} />;
export const H5 = (props: TextProps) => <Text {...Typography.H5} {...props} />;
export const H6 = (props: TextProps) => <Text {...Typography.H6} {...props} />;
export const H7 = (props: TextProps) => <Text {...Typography.H7} {...props} />;
export const Label = (props: TextProps) => <Text {...Typography.Label} {...props} />;
export const C1 = (props: TextProps) => <Text {...Typography.C1} {...props} />;
export const C2 = (props: TextProps) => <Text {...Typography.C2} {...props} />;
export const C3 = (props: TextProps) => <Text {...Typography.C3} {...props} />;
export const C4 = (props: TextProps) => <Text {...Typography.C4} {...props} />;
export const C5 = (props: TextProps) => <Text {...Typography.C5} {...props} />;
