import React from 'react';
import { Modal, hideModal } from '@app-studio/web';

import { H3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';
import { CreateForm } from 'src/forms/CreateForm';

export const CreateModal = ({ initialValues, handleSubmit, title, fields }) => {
  return (
    <Modal.Container padding={20} boxShadow="none" border="1px solid black">
      <Modal.Header buttonPosition="none" padding={'20px 0'} position="relative">
        <H3>{title}</H3>
        <AssetIcon
          name="BlackCloseSvg"
          width={16}
          height={16}
          position="absolute"
          top={5}
          right={5}
          onClick={hideModal}
          color="black"
        />
      </Modal.Header>
      <Modal.Body border="none">
        <CreateForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleCancel={hideModal}
          fields={fields}
          submitButtonLabelKey="button.create"
          cancelButtonLabelKey="action.cancel"
        />
      </Modal.Body>
    </Modal.Container>
  );
};
