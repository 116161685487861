import { EnterpriseService } from 'src/services/api';

export const useEnterpriseRequests = () => {
  const createEnterpriseRequest = EnterpriseService.useEnterpriseControllerCreateEnterpriseService({
    onSuccess: () => {
      getEnterpriseRequest.run();
    },
  });

  const getEnterpriseRequest = EnterpriseService.useEnterpriseControllerRetrieveEnterpriseService({});

  const updateEnterpriseRequest = EnterpriseService.useEnterpriseControllerUpdateEnterpriseService({
    onSuccess: () => {
      getEnterpriseRequest.run();
    },
  });

  return {
    createEnterpriseRequest,
    getEnterpriseRequest,
    updateEnterpriseRequest,
  };
};
