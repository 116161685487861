import { hideModal } from '@app-studio/web';
import { useNavigate } from 'react-router-dom';
import { ProjectService, ProjecSupplementaryInfoService, WorkflowService } from 'src/services/api';
import { useAppStore } from 'src/stores/AppStore';

export const useProjectRequests = ({
  createProjectRequestCallback,
  getWorkflowCallback,
}: {
  createProjectRequestCallback?: Function;
  getWorkflowCallback?: Function;
}) => {
  const { setProjects, setProject, projects } = useAppStore();

  const getAllProjectRequest = ProjectService.useProjectControllerListAllProjectsService({
    onSuccess: (data) => {
      setProjects(data);
    },
  });

  const createProjectRequest = ProjectService.useProjectControllerGenerateService({
    onSuccess: (data) => {
      if (createProjectRequestCallback) {
        createProjectRequestCallback(data);
      }
      getAllProjectRequest.run();
    },
    onError: (err) => {
      hideModal();
    },
  });

  const deleteProjectRequest = ProjectService.useProjectControllerRemoveProjectService({
    onSuccess: () => {
      // getAllProjectRequest.run();
    },
  });

  const createAdditionalInfoRequest = ProjecSupplementaryInfoService.useProjectSupplementaryInfoControllerCreateService(
    {}
  );

  const getWorkflowRequest = WorkflowService.useWorkflowControllerGetService({
    onSuccess: (data) => {
      if (getWorkflowCallback) {
        getWorkflowCallback(data);
      }
    },
  });

  return {
    getAllProjectRequest,
    createProjectRequest,
    deleteProjectRequest,
    createAdditionalInfoRequest,
    getWorkflowRequest,
  };
};
