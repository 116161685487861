/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { getTaskParams } from '../models/getTaskParams';
import type { RequestTask } from '../models/RequestTask';
import type { UpdateTaskStatus } from '../models/UpdateTaskStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * Create a new workflow and the first task
 * @returns any Workflow created
 * @throws ApiError
 */
export const workflowControllerStart = (): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/workflow/start`,
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, i want to read an workflow
 * @param id
 * @returns any Workflow's data
 * @throws ApiError
 */
export const workflowControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/workflow/${id}`,
    errors: {
      404: `Workflow doesn't exists`,
    },
  });
};

/**
 * Request a new task
 * @param requestBody
 * @returns any Workflow created
 * @throws ApiError
 */
export const workflowControllerRequestTask = (requestBody: RequestTask): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/workflow/task/request`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * get a task
 * @param taskId taskId
 * @param resetNbOfExecution resetNbOfExecution
 * @returns any
 * @throws ApiError
 */
export const workflowControllerGetTask = (taskId: string, resetNbOfExecution?: boolean): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/workflow/task`,
    query: {
      taskId: taskId,
      resetNbOfExecution: resetNbOfExecution,
    },
  });
};

/**
 * get a workflow tasks
 * @param workflowId workflowId
 * @returns any
 * @throws ApiError
 */
export const workflowControllerGetTasks = (workflowId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/workflow/tasks`,
    query: {
      workflowId: workflowId,
    },
  });
};

/**
 * Reset a task
 * @param requestBody
 * @returns any
 * @throws ApiError
 */
export const workflowControllerResetTask = (requestBody: getTaskParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/workflow/task/reset`,
    body: requestBody,
    mediaType: 'application/json',
  });
};

/**
 * Update task status
 * @param requestBody
 * @returns any Status updated
 * @throws ApiError
 */
export const workflowControllerUpdateTaskStatus = (requestBody: UpdateTaskStatus): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/workflow/task/status`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useWorkflowControllerStartService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerStart, { method, ...options });
};

export const useWorkflowControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerGet, { method, ...options });
};

export const useWorkflowControllerRequestTaskService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: RequestTask) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerRequestTask, { method, ...options });
};

export const useWorkflowControllerGetTaskService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (taskId: string, resetNbOfExecution?: boolean) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerGetTask, { method, ...options });
};

export const useWorkflowControllerGetTasksService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (workflowId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerGetTasks, { method, ...options });
};

export const useWorkflowControllerResetTaskService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: getTaskParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerResetTask, { method, ...options });
};

export const useWorkflowControllerUpdateTaskStatusService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (requestBody: UpdateTaskStatus) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(workflowControllerUpdateTaskStatus, { method, ...options });
};
