import { Center, hideModal, Loader, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';
import { H3 } from 'src/components/Text';
import { AddGrantButton, GrantTable } from './grant.element';
import { useGrantRequests } from './grant.request';
import { useEffect } from 'react';

const GrantPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const headers: any = i18n.t('page.grant.headers');
  const grantRequests = useGrantRequests();
  const { getAllGrantRequest, createGrantRequest, deleteGrantRequest } = grantRequests;

  const location = useLocation();

  useEffect(() => {
    getAllGrantRequest.run(projectId);
  }, [location.pathname]);

  // useMount(() => {
  //   getAllGrantRequest.run(projectId);
  // });

  const createGrantCallback = (name: string) => {
    createGrantRequest.run({ name, projectId });
    hideModal();
  };

  const isLoaded = !getAllGrantRequest.loader && getAllGrantRequest && getAllGrantRequest.data;

  const navigateToSource = (id: string) => {
    navigate(`/${projectId}/fund/grant/${id}`);
  };

  const buttonCallback = (id: string) => {
    deleteGrantRequest.run(id);
  };

  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <H3>{i18n.t('page.grant.grant')}</H3>
      <AddGrantButton text={i18n.t('button.create')} createGrantCallback={createGrantCallback} />
      {isLoaded ? (
        <GrantTable
          headers={headers}
          grants={getAllGrantRequest.data}
          navigateToSource={navigateToSource}
          buttonCallback={buttonCallback}
        />
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};
export default GrantPage;
