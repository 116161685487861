import React from 'react';
import { Modal, hideModal } from '@app-studio/web';
import { H3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';

import i18n from 'src/utils/locale';
import { ConfigForm } from 'src/forms/ConfigForm';

// Define TypeScript interface for the props
interface DescribeModalProps {
  title?: string;
  keyName?: string;
  initialValues: any; // Specify more specific type if possible
  textAreaStyle: any; // Specify more specific type if possible
  onNext: () => void;
  placeholder: string;
  dropdown?: {
    keyName: string;
    label?: string;
    options: { label: string; value: string }[];
  };
  textField?: {
    keyName: string;
    label?: string;
    placeholder: string;
  };
  position?: 'row' | 'column'; // 'position' is now optional
}

export const DescribeModal: React.FC<DescribeModalProps> = ({
  title,
  keyName,
  textField,
  initialValues,
  textAreaStyle,
  onNext,
  placeholder,
  position,
  dropdown,
}) => {
  return (
    <Modal.Container
      boxShadow="none"
      width="82vw"
      backgroundColor="white"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <AssetIcon
        name="BlackCloseSvg"
        cursor="pointer"
        width={24}
        height={24}
        color="black"
        position="absolute"
        zIndex={10000}
        right={50}
        top={30}
        onClick={() => hideModal()}
      />
      <Modal.Body border="none">
        {title && <H3 marginBottom={50}>{title}</H3>}
        <ConfigForm
          keyName={keyName}
          textField={textField}
          position={position} // position is optional and can be undefined
          initialValues={initialValues}
          onNext={onNext}
          textAreaStyle={textAreaStyle}
          placeholder={placeholder}
          nextButtonName={i18n.t('action.generate')}
          dropdown={dropdown}
        />
      </Modal.Body>
    </Modal.Container>
  );
};
