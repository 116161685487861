import React from 'react';
import { ImageProps, Text, TextProps, ViewProps } from 'app-studio';
import { Vertical, Horizontal, Button, View, Center } from '@app-studio/web';
import Icon from 'react-web-vector-icons';

import { HeroProps } from './Hero.interface';

const HeroSection: React.FC<HeroProps> = ({
  handlePropsSubmit,
  handlePropsSelection,
  textProps: { header, subHeader },
  imageProps,
  interactionProps: { mainButton, secondButton },
  designProps = {},
  mode,
}) => {
  const textProps = { header, subHeader };
  const interactionProps = { mainButton, secondButton };
  const { contentPosition = 'left' } = designProps;

  // Helper for conditional navigation
  const handleNavigation = ({ path, openInNewTab }: { path: string; openInNewTab: string }) => {
    if (openInNewTab === 'yes') {
      window.open(path, '_blank');
    } else {
      window.location.href = path;
    }
  };

  const position = contentPosition === 'left' ? 'flex-start' : 'flex-end';
  const alignItems = 'center';

  // Extract common styling and conditions to reduce repetition
  const isTextMode = mode === 'Text' || mode === 'Prompt';
  const isImageMode = mode === 'Image' || mode === 'Prompt';
  const isInteractionMode = mode === 'Interaction' || mode === 'Prompt';

  const textStyles = {
    cursor: isTextMode ? 'pointer' : 'not-allowed',
    opacity: isTextMode ? 1 : 0.1,
    border: mode === 'Text' ? '2px dotted gray' : 'none',
  };

  const imageStyles = {
    cursor: isImageMode ? 'pointer' : 'not-allowed',
    opacity: isImageMode ? 1 : 0.1,
    border: mode === 'Image' ? '3px dotted gray' : 'none',
  };

  const interactionStyles = {
    opacity: isInteractionMode ? 1 : 0.1,
    cursor: isInteractionMode ? 'pointer' : 'not-allowed',
    borderWidth: mode === 'Interaction' ? '2px' : 'none',
    borderColor: mode === 'Interaction' ? 'gray' : 'none',
    borderStyle: mode === 'Interaction' ? 'dotted' : 'none',
  };

  const containerResponsiveProps: ViewProps = {
    wrap: 'nowrap',
    backgroundColor: 'white',
    width: '100%',
    height: '70vh',
    justifyContent: position,
    alignItems: alignItems,
    gap: 30,
    media: {
      mobile: {
        padding: '40px 40px',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      tablet: {
        padding: '30px 60px',
      },
      desktop: {
        padding: '40px 100px',
      },
    },
  };

  const headlineResponsiveProps: any = {
    alignSelf: position,
    textAlign: 'right',
    media: {
      mobile: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '36px',
        textAlign: contentPosition,
        width: '100%',
      },
      tablet: {
        fontSize: '40px',
        fontWeight: 'bold',
        lineHeight: '56px',
        textAlign: contentPosition,
        width: '100%',
      },
      desktop: {
        fontSize: '58px',
        fontWeight: 'bold',
        lineHeight: '72px',
        textAlign: contentPosition,
        minWidth: '60%',
      },
    },
    ...(designProps?.headerProps ?? {}),
    ...textStyles,
  };

  const subheaderResponsiveProps: TextProps = {
    alignSelf: position,
    media: {
      mobile: {
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '24px',
        width: '90%',
        textAlign: contentPosition,
      },
      tablet: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '26px',
        width: '80%',
        textAlign: contentPosition,
      },
      desktop: {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '28px',
        minWidth: '60%',
        textAlign: contentPosition,
      },
    },
    ...(designProps?.subHeaderProps ?? {}),
    ...textStyles,
  };

  const buttonContainerResponsiveProps: any = {
    gap: '8%',
    marginTop: 20,
    justifyContent: position,
    alignItems: alignItems,
    alignSelf: position,
    media: {
      mobile: {
        gap: '10px',
        width: '90%',
      },
      tablet: {
        width: '80%',
      },
      desktop: {
        width: '60%',
      },
    },
  };

  const buttonResponsiveProps: any = {
    padding: 0,
    display: 'flex',
    wrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
    media: {
      mobile: {
        fontSize: '10px',
        padding: '5px 10px',
      },
      tablet: {
        fontSize: '12px',
        padding: '5px 10px',
      },
      desktop: {
        fontSize: '16px',
        padding: '12px 24px',
      },
    },
  };

  const mainButtonResponsiveProps: any = {
    ...buttonResponsiveProps,
    ...(designProps?.mainButtonProps ?? {}),
    ...interactionStyles,
  };

  const secondButtonResponsiveProps: any = {
    ...buttonResponsiveProps,
    ...(designProps?.secondButtonProps ?? {}),
    ...interactionStyles,
  };

  const shape = designProps?.imageProps?.shape ?? 'rounded';

  const imageResponsiveProps: ImageProps = {
    borderRadius: shape === 'circle' ? '50%' : shape === 'rounded' ? 15 : 0,
    backgroundImage: `url(${imageProps?.href})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginBottom: 0,
    borderWidth: 0,
    width: shape === 'circle' ? '45%' : '100%',
    height: shape === 'circle' ? '70%' : '70%',
    media: {
      mobile: {
        display: 'none',
      },
      tablet: {
        maxWidth: '400px',
        maxHeight: '400px',
      },
      desktop: {
        maxWidth: '500px',
        maxHeight: '800px',
      },
    },
    ...imageStyles,
  };

  const handleImageClick = () => {
    if (mode === 'Image') {
      handlePropsSelection({
        key: 'string-image',
        initialValues: imageProps,
        callback: (updatedObject) => {
          const updatedValue = {
            imageProps: {
              ...imageProps,
              href: updatedObject.href ?? imageProps.href,
            },
            designProps: {
              ...designProps,
              imageProps: {
                ...(designProps?.imageProps ?? {}),
                shape: updatedObject.shape ?? designProps?.imageProps?.shape,
              },
            },
          };
          handlePropsSubmit(updatedValue);
        },
      });
    }
  };

  return (
    <Horizontal role="hero" {...containerResponsiveProps}>
      {imageProps && imageProps.href && contentPosition === 'right' && (
        <View onClick={handleImageClick} {...imageResponsiveProps} />
      )}
      <Vertical gap={10}>
        <Text
          onClick={() => {
            if (mode === 'Text') {
              handlePropsSelection({
                key: 'header',
                initialValues: header,
                callback: (updatedObject) => {
                  const updatedValue = {
                    textProps: {
                      ...textProps,
                      header: {
                        ...header,
                        text: updatedObject.text,
                      },
                    },
                    designProps: designProps,
                  };
                  handlePropsSubmit(updatedValue);
                },
              });
            }
          }}
          {...headlineResponsiveProps}
        >
          {header.text}
        </Text>
        {subHeader?.text && (
          <Text
            onClick={() => {
              if (mode === 'Text') {
                handlePropsSelection({
                  key: 'subHeader',
                  initialValues: subHeader,
                  callback: (updatedObject) => {
                    const updatedValue = {
                      textProps: {
                        ...textProps,
                        subHeader: {
                          ...subHeader,
                          text: updatedObject.text,
                        },
                      },
                      designProps: designProps,
                    };
                    handlePropsSubmit(updatedValue);
                  },
                });
              }
            }}
            {...subheaderResponsiveProps}
          >
            {subHeader.text}
          </Text>
        )}
        {(mainButton || secondButton) && (
          <Horizontal {...buttonContainerResponsiveProps}>
            {mainButton && mainButton.text && (
              <Button
                onClick={() => {
                  if (mode === 'Interaction') {
                    handlePropsSelection({
                      key: 'mainButton',
                      initialValues: mainButton,
                      callback: (updatedObject) => {
                        const updatedValue = {
                          interactionProps: {
                            ...interactionProps,
                            mainButton: {
                              ...mainButton,
                              text: updatedObject.text,
                              url: updatedObject.url,
                              openInNewTab: updatedObject.openInNewTab,
                            },
                          },
                          designProps: designProps,
                        };
                        handlePropsSubmit(updatedValue);
                      },
                    });
                  }
                  if (mode === 'Prompt') {
                    handleNavigation({ path: mainButton?.url ?? '#', openInNewTab: mainButton?.openInNewTab ?? 'yes' });
                  }
                }}
                isAuto
                {...mainButtonResponsiveProps}
              >
                <Text whiteSpace="nowrap">{mainButton?.text}</Text>
                {mainButton.icon && mainButton.icon.name && mainButton.icon.font && (
                  <Center>
                    <Icon
                      name={mainButton.icon.name}
                      font={mainButton.icon.font}
                      color={designProps?.mainButtonProps?.iconColor ? designProps?.mainButtonProps.iconColor : 'white'}
                      size={Math.min(designProps?.mainButtonProps?.iconSize ?? 24, 24)}
                    />
                  </Center>
                )}
              </Button>
            )}
            {secondButton && secondButton.text && (
              <Button
                onClick={() => {
                  if (mode === 'Interaction') {
                    handlePropsSelection({
                      key: 'secondButton',
                      initialValues: secondButton,
                      callback: (updatedObject) => {
                        const updatedValue = {
                          interactionProps: {
                            ...interactionProps,
                            secondButton: {
                              ...secondButton,
                              text: updatedObject.text,
                              url: updatedObject.url,
                              openInNewTab: updatedObject.openInNewTab,
                            },
                          },
                          designProps: designProps,
                        };
                        handlePropsSubmit(updatedValue);
                      },
                    });
                  }
                  if (mode === 'Prompt') {
                    handleNavigation({
                      path: secondButton?.url ?? '#',
                      openInNewTab: secondButton?.openInNewTab ?? 'yes',
                    });
                  }
                }}
                isAuto
                {...secondButtonResponsiveProps}
              >
                <Text whiteSpace="nowrap">{secondButton?.text}</Text>
                {secondButton.icon && secondButton.icon.name && secondButton.icon.font && (
                  <Center>
                    <Icon
                      name={secondButton.icon.name}
                      font={secondButton.icon.font}
                      color={
                        designProps?.secondButtonProps?.iconColor ? designProps?.secondButtonProps.iconColor : 'white'
                      }
                      size={Math.min(designProps?.secondButtonProps?.iconSize ?? 24, 24)}
                    />
                  </Center>
                )}
              </Button>
            )}
          </Horizontal>
        )}
      </Vertical>
      {imageProps && imageProps.href && contentPosition === 'left' && (
        <View
          onClick={() => {
            if (mode === 'Image') {
              handlePropsSelection({
                key: 'image',
                initialValues: imageProps,
                callback: (updatedObject) => {
                  const updatedValue = {
                    imageProps: {
                      ...imageProps,
                      href: updatedObject.href ?? imageProps.href,
                    },
                    designProps: {
                      ...designProps,
                      imageProps: {
                        ...(designProps?.imageProps ?? {}),
                        shape: updatedObject.shape ?? designProps?.imageProps?.shape,
                      },
                    },
                  };
                  handlePropsSubmit(updatedValue);
                },
              });
            }
          }}
          {...imageResponsiveProps}
        />
      )}
    </Horizontal>
  );
};

export default HeroSection;
