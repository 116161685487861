import { View } from 'app-studio';
import { Horizontal, Right, Left } from '@app-studio/web';
import RegisterForm from '../../forms/RegisterForm';
import { useNavigate } from 'react-router-dom';
import { VerticalScroll } from 'src/components/Layout';
import i18n from 'src/utils/locale';
import { BackToHomeButton } from 'src/components/Button';

function RegisterPage() {
  const navigate = useNavigate();

  return (
    <LoginContainer>
      <BackToHomeButton />

      <Message>{i18n.t('page.register.joinUs')}</Message>
      <RegisterForm />
      <Horizontal margin={10} gap={10} onClick={() => navigate('/auth/login')}>
        <PasswordLabel>{i18n.t('page.register.alreadyAccount')}</PasswordLabel>
        <SignIn>{i18n.t('page.register.signIn')}</SignIn>
        {/* <Right onClick={() => navigate('/security/reset-password')}>
          <PasswordLabel>{i18n.t('page.login.forgotPassword')}</PasswordLabel>
        </Right> */}
      </Horizontal>
    </LoginContainer>
  );
}

export default RegisterPage;

const Message = (props) => (
  <View font={'600 32px '} color={'rgba(19, 15, 38, 1)'} margin={50} textAlign="center" {...props} />
);

const LoginContainer = (props) => (
  <View padding={20} flexDirection={'column'} justifyContent={'flex-start'} {...props} />
);

const PasswordLabel = (props) => <View font={'400 16px '} color={'rgba(19, 15, 38, 1)'} {...props} />;

const SignIn = (props) => (
  <View font={'500 16px '} color={'rgba(85, 52, 165, 1)'} textDecorationLine={'underline'} {...props} />
);
