import React, { Suspense, useEffect, useState, lazy, useRef } from 'react';
// Assurez-vous que Babel Standalone est inclus dans votre projet
import * as AppStudio from '@app-studio/web';
import * as AppStudioBase from 'app-studio';
import * as ReactRouterDom from 'react-router-dom';
import Icon from 'react-web-vector-icons';

import { transform } from '@babel/standalone';
import styled from 'styled-components';

export function extractContent(response: string, tag = 'typescript') {
  const jsonCodeRegex = new RegExp('```' + tag + '\\n([\\s\\S]*?)```', 'g');

  const match = response.match(jsonCodeRegex);
  if (match && match[1]) {
    const cleanedJsonString = match[1].trim().replace(/\/\/.*$/gm, ''); // Remove comments
    return JSON.parse(cleanedJsonString);
  } else {
    const lines = response.split('\n');
    // Supprime la première et la dernière ligne
    return lines.slice(1, -1).join('\n');
  }
}
// Function to find all import statements in the code string
interface ImportStructure {
  names: string[];
  path: string;
}

const extractImports = (code: string): ImportStructure[] => {
  const importRegex = /import\s+((?:[^{}]*?)\s*(?:{[^}]*}\s*)?)\s*from\s*['"]([^'"]+)['"];?/g;
  let match;
  const imports: ImportStructure[] = [];

  while ((match = importRegex.exec(code)) !== null) {
    const importPart = match[1].trim();
    const importNames: string[] = [];

    if (importPart.includes('{')) {
      const parts = importPart.split('{');
      if (parts[0].trim()) {
        importNames.push(parts[0].trim());
      }
      importNames.push(
        ...parts[1]
          .replace('}', '')
          .split(',')
          .map((name) => name.trim().replace(/[^\w$]/g, ''))
      );
    } else {
      importNames.push(importPart.trim().replace(/[^\w$]/g, ''));
    }
    imports.push({ names: importNames, path: match[2] });
  }

  return imports;
};

const removeImports = (code) => {
  return code.replace(/import\s+.*\s+from\s+['"][^'"]+['"];?/g, '');
};

const PageEditorView = ({ code = '' }) => {
  const [Component, setComponent] = useState<React.FC | null>(null);

  //console.log('code', code);

  useEffect(() => {
    const transpileCode = (a) => {
      return transform(a, {
        presets: ['react', 'typescript'],
        filename: 'file.tsx',
      }).code;

      // ts.transpileModule(a, {
      //   compilerOptions: { module: ts.ModuleKind.ESNext, jsx: ts.JsxEmit.React },
      // }).outputText;
    };

    const loadComponent = async (b, imports) => {
      // console.log('transpiledCode', b);
      const moduleUrl = URL.createObjectURL(new Blob([b], { type: 'text/javascript' }));
      // Ensure the global object contains the necessary imports
      for (const imp of imports) {
        let importedModule;
        if (imp.path == '@app-studio/web') {
          importedModule = AppStudio;
        } else if (imp.path == 'react') {
          importedModule = React;
        } else if (imp.path == 'app-studio') {
          importedModule = AppStudioBase;
        } else if (imp.path == 'react-router-dom') {
          importedModule = ReactRouterDom;
        } else if (imp.path == 'styled-components') {
          importedModule = styled;
        } else if (imp.path == 'react-router-dom') {
          importedModule = ReactRouterDom;
        } else if (imp.path == 'react-web-vector-icons') {
          importedModule = { Icon };
        } else {
          importedModule = await import(/* webpackIgnore: true */ imp.path);
        }
        imp.names.forEach((name) => {
          global[name] = importedModule[name];
        });
        console.log(importedModule);
      }

      global['Vertical'] = AppStudio['Vertical'];
      global['Horizontal'] = AppStudio['Horizontal'];
      global['Button'] = AppStudio['Button'];
      global['View'] = AppStudio['View'];
      global['Link'] = ReactRouterDom['Link'];
      global['Table'] = AppStudio['Table'];

      global.React = React;
      global.Text = Text;
      global.styled = styled;

      return lazy(() => import(/* webpackIgnore: true */ moduleUrl));
    };

    const imports = extractImports(code);
    const cleanedCodeString = removeImports(code);
    const transpiledCode = transpileCode(cleanedCodeString);

    loadComponent(transpiledCode, imports).then((Comp) => {
      setComponent(() => Comp);
    });
  }, [code]);

  if (!Component) return <div id={'code-container'}>Chargement du composant...</div>;
  return (
    <Suspense
      fallback={
        <AppStudio.Center width={'100%'} height={'100%'}>
          <AppStudio.Loader />
        </AppStudio.Center>
      }
    >
      <Component />
    </Suspense>
  );
};

export default PageEditorView;
