import { AlertModal } from 'src/modals/AlertModal';
import { AddSourceModal } from './AddSourceModal';

import { CreateApplicationModal } from './CreateApplicationModal';
import { CreateGrantModal } from './CreateGrantModal';
import { CreateModal } from './CreateModal';
import CreateNewsletterModal from './CreateNewsletterModal';
import { DescribeModal } from './DescribeModal';
import { EditorModal } from './EditorModal';

import { ModelModal } from './ModelModal';
import { ProjectModal } from './ProjectModal';
import AddCardModal from './addCardModal';
import { AddComponentModal } from './AddComponentModal';
import { ListProjectModal } from './ListProjectModal';

const modalList: any = {
  AlertModal,
  CreateGrantModal,
  EditorModal,
  CreateModal,
  CreateApplicationModal,
  ModelModal,
  AddSourceModal,
  CreateNewsletterModal,
  ProjectModal,
  DescribeModal,
  AddCardModal,
  AddComponentModal,
  ListProjectModal,
};
export default modalList;
