import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Button, FormikSelect, FormikTextArea, FormikTextField, Vertical } from '@app-studio/web';
import { FormButtons } from 'src/components/Button';
import { C5 } from 'src/components/Text';
import i18n from 'src/utils/locale';
import { AssetIcon } from 'src/utils/assets';

interface ImagePropsFormProps {
  initialValues: {
    href: string;
    shape: any;
  };
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  regenerateImage: () => void;
  field: {
    header: string;
    headerPlaceholder: string;
    shape: string;
    shapeOptions: any;
  };
}

export const ImagePropsEditorForm: React.FC<ImagePropsFormProps> = ({
  initialValues,
  handleSubmit,
  handleCancel,
  regenerateImage,
  field,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical gap={20} height="68vh">
          <Button marginLeft="auto" padding={10} borderRadius={10} onClick={regenerateImage} isAuto>
            <AssetIcon name="LoadingSvg" width={20} height={20} color="white" />
          </Button>
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.header}
          </C5>
          <FormikTextField
            id="href"
            placeholder={field.headerPlaceholder}
            name="href"
            shape={'rounded'}
            variant={'outline'}
            styles={{
              box: {
                borderColor: '#E0E0E0',
                borderStyle: 'solid',
                borderWidth: 1,
                padding: '0 10px',
              },
              text: { color: '#151920' },
              field: {
                height: 32,
                fontSize: 11,
              },
            }}
          />
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.shape}
          </C5>
          <FormikSelect
            id="shape"
            key="shape"
            name="shape"
            options={field.shapeOptions}
            shape={'rounded'}
            variant={'outline'}
            styles={{
              box: {
                borderColor: '#E0E0E0',
                borderStyle: 'solid',
                borderWidth: 1,
                backgroundColor: 'white',
                height: 32,
                width: '100%',
              },
              dropDown: { zIndex: 1000000 }, // Ensuring dropdown appears above other elements
              text: { color: '#151920', fontSize: 11 },
            }}
          />
          <Vertical gap={20} width="100%" marginTop="auto">
            <FormButtons
              handleCancel={handleCancel}
              handleButtonSave={formikProps.handleSubmit}
              type="submit"
              styles={{
                buttons: {
                  flexDirection: 'column',
                  gap: 8,
                },
              }}
            />
          </Vertical>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
