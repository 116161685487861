import { Vertical } from '@app-studio/web';
import { marked } from 'marked';
import { H4, C3 } from 'src/components/Text';
import { Header } from '../document/document.element';

export const Section = ({ id, content, sectionId, isEditing, title, editorIconCallback }) => (
  <Vertical
    padding={isEditing ? '20px 30px 20px 40px' : '20px 80px 20px 40px'}
    height={!!(sectionId === id) && isEditing ? 'auto' : '100%'}
    backgroundColor={!!(sectionId === id) && isEditing ? '#D9D9D9' : 'inherit'}
  >
    <Header
      TextComponent={H4}
      textProps={{ children: title }}
      editorCallback={() => editorIconCallback({ id, title, content })}
    />
    <Vertical gap={25} margin={'10px 0'}>
      <C3
        color="black"
        overflow={'auto'}
        paddingVertical={10}
        dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
      />
    </Vertical>
  </Vertical>
);
