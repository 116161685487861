import { Vertical, hideModal } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';

import { useNewsletterSourcesRequests } from './Sources.request';
import { AddSourceButton, GenerateEditButton, SourceHeader } from './Sources.element';
import { SourcesTable } from './components/SourcesTable/SourcesTable.component';

const Sourcespage = () => {
  const { projectId, id } = useParams();
  const navigate = useNavigate();

  const sourceRequests = useNewsletterSourcesRequests();
  const { getNewsletterRequest, createContentRequest, createSourceRequest } = sourceRequests;

  let newsletter = getNewsletterRequest.data;
  const isLoading = !!getNewsletterRequest.loader && !getNewsletterRequest.data && !createSourceRequest.data;

  useMount(() => {
    getNewsletterRequest.run(id);
  });

  const createSourceCallback = (url: string) => {
    createSourceRequest.run({ url, newsletterId: id });
  };

  const generateContentCallback = () => {
    if (getNewsletterRequest.data.content) {
      navigate(`/${projectId}/social/newsletter/${id}`);
    } else {
      createContentRequest.run(id, { language: 'english' });
    }
  };

  return (
    <Vertical width="100%" gap={50} height="100%" padding="60px 40px 90px">
      <SourceHeader title={newsletter?.name ?? ''} />
      <AddSourceButton createSourceCallback={createSourceCallback} />
      <SourcesTable isLoading={isLoading} sources={newsletter?.sources ?? []} sourceRequests={sourceRequests} />
      {!getNewsletterRequest.loader && newsletter && (newsletter.sources.length > 0 || newsletter.content) && (
        <GenerateEditButton
          isLoading={!!createContentRequest.loader}
          text={newsletter.content ? i18n.t('button.editNews') : i18n.t('button.generate')}
          generateContentCallback={generateContentCallback}
        />
      )}
    </Vertical>
  );
};

export default Sourcespage;
