import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import { Button, FormikTextArea, Vertical, FormikTextField, FormikForm, View, FormikDatePicker } from '@app-studio/web';
import { FormItem } from 'src/components/Form';
import { Label, C4 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';

export const EnterpriseForm = ({ initialValues, isLoading, handleSubmit, ...props }) => {
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, isSubmitting }) => (
        <FormikForm {...props}>
          <Vertical gap={20} width="fit-content">
            <FormItem alignItems="center">
              <Label as="label" flex={1}>
                {i18n.t('form.name.label')}:
              </Label>
              <View flex={2}>
                <FormikTextField
                  placeholder={i18n.t('form.enterpriseName.placeholder')}
                  name="name"
                  shape={'rounded'}
                  variant={'outline'}
                  style={{ field: { fontSize: 14 } }}
                />
              </View>
            </FormItem>
            <FormItem flexDirection="column" gap={10}>
              <Label as="label">{i18n.t('form.address.label')}:</Label>
              <FormikTextArea
                placeholder={i18n.t('form.address.placeholder')}
                name="address"
                shape={'rounded'}
                variant={'outline'}
                style={{ text: { fontSize: 14 }, field: { fontSize: 14 } }}
              />
            </FormItem>
            <FormItem flexDirection="column" gap={10}>
              <Label as="label">{i18n.t('form.SIRETNumber.label')}:</Label>
              <FormikTextField
                placeholder={i18n.t('form.SIRETNumber.placeholder')}
                name="siretNumber"
                shape={'rounded'}
                type="number"
                variant={'outline'}
                style={{ field: { fontSize: 14 } }}
              />
            </FormItem>
            <FormItem alignItems="center">
              <Label as="label" flex={1}>
                {i18n.t('form.phone.label')}:
              </Label>
              <View flex={2}>
                <FormikTextField
                  placeholder={i18n.t('form.phone.placeholder')}
                  name="phone"
                  shape={'rounded'}
                  styles={{ field: { type: 'tel' } }}
                  variant={'outline'}
                  style={{ field: { fontSize: 14 } }}
                />
              </View>
            </FormItem>
            <FormItem alignItems="center">
              <Label as="label" flex={1}>
                {i18n.t('form.date.label')}:
              </Label>
              <View flex={2}>
                <View position="relative">
                  <FormikDatePicker
                    id={'creationDate'}
                    name={'creationDate'}
                    placeholder={i18n.t('form.date.placeholder')}
                    shape={'rounded'}
                    variant={'outline'}
                    style={{ field: { fontSize: 14 } }}
                  />
                  {/* <AssetIcon
                    cursor={'pointer'}
                    position="absolute"
                    zIndex={1000}
                    top={15}
                    right={10}
                    name="SolarCalendarSvg"
                    width={24}
                    height={24}
                  /> */}
                </View>
              </View>
            </FormItem>
            <Button
              marginLeft="auto"
              isLoading={isLoading}
              type="submit"
              color="white"
              whiteSpace="nowrap"
              onClick={handleSubmit}
              isDisabled={isSubmitting || isLoading}
              borderRadius={10}
              width="90px"
              height={20}
            >
              <C4>{i18n.t('action.save')}</C4>
            </Button>
          </Vertical>
        </FormikForm>
      )}
    </Formik>
  );
};
