import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { PageHeader } from 'src/components/Header';
import { useProjectStates } from './offer.state';

import { useOffersRequests } from './offer.request';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Section } from 'src/components/Section';
import { useEffect } from 'react';
import { H3 } from 'src/components/Text';
import { CreateButton } from 'src/components/Button';
import { Table } from 'src/components/Table';
import { useMount } from 'app-studio';

const OfferPage = () => {
  let section = { id: '', content: '', title: '' };
  const initialValues = {
    description: '',
  };

  const { projectId, id } = useParams();
  const location = useLocation();
  const { isEditing, setIsEditing } = useProjectStates();
  const headers: any = i18n.t('page.tableHeader');
  const navigate = useNavigate();

  useMount(() => {
    getAllOfferRequest.run(projectId);
    getAllCustomerRequest.run(projectId);
  });

  useEffect(() => {
    if (id) {
      getOfferRequest.run(id);
    }
  }, [location.pathname]);

  const handleEditorCallback = () => {
    getOfferRequest.run(id);
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const refresh = () => {
    getAllOfferRequest.run(projectId);
    hideModal();
  };

  const getAllCustomerCallback = (data) => {};

  const marketDescripRequests = useOffersRequests({
    handleEditorCallback,
    editorCallback,
    getAllCustomerCallback,
    refresh,
  });

  const {
    getOfferRequest,
    updateSectionRequest,
    generateContentRequest,
    getAllOfferRequest,
    createOfferRequest,
    deleteOfferRequest,
    getAllCustomerRequest,
  } = marketDescripRequests;

  const areOffersLoaded = !getAllOfferRequest.loader && getAllOfferRequest && getAllOfferRequest.data;
  const isOfferLoaded = !getOfferRequest.loader && getOfferRequest && getOfferRequest.data;

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        request: generateContentRequest,
        description: content,
        sectionTitle: title,
        isLoading: isLoading,
        sectionContent: content,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  const navigateTo = (id: string) => {
    navigate(`/${projectId}/project/strategy/offer/${id}`);
  };

  const handleButtonClick = () => {
    const options =
      getAllCustomerRequest &&
      getAllCustomerRequest.data &&
      getAllCustomerRequest.data.map((customer) => customer.name);
    showModal(
      'DescribeModal',
      {
        key: new Date().getTime(),
        onNext: (input) => {
          const customer = getAllCustomerRequest.data.find((customer) => customer.name === input.dropdown);
          if (customer && customer.id) {
            createOfferRequest.run({ projectId, description: input.description, customerId: customer.id });
          }
        },
        initialValues,
        title: i18n.t('modal.offer.describe'),
        keyName: 'description',
        textAreaStyle: { height: 200, width: '65vw' },
        placeholder:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate, augue ut aliquet ultrices, orci magna ornare urna, eget interdum ex enim in purus. Nam volutpat eros ligula, eu tempor mauris varius a. ',
        position: 'row',
        dropdown: {
          keyName: 'dropdown',
          label: 'For Customer',
          options,
        },
      },
      { position: 'right' }
    );
  };

  const buttonCallback = (id: string) => deleteOfferRequest.run(id);
  return (
    <>
      {!id && (
        <Vertical width="100%" gap={50} padding="60px 40px">
          <H3>{i18n.t('page.offer.headerMain')}</H3>
          <CreateButton
            text={i18n.t('button.create')}
            handleButtonClick={handleButtonClick}
            isLoading={!!getAllCustomerRequest && !!getAllCustomerRequest.loading}
          />
          {areOffersLoaded ? (
            <Table
              headers={headers}
              menu={getAllOfferRequest.data}
              navigateTo={navigateTo}
              buttonCallback={buttonCallback}
            />
          ) : (
            <Center width={'100%'} height={'100%'}>
              <Loader />
            </Center>
          )}
        </Vertical>
      )}
      {id && (
        <Vertical width="100%" padding="60px 40px">
          <PageHeader
            prefixTitle={i18n.t('page.offer.header')}
            title={getOfferRequest?.data?.name ?? ''}
            paddingBottom={50}
          />
          {isOfferLoaded &&
            getOfferRequest.data.offerSections.map((section) => (
              <Section
                id={section.id}
                key={section.id}
                sectionId={section.id}
                isEditing={isEditing}
                title={section.title}
                editorIconCallback={handleEditorSelection}
                content={section.content}
              />
            ))}
        </Vertical>
      )}
    </>
  );
};

export default OfferPage;
