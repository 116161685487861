import React, { useState } from 'react';
import { SourceView } from './Source/Source.element';
import { SourceProps } from './Source/Source.props';
import { useSourceStates } from './Source/Source.state';

export const Source: React.FC<SourceProps> = ({ source, sourceRequests }) => {
  const { updateSourceRequest, deleteSourceRequest } = sourceRequests;
  const { selectedSource, setSelectedSource } = useSourceStates();

  const handleDeleteCallback = () => {
    setSelectedSource(source.id);
    deleteSourceRequest.run(source.id);
  };

  const handleStateCallback = () => {
    setSelectedSource(source.id);
    updateSourceRequest.run(source.id, { state: !source.state });
  };

  return (
    <SourceView
      source={source}
      selectedSource={selectedSource}
      setSelectedSource={setSelectedSource}
      isDeleting={deleteSourceRequest.loading && selectedSource === source.id}
      isUpdating={updateSourceRequest.loading && selectedSource === source.id}
      handleStateCallback={handleStateCallback}
      handleDeleteCallback={handleDeleteCallback}
    />
  );
};
