import { View, Button, Center, Vertical, Horizontal } from '@app-studio/web';
import { Form, Input } from 'app-studio';

export const Apps = (props) => (
  <Horizontal width="100vw" height={330} position="relative" marginVertical={150}>
    <Blue />
    <Black />
    <RightGrey />
    <Green />
    <RightBlue />
    <Purple />
    <White />
    <Grey />
    <RightPurple />
  </Horizontal>
);

export const Card = (props) => (
  <View
    overflow="hidden"
    position="absolute"
    background="rgb(0, 0, 0)"
    backgroundRepeat="no-repeat"
    backgroundSize="contain"
    backgroundPosition="center"
    borderRadius="10px"
    {...props}
  />
);

export const Black = (props) => (
  <Card width="20vw" height="30vh" background="rgb(0, 0, 0)" left="10vw" top="5vh" {...props} />
);

export const Grey = (props) => (
  <Card
    width="20vw"
    height="30vh"
    background="linear-gradient(-90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))"
    left="20vw"
    top="14vh"
    {...props}
  />
);

export const RightGrey = (props) => (
  <Card
    width="20vw"
    height="30vh"
    background="linear-gradient(-90deg, rgba(0, 60, 60, 0.2), rgba(0, 0, 0, 0.2))"
    left="50vw"
    top="5vh"
    {...props}
  />
);

export const Purple = (props) => (
  <Card width="26vw" height="30vh" background="rgb(132, 75, 253)" left="34vw" top="20vh" {...props} />
);

export const RightPurple = (props) => (
  <Card width="20vw" height="30vh" background="rgb(132, 75, 253)" left="75vw" top="0vh" {...props} />
);

export const White = (props) => (
  <Card width="24vw" height="26vh" background="rgb(255, 255, 255)" left="35vw" top="22vh" {...props} />
);

export const Green = (props) => (
  <Card width="20vw" height="35vh" background="rgb(151, 227, 151)" left="62vw" top="10vh" {...props} />
);

export const RightBlue = (props) => (
  <Card width="15vw" height="40vh" background="rgb(130, 206, 243)" right="0" top="10vh" {...props} />
);

export const Blue = (props) => (
  <Card width="25vw" height="50vh" background="rgb(130, 206, 243)" left="-40px" top="0px" {...props} />
);
