import { UserService } from 'src/services/api';

export const useTopLayoutRequests = ({ handleUpdateCallback }) => {
  const updateUserRequests = UserService.useUserControllerUpdateService({
    onSuccess: (data) => {
      handleUpdateCallback(data.model);
    },
  });
  return {
    updateUserRequests,
  };
};
