import { useState } from 'react';

enum Steps {
  NameProject,
  DescribeProject,
  ConfigureProject,
  Loading,
  Complete,
}

export const useProjectStates = () => {
  const [currentStep, setCurrentStep] = useState<any>(Steps.NameProject);
  const [projectData, setProjectData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentUsage, setCurrentUsage] = useState(0);
  const [tasksInProgress, setTasksInProgress] = useState<string[]>([]);

  return {
    currentStep,
    setCurrentStep,
    projectData,
    setProjectData,
    isLoading,
    setIsLoading,
    currentUsage,
    setCurrentUsage,
    tasksInProgress,
    setTasksInProgress,
  };
};
