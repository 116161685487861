/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLandingHistoryParams } from '../models/CreateLandingHistoryParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create a landingHistory
 * @param requestBody
 * @returns any landingHistory created
 * @throws ApiError
 */
export const landingHistoryControllerCreate = (requestBody: CreateLandingHistoryParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landingHistory`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific landingHistory
 * @param id
 * @returns any landingHistory details fetched
 * @throws ApiError
 */
export const landingHistoryControllerRead = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/landingHistory/${id}`,
    errors: {
      404: `landingHistory not found`,
    },
  });
};

/**
 * As an admin, I want to delete a landingHistory
 * @param id
 * @returns any landingHistory deleted
 * @throws ApiError
 */
export const landingHistoryControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/landingHistory/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `landingHistory not found`,
    },
  });
};

/**
 * As an admin, I want to get all landingHistory
 * @param landingId
 * @returns any All landingHistory fetched
 * @throws ApiError
 */
export const landingHistoryControllerReadAll = (landingId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/landingHistory/project/${landingId}`,
    errors: {
      404: `landingHistorys does not exists`,
    },
  });
};

export const useLandingHistoryControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateLandingHistoryParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingHistoryControllerCreate, { method, ...options });
};

export const useLandingHistoryControllerReadService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingHistoryControllerRead, { method, ...options });
};

export const useLandingHistoryControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingHistoryControllerDelete, { method, ...options });
};

export const useLandingHistoryControllerReadAllService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (landingId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingHistoryControllerReadAll, { method, ...options });
};
