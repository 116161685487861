import react, { useEffect } from 'react';
import { marked } from 'marked';
import { hideModal, showModal, Vertical } from '@app-studio/web';
import { useLocation, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';
import { PageHeader } from 'src/components/Header';
import { Section } from 'src/components/Section';

import { useProjectStates } from './brand.state';

import { useBrandsRequests } from './brand.request';

const BrandPage = () => {
  let section = { id: '', content: '', title: '' };

  const { projectId, category } = useParams();

  const location = useLocation();
  const { isEditing, setIsEditing } = useProjectStates();

  useEffect(() => {
    getBrandRequest.run(projectId);
  }, [location.pathname]);

  const handleEditorCallback = () => {
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const brandDescripRequests = useBrandsRequests({ handleEditorCallback, editorCallback });

  const { getBrandRequest, updateSectionRequest, generateContentRequest, createBrandRequest } = brandDescripRequests;

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        request: generateContentRequest,
        description: '',
        sectionTitle: title,
        isLoading: isLoading,
        sectionContent: content,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  const regenerateClick = () => {};
  return (
    <Vertical width="100%" padding="60px 40px">
      <PageHeader
        isLoading={!!createBrandRequest.loading}
        prefixTitle={i18n.t(`page.${category}.header`)}
        regenerateClick={() => createBrandRequest.run({ projectId })}
        paddingBottom={50}
      />
      {getBrandRequest &&
        getBrandRequest.data &&
        getBrandRequest.data.brandCategory &&
        getBrandRequest.data.brandCategory
          .find((brand) => brand.category === category)
          ?.sections.map((section) => (
            <Section
              id={section.id}
              key={section.id}
              sectionId={section.id}
              isEditing={isEditing}
              title={section.title}
              editorIconCallback={handleEditorSelection}
              content={section.content}
            />
          ))}
    </Vertical>
  );
};

export default BrandPage;
