import { componentProps } from './editor.props';

export const deviceSize = {
  desktop: {
    width: '100%',
  },
  mobile: {
    width: '35%',
  },
  tablet: {
    width: '55%',
  },
  screen: {
    width: '100%',
  },
};
