import { Button, Center, Horizontal, Vertical } from '@app-studio/web';

import i18n from 'src/utils/locale';
import { C3, C4, H3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';
import { marked } from 'marked';

export const Header = ({ name }) => (
  <Horizontal marginVertical="30px">
    <H3>{i18n.t('page.newsletter.title', { separator: '>', title: name })}</H3>
  </Horizontal>
);

export const PromptHistory = ({ prompts, cancelCallback }) => (
  <Vertical wrap="nowrap" backgroundColor="color.gray.10" width="fit-content" height="100%" justifyContent="center">
    {prompts &&
      prompts.map((prompt: any, index: number) => {
        const isLastPrompt = index === 0;
        return (
          <Vertical
            key={index}
            gap={15}
            width="241px"
            padding={isLastPrompt ? '10px' : '30px 10px'}
            backgroundColor={isLastPrompt ? 'black' : 'transparent'}
          >
            <C3 color={isLastPrompt ? 'white' : 'black'}>{prompt.prompt}</C3>
            {isLastPrompt && isLastPrompt && (
              <Button
                marginLeft="auto"
                borderRadius="3px"
                color="black"
                padding="0px 15px"
                backgroundColor="white"
                onClick={() => cancelCallback(prompt.id)}
                isAuto
              >
                <C4>{i18n.t('action.cancel')}</C4>
              </Button>
            )}
          </Vertical>
        );
      })}
  </Vertical>
);

export const SendButton = ({ isLoading, handleSubmit }) => (
  <Button
    shape="pillShaped"
    type="submit"
    disabled={isLoading}
    backgroundColor={'black'}
    padding={'0.5rem'}
    cursor={isLoading ? 'not-allowed' : 'pointer'}
    opacity={isLoading ? '0.5' : '1'}
    width={'40px'}
    height={'40px'}
    icon={<AssetIcon name="Send_arrowSvg" height={'24px'} width={'24px'} />}
    onClick={handleSubmit}
  />
);

export const HeaderButtons = ({ isLoading, generateText, handleRegenerate, navigateTo }) => (
  <Horizontal marginLeft="auto" gap={40} marginBottom={20} paddingRight={20}>
    <Button
      color="white"
      alignSelf="center"
      marginTop="auto"
      icon={<AssetIcon name="MagicSvg" />}
      iconPosition="right"
      width="200px"
      disabled={isLoading}
      backgroundColor={isLoading ? 'gray' : 'black'}
      cursor={isLoading ? 'wait' : 'pointer'}
      onClick={handleRegenerate}
      isAuto
    >
      <C3>{generateText}</C3>
    </Button>
    <Button color="white" alignSelf="center" width="200px" onClick={navigateTo} isAuto>
      <C3>{i18n.t('button.edit')}</C3>
    </Button>
  </Horizontal>
);

export const Section = ({ id, content, sectionId, isEditing, editorCallback }) => (
  <Vertical
    gap={10}
    padding={isEditing ? '20px 30px 20px 40px' : '20px 80px 20px 40px'}
    // height={!!(selected.id === id) && isEditing ? 'auto' : '100%'}
    backgroundColor={!!(sectionId === id) && isEditing ? '#D9D9D9' : 'inherit'}
  >
    <AssetIcon
      name="Bxs_editSvg"
      width={24}
      height={24}
      marginLeft="auto"
      onClick={() => editorCallback({ id, content })}
    />
    <C3
      color="black"
      overflow={'auto'}
      paddingVertical={10}
      dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
    />
  </Vertical>
);
