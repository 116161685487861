import { Center, Loader, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';
import { Table } from 'src/components/Table';
import { useApplicationFilesRequests } from './applicationFiles.request';
import { Header } from 'src/components/Header';

const ApplicationFilesPage = () => {
  const { projectId, id } = useParams();
  const navigate = useNavigate();
  const headers: any = i18n.t('page.tableFileHeader');

  const applicationFilesRequests = useApplicationFilesRequests();
  const { getGrantRequest, getApplicationRequest } = applicationFilesRequests;

  useMount(() => {
    getApplicationRequest.run(id);
  });

  const isLoaded =
    !getGrantRequest.loader &&
    !getApplicationRequest.loader &&
    getApplicationRequest &&
    getGrantRequest &&
    getApplicationRequest.data &&
    getGrantRequest.data;

  const navigateTo = (fileId: string) => {
    if (isLoaded) {
      const name = getGrantRequest.data.name;
      navigate(`/${projectId}/fund/grant/application/${id}/${name}/${fileId}`);
    }
  };

  const buttonCallback = () => {};

  console.log;
  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <Header
        prefixTitle={i18n.t('page.application.application')}
        title={getApplicationRequest?.data?.name}
        paddingBottom={50}
      />
      {isLoaded ? (
        <Table
          headers={headers}
          menu={getGrantRequest.data.grantFiles}
          navigateTo={navigateTo}
          buttonCallback={buttonCallback}
        />
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};
export default ApplicationFilesPage;
