import { hideModal } from '@app-studio/web';
import { useParams } from 'react-router-dom';
import { AssistantService, CustomerService, ProjectService } from 'src/services/api';

export const useCustomersRequests = ({ handleEditorCallback, editorCallback, refresh }) => {
  const { projectId } = useParams();

  const getCustomerRequest = CustomerService.useCustomerControllerGetService({});

  const getAllCustomerRequest = CustomerService.useCustomerControllerReadAllService({
    onSuccess: (data) => {
      console.log('inside data');
    },
  });

  const updateSectionRequest = CustomerService.useCustomerSectionControllerUpdateService({
    onSuccess: () => {
      handleEditorCallback();
    },
  });

  const createCustomerRequest = CustomerService.useCustomerControllerCreateCustomerService({
    onSuccess: () => {
      refresh();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  const deleteCustomerRequest = CustomerService.useCustomerControllerDeleteService({
    onSuccess: () => {
      refresh();
    },
  });

  return {
    createCustomerRequest,
    getAllCustomerRequest,
    getCustomerRequest,
    updateSectionRequest,
    generateContentRequest,
    deleteCustomerRequest,
  };
};
