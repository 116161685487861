import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FormikSelect, FormikTextField, Vertical } from '@app-studio/web';
import { FormButtons } from 'src/components/Button';
import { C5 } from 'src/components/Text';

interface ButtonPropsFormProps {
  initialValues: {
    text: string;
    url: string;
    openInNewTab: string;
  };
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  field: {
    header: string;
    headerPlaceholder: string;
    url: string;
    window: string;
    options: any;
  };
}

export const ButtonPropsEditorForm: React.FC<ButtonPropsFormProps> = ({
  initialValues,
  handleSubmit,
  handleCancel,
  field,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical gap={20} height="68vh">
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.header}
          </C5>
          <FormikTextField
            id="text"
            placeholder={field.headerPlaceholder}
            name="text"
            shape={'rounded'}
            variant={'outline'}
            styles={{
              box: {
                borderColor: '#E0E0E0',
                borderStyle: 'solid',
                borderWidth: 1,
                padding: '0 10px',
              },
              text: { color: '#151920' },
              field: {
                height: 32,
                fontSize: 11,
              },
            }}
          />
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.url}
          </C5>
          <FormikTextField
            id="url"
            name="url"
            shape={'rounded'}
            variant={'outline'}
            styles={{
              box: {
                borderColor: '#E0E0E0',
                borderStyle: 'solid',
                borderWidth: 1,
                padding: '0 10px',
              },
              text: { color: '#151920' },
              field: {
                height: 32,
                fontSize: 11,
              },
            }}
          />
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.window}
          </C5>
          <FormikSelect
            id="openInNewTab"
            name="openInNewTab"
            options={field.options}
            shape={'rounded'}
            variant={'outline'}
            styles={{
              box: {
                borderColor: '#E0E0E0',
                borderStyle: 'solid',
                borderWidth: 1,
                backgroundColor: 'white',
                height: 32,
                width: '100%',
              },
              dropDown: { zIndex: 10000 },
              text: { color: '#151920', fontSize: 11 },
            }}
          />
          <Vertical gap={20} width="100%" marginTop="auto">
            <FormButtons
              handleCancel={handleCancel}
              handleButtonSave={formikProps.handleSubmit}
              type="submit"
              styles={{
                buttons: {
                  flexDirection: 'column',
                  gap: 8,
                },
              }}
            />
          </Vertical>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
