import React from 'react';
import { Button } from '@app-studio/web';
import { Element } from 'app-studio';

import { C3, C4 } from 'src/components/Text';
import { formattedDate } from 'src/utils/date';
import i18n from 'src/utils/locale';

import { SourceButtonProps, SourceViewProps, TableCellProps, TableRowProps } from './Source.props';

export const SourceView: React.FC<SourceViewProps> = ({
  source,
  isDeleting,
  isUpdating,
  selectedSource,
  setSelectedSource,
  handleStateCallback,
  handleDeleteCallback,
  ...props
}) => {
  const isActive = source.state;
  const style = { textAlign: 'left', padding: '8px' };
  return (
    <TableRow {...props} color={isActive ? 'black' : '#9B9EA3'}>
      <TableCell {...style}>
        <C3>{source.url}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{source.status}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{formattedDate(new Date(source.createdAt))}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{formattedDate(new Date(source.updatedAt))}</C3>
      </TableCell>
      <TableCell {...style}>
        <SourceButton
          key={source.id}
          buttonCallback={handleStateCallback}
          isLoading={isUpdating}
          text={isActive ? i18n.t('button.deactivate') : i18n.t('button.activate')}
        />
      </TableCell>
      <TableCell {...style}>
        <SourceButton
          key={source.id}
          buttonCallback={handleDeleteCallback}
          isLoading={isDeleting}
          text={i18n.t('button.remove')}
        />
      </TableCell>
    </TableRow>
  );
};

const SourceButton: React.FC<SourceButtonProps> = ({ buttonCallback, isLoading, text }) => (
  <Button
    color="white"
    borderRadius={8}
    onClick={buttonCallback}
    padding="3px 0"
    width="99px"
    cursor={isLoading ? 'not-allowed' : 'pointer'}
    backgroundColor={isLoading ? 'gray' : 'black'}
    isDisabled={isLoading}
    isAuto
  >
    <C4>{text}</C4>
  </Button>
);

const TableRow: React.FC<TableRowProps> = (props) => <Element as="tr" {...props} />;
const TableCell: React.FC<TableCellProps> = (props) => <Element as="td" {...props} />;
