import React from 'react';
import { marked } from 'marked';
import { Button, Horizontal, Vertical } from '@app-studio/web';

import { H5, H4, C1, C3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';

export const AddButton = ({ text, isLoading = false, handleButtonClick, ...props }) => {
  return (
    <Button
      color="white"
      borderRadius={8}
      onClick={handleButtonClick}
      padding="10px 0"
      width="364px"
      height="53px"
      border="none"
      cursor={isLoading ? 'not-allowed' : 'pointer'}
      backgroundColor={isLoading ? 'gray' : 'black'}
      isDisabled={isLoading}
      isAuto
      {...props}
    >
      <H5>{text}</H5>
    </Button>
  );
};

export const Section = ({ id, content, sectionId, isEditing, title, editorCallback }) => (
  <Vertical
    height={'auto'}
    padding={isEditing ? '20px 30px 20px 40px' : '20px 0 20px 0px'}
    backgroundColor={!!(sectionId === id) && isEditing ? '#D9D9D9' : 'inherit'}
  >
    <Header
      TextComponent={H4}
      textProps={{ children: title }}
      editorCallback={() => editorCallback({ id, title, content })}
    />
    <Vertical gap={25} margin={'10px 0'} height="auto">
      <C3 color="black" paddingVertical={10} dangerouslySetInnerHTML={{ __html: marked.parse(content) }} />
    </Vertical>
  </Vertical>
);

export const Header = ({ TextComponent, textProps, editorCallback }) => {
  return (
    <Vertical gap={10}>
      <Horizontal wrap="nowrap" gap={10}>
        <TextComponent {...textProps} />
        <AssetIcon name="Bxs_editSvg" width={24} height={24} onClick={editorCallback} />
      </Horizontal>
    </Vertical>
  );
};

export const AddQuestion = ({ text, navigateTo, ...props }) => {
  return (
    <Horizontal gap={10} wrap="nowrap" {...props} alignItems="center">
      <AssetIcon name="Icon_park_outline_addSvg" onClick={navigateTo} width={24} height={24} />
      <C1>{text}</C1>
    </Horizontal>
  );
};
