import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FormikTextArea, FormikTextField, Vertical } from '@app-studio/web';
import { FormButtons } from 'src/components/Button';
import { C5 } from 'src/components/Text';
import i18n from 'src/utils/locale';

interface TextPropsFormProps {
  initialValues: {
    text?: string;
    subText?: string;
  };
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  field: {
    header: string;
    headerPlaceholder: string;
  };
}

export const TextPropsEditorForm: React.FC<TextPropsFormProps> = ({
  initialValues,
  handleSubmit,
  handleCancel,
  field,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical gap={20} height="68vh">
          <C5 paddingBottom="10px" borderBottom="1px solid #EBEBEB">
            {field.header}
          </C5>
          {initialValues?.text && (
            <FormikTextArea
              id="text"
              placeholder={field.headerPlaceholder}
              name="text"
              shape={'rounded'}
              variant={'outline'}
              styles={{
                box: {
                  borderColor: '#E0E0E0',
                  borderStyle: 'solid',
                  borderWidth: 1,
                },
                text: { color: '#151920' },
              }}
            />
          )}
          {initialValues?.subText && (
            <FormikTextArea
              id="subText"
              placeholder={field.headerPlaceholder}
              name="subText"
              shape={'rounded'}
              variant={'outline'}
              styles={{
                box: {
                  borderColor: '#E0E0E0',
                  borderStyle: 'solid',
                  borderWidth: 1,
                },
                text: { color: '#151920' },
              }}
            />
          )}
          <Vertical gap={20} width="100%" marginTop="auto">
            <FormButtons
              handleCancel={handleCancel}
              handleButtonSave={formikProps.handleSubmit}
              type="submit"
              styles={{
                buttons: {
                  flexDirection: 'column',
                  gap: 8,
                },
              }}
            />
          </Vertical>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
