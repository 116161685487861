/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateApplicationFileSectionParams } from '../models/CreateApplicationFileSectionParams';
import type { CreateApplicationParams } from '../models/CreateApplicationParams';
import type { EditApplicationFileSectionParams } from '../models/EditApplicationFileSectionParams';
import type { UpdateApplicationFileSectionParams } from '../models/UpdateApplicationFileSectionParams';
import type { UpdateApplicationParams } from '../models/UpdateApplicationParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a application
 * @param requestBody
 * @returns any Application created
 * @throws ApiError
 */
export const applicationControllerCreateApplication = (
  requestBody: CreateApplicationParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/application`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read all the application
 * @returns any Application data
 * @throws ApiError
 */
export const applicationControllerListAllApplications = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/application`,
    errors: {
      404: `Applications does not exists`,
    },
  });
};

/**
 * I want to read an application
 * @param id
 * @returns any Application data
 * @throws ApiError
 */
export const applicationControllerRetrieveApplication = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/application/${id}`,
    errors: {
      404: `Application does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a application
 * @param id
 * @returns any application delete
 * @throws ApiError
 */
export const applicationControllerRemoveApplication = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/application/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Application does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a application
 * @param id
 * @param requestBody
 * @returns any Application Updated
 * @throws ApiError
 */
export const applicationControllerUpdateApplication = (
  id: string,
  requestBody: UpdateApplicationParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/application/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a Section
 * @param id
 * @param requestBody
 * @returns any Project Section created
 * @throws ApiError
 */
export const applicationFileSectionControllerCreateApplicationFileSection = (
  id: string,
  requestBody: CreateApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/application/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an applicationFileSection
 * @param id
 * @returns any Section data
 * @throws ApiError
 */
export const applicationFileSectionControllerRetrieveApplicationFileSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/application/section/${id}`,
    errors: {
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a applicationFileSection
 * @param id
 * @returns any applicationFileSection delete
 * @throws ApiError
 */
export const applicationFileSectionControllerRemoveApplicationFileSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/application/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a applicationFileSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const applicationFileSectionControllerUpdateApplicationFileSection = (
  id: string,
  requestBody: UpdateApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/application/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to update a applicationFileSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const applicationFileSectionControllerEditApplicationFileSectionContent = (
  id: string,
  requestBody: EditApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/application/section/${id}/content`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useApplicationControllerCreateApplicationService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: CreateApplicationParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationControllerCreateApplication, {
    method,
    ...options,
  });
};

export const useApplicationControllerListAllApplicationsService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationControllerListAllApplications, {
    method,
    ...options,
  });
};

export const useApplicationControllerRetrieveApplicationService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationControllerRetrieveApplication, {
    method,
    ...options,
  });
};

export const useApplicationControllerRemoveApplicationService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationControllerRemoveApplication, {
    method,
    ...options,
  });
};

export const useApplicationControllerUpdateApplicationService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateApplicationParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationControllerUpdateApplication, {
    method,
    ...options,
  });
};

export const useApplicationFileSectionControllerCreateApplicationFileSectionService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerCreateApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerRetrieveApplicationFileSectionService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerRetrieveApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerRemoveApplicationFileSectionService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerRemoveApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerUpdateApplicationFileSectionService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerUpdateApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerEditApplicationFileSectionContentService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: EditApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerEditApplicationFileSectionContent, { method, ...options });
};
