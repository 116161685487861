import { useAuthStore } from 'src/stores/AuthStore';
import { useMount } from 'app-studio';
import { View } from '@app-studio/web';
// import ImageUploader from 'src/components/ImageUploader/ImageUploader';
import SettingsForm from 'src/forms/SettingsForm';
import { Horizontal, Right, Button, Vertical } from '@app-studio/web';
import { useNavigate } from 'react-router-dom';
import i18n from 'src/utils/locale';
import { AuthService } from 'src/services/api';

function SettingsPage() {
  const MeService = AuthService.useAuthUserControllerMeService();
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  const { user } = useAuthStore();

  useMount(() => {
    MeService.run();
  });

  //console.log({ JoinedStudios, VituralStudios });
  return (
    <SettingsInfoContainer>
      <Horizontal>
        <StudioHeading>{i18n.t('page.settings.info')}</StudioHeading>
      </Horizontal>
      <ProfileForm>
        <ImageContainer1>
          <Horizontal>
            {/* <Vertical>
                <ImageUploader />
                <UploadImageText>{i18n.t('button.uploadImage')}</UploadImageText>
              </Vertical> */}
            <Right>
              <Button
                onClick={() => {
                  logout();
                  navigate('/');
                }}
              >
                {i18n.t('action.logout')}
              </Button>
            </Right>
          </Horizontal>
          <StudioForm>
            <SettingsForm />
          </StudioForm>
        </ImageContainer1>
      </ProfileForm>
    </SettingsInfoContainer>
  );
}

const StudioForm = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const SettingsInfoContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={32}
    padding={20}
    {...props}
  />
);

const StudioHeading = (props) => <View flex={'0 0 auto'} font={'600 24px '} color={'rgba(19, 15, 38, 1)'} {...props} />;

const ProfileForm = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    padding={'20px 19.5px'}
    marginTop={20}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);

const ImageContainer1 = (props) => <View flex={'0 0 auto'} {...props} />;

const UploadImageText = (props) => (
  <View
    marginTop={16}
    font={'400 16px '}
    color={'rgba(85, 52, 165, 1)'}
    textDecorationLine={'underline'}
    width={150}
    textAlign="center"
    {...props}
  />
);

export default SettingsPage;
