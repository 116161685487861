import { Horizontal, Vertical } from '@app-studio/web';

import i18n from 'src/utils/locale';
import { AssetIcon } from 'src/utils/assets';
import { C3, C2 } from 'src/components/Text';

import { ProgressBar } from 'src/components/ProgressBar';
import { View } from 'app-studio';

export const TopElementContainer = (props) => (
  <Horizontal
    boxSizing={'border-box'}
    gap={8}
    alignItems={'center'}
    justifyContent={'space-between'}
    padding={5}
    flexWrap="nowrap"
    backgroundColor="#222836"
    color="#FFFFFF"
    borderRadius={5}
    {...props}
  />
);

export const Usage = ({
  plan,
  usageNumber,
  totalUsage,
  handleClick,
}: {
  plan: string;
  usageNumber: number;
  totalUsage: number;
  handleClick: Function;
}) => {
  return (
    <TopElementContainer onClick={handleClick}>
      <C3 whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" width={100}>
        {plan}
      </C3>
      <View width={50}>
        <ProgressBar usageNumber={usageNumber} totalUsage={totalUsage} />
      </View>
      <C3>{i18n.t('page.planAndUsage.sideMenuUsage.subTitle')}</C3>
      <C3 width={50}>
        {usageNumber}/{totalUsage}
      </C3>
      <AssetIcon name={'ModelEditSvg'} size={20} color="white" />
    </TopElementContainer>
  );
};

export const ModelSelector = ({ modelText, handleModelClick }: { modelText: string; handleModelClick: Function }) => {
  return (
    <TopElementContainer onClick={handleModelClick}>
      <C3 whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" width={250}>
        {modelText}
      </C3>
      <AssetIcon name={'ModelEditSvg'} size={20} color="white" />
    </TopElementContainer>
  );
};

export const TopLayoutContainer = (props) => (
  <Horizontal
    boxSizing={'border-box'}
    alignItems={'flex-end'}
    justifyContent={'space-between'}
    paddingHorizontal={10}
    paddingVertical={20}
    width="100%"
    height={60}
    flexWrap="nowrap"
    {...props}
  />
);
