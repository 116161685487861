import React from 'react';
import { Modal, Vertical, View } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import { ApplicationForm } from 'src/forms/ApplicationForm';

export const CreateApplicationModal = ({ request, initialValues, handleSubmit, handleCancel, fields }) => {
  return (
    <Modal.Container
      padding={0}
      boxShadow="none"
      height={'100vh'}
      position="right"
      width={'80vw'}
      backgroundColor="theme.secondary"
    >
      <Modal.Body border="none" margin={0} height="100%">
        <Vertical padding="20px 30px" flex={1} gap={30} height="100vh">
          <AssetIcon name="BlackCloseSvg" width={14} height={14} marginLeft="auto" onClick={handleCancel} />
          <ApplicationForm
            isLoading={request.isLoading}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            fields={fields}
          />
        </Vertical>
      </Modal.Body>
    </Modal.Container>
  );
};
