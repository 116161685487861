import { createGlobalStyle } from 'styled-components';
import { GoogleFonts } from './assets/fonts';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "${GoogleFonts[0]}";
  }

  a {
    text-decoration: none;
    color: #000;
  }

  body {
    margin: 0;
    box-sizing: border-box;
  }

  .truncate-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines you want to display */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  textarea {
    resize: none;


  }

  *::-webkit-scrollbar {
    display: none;
}

.ce-block__content{
margin:20px 60px;
}
.ce-toolbar__actions{
  left:0;
}

.ce-toolbar__content{
  margin:0;
}
`;
