/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGrantParams } from '../models/CreateGrantParams';
import type { CreateSectionParams } from '../models/CreateSectionParams';
import type { UpdateGrantParams } from '../models/UpdateGrantParams';
import type { UpdateSectionParams } from '../models/UpdateSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a grant
 * @param requestBody
 * @returns any Grant created
 * @throws ApiError
 */
export const grantControllerCreateGrant = (requestBody: CreateGrantParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/grant`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an grant
 * @param id
 * @returns any Grant data
 * @throws ApiError
 */
export const grantControllerRetrieveGrant = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/grant/${id}`,
    errors: {
      404: `Grant does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a grant
 * @param id
 * @returns any grant delete
 * @throws ApiError
 */
export const grantControllerRemoveGrant = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/grant/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Grant does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a grant
 * @param id
 * @param requestBody
 * @returns any Grant Updated
 * @throws ApiError
 */
export const grantControllerUpdateGrant = (id: string, requestBody: UpdateGrantParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/grant/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read all the grant
 * @param projectId
 * @returns any Grant data
 * @throws ApiError
 */
export const grantControllerListAllGrants = (projectId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/grant/project/${projectId}`,
    errors: {
      404: `Grants does not exists`,
    },
  });
};

/**
 * Upload a file.
 * @param id
 * @param formData
 * @returns any File Upload Succeed
 * @throws ApiError
 */
export const grantFileUploadControllerCreateFile = (
  id: string,
  formData: {
    file?: Blob;
  },
  onProgress
): CancelablePromise<any> => {
  return upload({
    onProgress,
    method: 'POST',
    path: `/grant/file/${id}`,
    formData: formData,
    mediaType: 'multipart/form-data',
    errors: {
      400: `Required file is empty`,
      403: `Forbidden. You do not have the rights.`,
    },
  });
};

/**
 * As a admin, i want to delete a grantFile
 * @param id
 * @returns any grantFile delete
 * @throws ApiError
 */
export const grantFileUploadControllerRemoveGrantFile = (id: string, onProgress): CancelablePromise<any> => {
  return upload({
    onProgress,
    method: 'DELETE',
    path: `/grant/file/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Grant does not exists`,
    },
  });
};

/**
 * As an admin, I want to create a section
 * @param id
 * @param requestBody
 * @returns any section created
 * @throws ApiError
 */
export const sectionControllerCreateSection = (
  id: string,
  requestBody: CreateSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/grant/file/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific section
 * @param id
 * @returns any section details fetched
 * @throws ApiError
 */
export const sectionControllerRetrieveSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/grant/file/section/${id}`,
    errors: {
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to delete a section
 * @param id
 * @returns any section deleted
 * @throws ApiError
 */
export const sectionControllerRemoveSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/grant/file/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to update a section
 * @param id
 * @param requestBody
 * @returns any section updated
 * @throws ApiError
 */
export const sectionControllerUpdateSection = (
  id: string,
  requestBody: UpdateSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/grant/file/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useGrantControllerCreateGrantService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateGrantParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantControllerCreateGrant, { method, ...options });
};

export const useGrantControllerRetrieveGrantService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantControllerRetrieveGrant, { method, ...options });
};

export const useGrantControllerRemoveGrantService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantControllerRemoveGrant, { method, ...options });
};

export const useGrantControllerUpdateGrantService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateGrantParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantControllerUpdateGrant, { method, ...options });
};

export const useGrantControllerListAllGrantsService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (projectId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantControllerListAllGrants, { method, ...options });
};

export const useGrantFileUploadControllerCreateFileService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (
    id: string,
    formData: {
      file?: Blob;
    }
  ) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantFileUploadControllerCreateFile, {
    method,
    ...options,
  });
};

export const useGrantFileUploadControllerRemoveGrantFileService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(grantFileUploadControllerRemoveGrantFile, {
    method,
    ...options,
  });
};

export const useSectionControllerCreateSectionService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerCreateSection, { method, ...options });
};

export const useSectionControllerRetrieveSectionService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerRetrieveSection, { method, ...options });
};

export const useSectionControllerRemoveSectionService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerRemoveSection, { method, ...options });
};

export const useSectionControllerUpdateSectionService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerUpdateSection, { method, ...options });
};
