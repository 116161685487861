import { remark } from 'remark';
import { parseMarkdownToHeader } from './BlockTypeParsers/HeaderTypeParser';
import { parseMarkdownToParagraph } from './BlockTypeParsers/ParagraphTypeParser';
import { parseMarkdownToList } from './BlockTypeParsers/ListTypeParser';
import { parseMarkdownToDelimiter } from './BlockTypeParsers/DelimiterTypeParser';
import { parseMarkdownToCode } from './BlockTypeParsers/CodeTypeParser';
import { parseMarkdownToQuote } from './BlockTypeParsers/QuoteTypeParser';
import { parseHeaderToMarkdown } from './BlockTypeParsers/HeaderTypeParser';
import { parseParagraphToMarkdown } from './BlockTypeParsers/ParagraphTypeParser';
import { parseListToMarkdown } from './BlockTypeParsers/ListTypeParser';
import { parseDelimiterToMarkdown } from './BlockTypeParsers/DelimiterTypeParser';
import { parseImageToMarkdown, parseMarkdownToImage, parseImageToHtml } from './BlockTypeParsers/ImageTypeParser';
import { parseCheckboxToMarkdown } from './BlockTypeParsers/CheckboxTypeParser';
import { parseQuoteToMarkdown } from './BlockTypeParsers/QuoteTypeParser';
import { parseCodeToMarkdown } from './BlockTypeParsers/CodeTypeParser';

export const getEditorBlocks = async (markdownContent) => {
  const editorData = [];

  // Process the markdown content asynchronously
  const processedContent = await remark()
    .use(() => (tree) => {
      // Here we directly manipulate the syntax tree
      tree.children.forEach((item) => {
        console.log({ item });
        switch (item.type) {
          case 'heading':
            editorData.push(parseMarkdownToHeader(item));
            break;
          case 'paragraph':
            editorData.push(parseMarkdownToParagraph(item));
            break;
          case 'list':
            editorData.push(parseMarkdownToList(item));
            break;
          case 'thematicBreak':
            editorData.push(parseMarkdownToDelimiter());
            break;
          case 'code':
            editorData.push(parseMarkdownToCode(item));
            break;
          case 'blockquote':
            editorData.push(parseMarkdownToQuote(item));
            break;
          case 'html':
            editorData.push(parseMarkdownToImage(item));
            break;
          case 'image':
            editorData.push(parseImageToMarkdown(item));
            break;
          default:
            break;
        }
      });
    })
    .process(markdownContent);

  // Optionally, you can now use editorData as needed
  return editorData;
};

export const getMarkdown = ({ data }) => {
  const initialData = {};
  initialData.content = data.blocks;

  const parsedData = initialData.content.map((item) => {
    // iterate through editor data and parse the single blocks to markdown syntax
    switch (item.type) {
      case 'header':
        return parseHeaderToMarkdown(item.data);
      case 'paragraph':
        return parseParagraphToMarkdown(item.data);
      case 'list':
        return parseListToMarkdown(item.data);
      case 'delimiter':
        return parseDelimiterToMarkdown(item);
      case 'image':
        return parseImageToHtml(item.data);
      case 'quote':
        return parseQuoteToMarkdown(item.data);
      case 'checkbox':
        return parseCheckboxToMarkdown(item.data);
      case 'code':
        return parseCodeToMarkdown(item.data);
      case 'checklist':
        return parseCheckboxToMarkdown(item.data);
      default:
        break;
    }
  });
  return parsedData.join('\n');
};
