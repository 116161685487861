import { hideModal } from '@app-studio/web';
import { useNavigate, useParams } from 'react-router-dom';
import { NewsletterService } from 'src/services/api';

export const useNewsletterSourcesRequests = (onRefresh?: Function) => {
  const { projectId, id } = useParams();
  const navigate = useNavigate();

  const refresh = () => {
    getNewsletterRequest.run(id);
  };

  const getNewsletterRequest = NewsletterService.useNewsletterControllerReadService({});

  const createSourceRequest = NewsletterService.useNewsletterControllerCreateSourceService({
    onSuccess: () => {
      refresh();
      hideModal();
    },
  });

  const updateSourceRequest = NewsletterService.useNewsletterControllerUpdateSourceService({
    onSuccess: () => {
      refresh();
    },
  });

  const deleteSourceRequest = NewsletterService.useNewsletterControllerDeleteSourceService({
    onSuccess: () => {
      refresh();
    },
  });

  const createContentRequest = NewsletterService.useNewsletterControllerGenerateContentService({
    onSuccess: () => {
      navigate(`/${projectId}/social/newsletter/${id}`);
    },
  });

  return {
    getNewsletterRequest,
    createSourceRequest,
    updateSourceRequest,
    deleteSourceRequest,
    createContentRequest,
  };
};
