import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FormikSelect, Vertical } from '@app-studio/web';

import { Label } from 'src/components/Text';
import { FormButtons } from 'src/components/Button';

interface CustomFormProps {
  isLoading: boolean;
  initialValues: any;
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  fields: Array<{
    name: string;
    label: string;
    options: string[];
  }>;
}

export const ApplicationForm: React.FC<CustomFormProps> = ({
  isLoading = false,
  initialValues,
  handleSubmit,
  handleCancel,
  fields,
}) => (
  <Formik initialValues={{ initialValues }} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical alignItems="center">
          <Vertical width="70vw" gap={50}>
            {fields.map((field) => (
              <Vertical gap={20}>
                <Label>{field.label}</Label>
                <FormikSelect
                  key={field.name}
                  name={field.name}
                  options={field.options}
                  styles={{
                    box: {
                      borderColor: 'black',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      backgroundColor: 'white',
                      height: 52,
                    },
                    text: { color: 'black', fontSize: 18, fontWeight: 500 },
                  }}
                />
              </Vertical>
            ))}
            <FormButtons
              isLoading={isLoading}
              handleCancel={handleCancel}
              handleButtonSave={formikProps.handleSubmit}
              type="submit"
            />
          </Vertical>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
