import React from 'react';
import { FormikForm, FormikTextField, Loader, Vertical } from '@app-studio/web';
import { Label, C5 } from 'src/components/Text';
import { CreateProjectFormProps } from './project.type';
import { Formik } from 'formik';
import { NextButton } from 'src/components/Button';
import { ProgressBar } from 'src/components/ProgressBar';

export const CreateProjectForm: React.FC<CreateProjectFormProps> = ({ initialValues, onNext, placeholder }) => (
  <Formik initialValues={initialValues} onSubmit={onNext}>
    {(formikProps) => (
      <FormikForm>
        <Vertical gap={20} width="670px">
          <FormikTextField
            key={'name'}
            name={'name'}
            placeholder={placeholder}
            styles={{
              box: {
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: 1,
              },
              text: { color: 'black' },
            }}
          />
          <NextButton handleSubmit={formikProps.handleSubmit} />
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);

export const Loading = ({ title, usage, totalUsage, tasksInProgress }) => {
  const project = tasksInProgress.length > 0 ? `: ${tasksInProgress[tasksInProgress.length - 1]}` : '';
  return (
    <Vertical justifyContent="center" alignItems="center" gap={70} width={'450px'}>
      <Vertical>
        <Label>{`${title} ${project.toUpperCase()}`}</Label>
        {/* {tasksInProgress.length > 0 &&
          tasksInProgress.map((taskName, index) => (
            <Label key={index} color="black">
              {taskName}
            </Label>
          ))} */}
      </Vertical>
      <Loader type="dotted" size="2xl" />
      <ProgressBar usageNumber={usage} totalUsage={totalUsage} showPercentage={true} />
    </Vertical>
  );
};
