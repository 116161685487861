import React from 'react';
import { hideModal, Horizontal, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';

import { Section, AddButton } from './document.element';
import { GrantHeader } from '../grant/grant.element';
import { useDocumentStates } from './document.state';
import { useDocumentRequests } from './document.request';
import { useParams, useNavigate } from 'react-router-dom';
import { useMount } from 'app-studio';

export const DocumentPage = () => {
  let section = { id: '', content: '', title: '' };
  const navigate = useNavigate();

  const { id, fileId } = useParams();
  const { isEditing, setIsEditing } = useDocumentStates();

  const handleEditorCallback = () => {
    setIsEditing(false);
    hideModal();
  };

  const sectionRequests = useDocumentRequests({ handleEditorCallback });

  const { getGrantRequest, updateSectionRequest, createSectionRequest } = sectionRequests;

  useMount(() => {
    getGrantRequest.run(id);
  });

  const files = getGrantRequest.data?.grantFiles ?? [];
  const file = files.find((file) => file.id === fileId);
  const sections = file?.sections ?? [];

  const handleAddSourceButton = () => {
    showEditorModal({ title: '', content: '' });
    setIsEditing(true);
  };

  const handleSubmit = (event: any, { setSubmitting }) => {
    console.log({ event });
    if (section.content) {
      if (section.id) {
        updateSectionRequest.run(section.id, { title: event.name, content: section.content });
      } else {
        createSectionRequest.run(fileId, { title: event.name, content: section.content });
      }
    }
  };

  const handleCancel = () => {
    hideModal();
    setIsEditing(false);
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;
    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const showEditorModal = ({ title, content }: { title?: string; content: string }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(), // Unique key on each open
        handleOnClick: handleCancel,
        isAIEditable: false,
        buttonSaveType: 'submit',
        sectionTitle: i18n.t('page.grant.secName'),
        sectionTitleKey: 'name',
        sectionContent: content,
        initialValues: {
          name: title,
        },
        handleSubmit: handleSubmit,
        handleCancel: handleCancel,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleCancel }
    );
  };

  return (
    <Horizontal wrap="nowrap" height="100vh" width="100%" overflow="auto">
      {!getGrantRequest.loader && getGrantRequest.data && getGrantRequest.data.grantFiles && (
        <Vertical height="100%" width="100%" padding={isEditing ? '60px 0' : '60px 40px'} flex={1} oveflow="auto">
          <GrantHeader
            handleNavigation={() => navigate(-1)}
            title={getGrantRequest.data?.name ?? ''}
            paddingLeft={isEditing ? '40px' : '0'}
            paddingBottom="50px"
          />
          {sections.length > 0 &&
            sections.map((section) => (
              <Section
                id={section.id}
                key={section.id}
                sectionId={section.id}
                isEditing={isEditing}
                title={section.title}
                editorCallback={handleEditorSelection}
                content={section.content}
              />
            ))}
          {!isEditing && (
            <AddButton
              marginLeft="auto"
              text={i18n.t('page.grant.addSection')}
              handleButtonClick={handleAddSourceButton}
            />
          )}
        </Vertical>
      )}
    </Horizontal>
  );
};
