export const Typography = {
  H1: {
    fontSize: 47,
    fontWeight: 700,
  },
  H2: {
    fontSize: 36,
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  H3: {
    fontSize: 30,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 30,
  },
  H4: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  H5: {
    fontSize: 20,
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 22,
  },
  H6: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  H7: {
    fontSize: 12,
  },
  Label: {
    fontSize: 18,
    lineHeight: 16,
    letterSpacing: 0,
    fontWeight: 700,
    fontStyle: 'normal',
  },
  C1: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 23,
  },
  C2: {
    fontSize: 18,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 22,
  },
  C3: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 22,
  },
  C4: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 18,
  },
  C5: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 14.5,
  },
};

export default Typography;
