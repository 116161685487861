import { create } from 'zustand';

export interface AppState {
  ready: boolean;
  server: boolean;
  started: boolean;
  loaded: boolean;
  initialUrl: string | null;
  redirectTo: string | null;
  device: string;
  projects: any[] | null;
  project: any;
  projectsById: { [key: string]: any };
  landingCode: string;
  setLandingCode: (code: any) => void;
  setProjects: (projects: any) => void;
  setProject: (project: any) => void;
  setDevice: (device: string) => void;
  setStarted: () => void;
  setLoaded: () => void;
  setReady: () => void;
  setServer: () => void;
  setRedirectTo: (redirectTo: string | null) => void;
  setRedirectOnHome: (redirectOnHome: string | null) => void;
}

export const useAppStore = create<AppState>((set) => ({
  landingCode: '',
  projects: null,
  project: null,
  projectsById: {},
  device: 'desktop',
  ready: false,
  server: false,
  started: false,
  loaded: false,
  redirectTo: null,
  initialUrl: null,
  redirectOnHome: null,

  setLandingCode: (landingCode) => {
    set((state) => ({
      ...state,
      landingCode,
    }));
  },

  setProjects: (projects) => {
    let newProjects: any[] = [];
    let ProjectsById: { [key: string]: any } = {};
    projects.map((project) => {
      if (ProjectsById[project.id] == undefined) {
        newProjects.push(project);
        ProjectsById[project.id] = project;
      }
    });

    console.log('ProjectsById:', ProjectsById);
    console.log('newProjects:', newProjects);

    set((state) => ({
      ...state,
      projects: newProjects,
      projectsById: ProjectsById,
    }));
  },
  setProject: (project) => {
    set((state) => ({
      ...state,
      project,
    }));
  },
  setServer: () => {
    set((state) => ({
      ...state,
      server: true,
    }));
  },
  setDevice: (device: string) => {
    set((state) => ({
      ...state,
      device,
    }));
  },
  setStarted: () => {
    set((state) => ({
      ...state,
      started: true,
    }));
  },
  setLoaded: () => {
    set((state) => ({
      ...state,
      loaded: true,
    }));
  },
  setReady: () => {
    set((state) => ({
      ...state,
      ready: true,
    }));
  },
  setRedirectTo: (redirectTo: string | null | undefined) => {
    set((state) => ({
      ...state,
      redirectTo: state.ready ? null : redirectTo,
      initialUrl: null,
    }));
  },
  setRedirectOnHome: (redirectOnHome: any) => {
    set((state) => ({
      ...state,
      redirectOnHome,
    }));
  },
}));
