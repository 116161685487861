import { View } from 'app-studio';
import { useNavigate } from 'react-router';
import Logo from 'src/components/Logo';

function LeftSide() {
  const navigate = useNavigate();

  return (
    <Container>
      <LogoContainer
        onClick={() => {
          navigate('/');
        }}
      >
        <Logo />
      </LogoContainer>
    </Container>
  );
}

export default LeftSide;

const Container = (props) => (
  <View
    boxSizing={'border-box'}
    width={'50vw'}
    height={'100vh'}
    background={'black'}
    media={{ mobile: { display: 'none' } }}
    {...props}
  />
);

const LogoContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);
