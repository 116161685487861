import { AssistantService, ProjectService } from 'src/services/api';

export const useProjectConfigRequests = ({ updateSectionCallback, generateContentCallback, getProjectCallback }) => {
  const getProjectRequest = ProjectService.useProjectControllerRetrieveProjectService({
    onSuccess: (data) => {
      getProjectCallback(data);
    },
  });

  const updateSectionRequest = ProjectService.useProjectSectionControllerUpdateProjectSectionService({
    onSuccess: (data) => {
      updateSectionCallback(data);
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      generateContentCallback(data);
    },
  });

  return {
    getProjectRequest,
    updateSectionRequest,
    generateContentRequest,
  };
};
