import React, { useState } from 'react';
import { RouterProvider } from 'src/providers/RouterProvider';
import { ThemeProvider, ResponsiveProvider, useMount } from 'app-studio';
import { RequestProvider } from '@app-studio/react-request';
import Colors from 'src/configs/ColorConfig.json';
import AppLayout from 'src/layouts/AppLayout';
import { useLoadingLayout } from 'src/layouts/LoadingLayout';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';

const AppProvider = ({ children }) => {
  const [online, setOnline] = useState(true);
  const [status, setAppStatus] = useState('active');
  const { getRoot } = useLoadingLayout();

  const { logout } = useAuthStore();

  useMount(() => {
    document.addEventListener(
      'visibilitychange',
      () => {
        setAppStatus(document.hidden !== true ? 'active' : 'inactive');
        if (document.hidden !== true && 'inactive' === status) {
          getRoot.run();
        }
      },
      false
    );

    if (typeof window !== 'undefined') {
      window.addEventListener('online', () => setOnline(!!window.navigator.onLine));
      window.addEventListener('offline', () => setOnline(!!window.navigator.onLine));
    }
  });

  const theme = {
    main: {
      primary: 'black',
      secondary: '#E2E1E1',
      tertiary: '#BBC2CA',
      disabled: '#E2E1E1',
      loading: 'black',
    },
    components: {},
  };

  return (
    <RequestProvider
      cached={true}
      debug={true}
      connectionStatus={online}
      appStatus={status}
      successKey="data"
      every={{
        onError: (
          e
          // params, name, method
        ) => {
          // error(e);
          const error: any = e;
          if (error.status === 401 || error.status === 403) {
            logout();
            document.location.href = '/';
          }
        },
        onOnline: (run, params, name, method) => {
          if (method === 'GET') {
            run(...params);
          }
        },
        onAppStatusChange: (status, run, params, name, method) => {
          if (method === 'GET' && status === 'active') {
            run(...params);
          }
        },
        onRetry(run, params, name, method, setLoading, setLoader, setData) {
          console.info('retry : ', name, method);
        },
      }}
    >
      <RouterProvider>
        <ThemeProvider theme={theme} colors={Colors}>
          <ResponsiveProvider
            breakpoints={{
              xs: 0,
              sm: 340,
              md: 560,
              lg: 1080,
              xl: 1300,
            }}
            devices={{
              mobile: ['xs', 'sm'],
              tablet: ['md', 'lg'],
              desktop: ['lg', 'xl'],
            }}
          >
            <AppLayout online={online} status={status}>
              {children}
            </AppLayout>
          </ResponsiveProvider>
        </ThemeProvider>
      </RouterProvider>
    </RequestProvider>
  );
};

export default AppProvider;
