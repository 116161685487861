import { hideModal } from '@app-studio/web';
import { useParams } from 'react-router-dom';
import { AssistantService, MarketService, ProjectService } from 'src/services/api';

export const useMarketsRequests = ({ handleEditorCallback, editorCallback }) => {
  const { projectId } = useParams();

  const getMarketRequest = MarketService.useMarketControllerGetService({});

  const updateSectionRequest = MarketService.useMarketSectionControllerUpdateService({
    onSuccess: () => {
      getMarketRequest.run(projectId);
      handleEditorCallback();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getMarketRequest,
    updateSectionRequest,
    generateContentRequest,
  };
};
