import React, { useState, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Apps } from './components/home.views';
import { View, Button, Vertical } from '@app-studio/web';
import { C2, H1 } from 'src/components/Text';
import { useMount } from 'app-studio';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'src/stores/AuthStore';

const HomePage = () => {
  const { isAuthentificated } = useAuthStore(({ isAuthentificated }) => ({
    isAuthentificated,
  }));

  return (
    <Vertical>
      <Vertical paddingTop={100} paddingHorizontal={30}>
        <H1 marginBottom={20}>Bring your ideas to life</H1>
        <C2 paddingLeft={-5}>AI-generated application developpment at your fingertips</C2>
        <TypeAnimation
          aria-hidden="true"
          preRenderFirstString
          style={{ fontSize: '1rem' }}
          speed={30}
          deletionSpeed={50}
          sequence={[
            700,
            'SaaS',
            1600,
            'AI application',
            1600,
            'Website',
            1250,
            'Blog',
            1400,
            'Marketplace',
            1300,
            'Platform',
            1600,
            'Social Network',
          ]}
        />
        <Button borderRadius="16px" marginTop={20}>
          Get Started
        </Button>
      </Vertical>

      <Apps />
      <H1 marginVertical={100} paddingHorizontal={30}>
        We believe in a world where technology and creativity becomes a powerful tool in the hands of entrepreneurs
      </H1>

      <View width="100vw" height="50px" marginTop={100} paddingHorizontal={20}>
        <C2>All rights reserved. ©App Studio 2023</C2>
      </View>
    </Vertical>
  );
};

export default HomePage;
