import React from 'react';
import { Modal } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import { ModelForm } from 'src/forms/ModelForm';
import { Label, C3, H3 } from 'src/components/Text';
import i18n from 'src/utils/locale';

export const ModelModal = ({ initialValues, handleSubmit, handleCancel, field }) => {
  return (
    <Modal.Container padding={20} boxShadow="none" backgroundColor="#D9D9D9">
      <Modal.Header buttonPosition="none" padding={'10px 0 20px'} position="relative">
        <AssetIcon
          name="BlackCloseSvg"
          width={16}
          height={16}
          position="absolute"
          top={5}
          right={5}
          onClick={handleCancel}
          color="black"
        />
      </Modal.Header>
      <Modal.Body border="none" width="100%">
        <Label textAlign="left" marginBottom={30}>
          {i18n.t('modal.model.title')}
        </Label>
        <ModelForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          field={field}
        />
      </Modal.Body>
    </Modal.Container>
  );
};
