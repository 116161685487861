import { Vertical } from '@app-studio/web';
import { marked } from 'marked';
import { H6, C4, H4, H5 } from 'src/components/Text';
import { SectionPageHeader } from './Header';

export const Section = ({ id, content, sectionId, isEditing, title, editorIconCallback }) => (
  <Vertical
    padding={isEditing ? '20px 30px 20px 40px' : '20px 0 20px 0px'}
    height={!!(sectionId === id) && isEditing ? 'auto' : '100%'}
    backgroundColor={!!(sectionId === id) && isEditing ? '#D9D9D9' : 'inherit'}
  >
    <SectionPageHeader
      TextComponent={H5}
      textProps={{ children: title }}
      editorCallback={() => editorIconCallback({ id, title, content })}
    />
    <Vertical gap={25} margin={'10px 0'}>
      <C4
        color="black"
        overflow={'auto'}
        paddingVertical={10}
        dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
      />
    </Vertical>
  </Vertical>
);
