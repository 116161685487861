import { View, Button, Horizontal, Vertical } from '@app-studio/web';
import { ReactComponent as MagicIcon } from 'src/assets/svg/magic.svg';
import { ReactComponent as BackArrowIcon } from 'src/assets/svg/back_arrow.svg';
import { ReactComponent as ForwardArrowIcon } from 'src/assets/svg/forward_arrow.svg';
import { H3 } from 'src/components/Text';

const Welcome = (props) => (
  <View fontSize="40px" lineHeight="48px" color="var(--color-black)" display="inline-block" width="906px" {...props} />
);

export const Head = ({ handleCreateButton, title }) => (
  <Vertical width="100%" marginBottom={50}>
    <H3>{title}</H3>
    <View display="flex" justifyContent="flex-end" width="100%">
      <Button
        height={40}
        width={200}
        icon={<MagicIcon />}
        iconPosition="right"
        marginTop="30px"
        onClick={handleCreateButton}
        cursor="pointer"
      >
        Create
      </Button>
    </View>
  </Vertical>
);

const Pagination = (props) => (
  <View width="32px" height="26px" textAlign="center" {...props} backgroundColor="#E2E1E1">
    1
  </View>
);

export const PageNavigation = () => (
  <Horizontal gap={10} justifyContent="center" alignItems="center" position="absolute" top="710px" left="1339px">
    <BackArrowIcon />
    <Pagination />
    <ForwardArrowIcon />
  </Horizontal>
);
