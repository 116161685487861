import { useState } from 'react';
import { Mode } from './editor.props';
import { DeviceType } from './editor.type';

export const useChatStates = () => {
  const [type, setType] = useState<DeviceType>('desktop');
  const [isInspectorEnabled, setIsInspectorEnabled] = useState(false);
  const [selectedElement, setSelectedElement] = useState<HTMLElement | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [components, setComponents] = useState<string[] | []>([]);
  const [showSections, setShowSections] = useState<boolean>(true);
  const [dirty, setDirty] = useState<boolean>(false);
  const [sections, setSections] = useState<any[]>([]);
  const [landingCode, setLandingCode] = useState<any>('');
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [selectedMode, setSelectedMode] = useState<Mode>({ text: 'Prompt', icon: 'PromptSvg' });
  const [selectedProps, setSelectedProps] = useState<Record<string, any> | null>(null);
  return {
    dirty,
    setDirty,
    type,
    setType,
    isInspectorEnabled,
    setIsInspectorEnabled,
    selectedElement,
    setSelectedElement,
    code,
    setCode,
    components,
    setComponents,
    showSections,
    setShowSections,
    sections,
    setSections,
    landingCode,
    setLandingCode,
    selectedSection,
    setSelectedSection,
    selectedMode,
    setSelectedMode,
    selectedProps,
    setSelectedProps,
  };
};
