import { parse } from 'node-html-parser';

export function parseImageToMarkdown(blocks) {
  return `![${blocks.caption}](${blocks.url} "${blocks.caption}")`.concat('\n');
}

export function parseImageToHtml(blocks) {
  const { url, caption, title } = blocks;
  return `<img src="${url}" alt="${caption}" title="${title}" style="object-fit: contain; width: 100%;" />\n`;
}

export const parseMarkdownToImage = (item) => {
  const root = parse(item.value);
  const img = root.querySelector('img');

  return {
    type: 'image',
    data: {
      url: img.getAttribute('src'),
      caption: img.getAttribute('alt') || '',
      title: img.getAttribute('title') || '',
      withBorder: false,
      withBackground: false,
      stretched: true,
    },
  };
};
