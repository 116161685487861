import React from 'react';
import { Button, Horizontal, showModal } from '@app-studio/web';
import { C4, C3, H3 } from 'src/components/Text';
import { Element } from 'app-studio';
import i18n from 'src/utils/locale';
import { GrantHeaderProps } from './grant.props';

const TableContainer = (props: any) => <Element as="table" {...props} />;
const TableHead = (props: any) => <Element as="thead" {...props} />;
const TableRow = (props: any) => <Element as="tr" {...props} />;
const TableHeadCell = (props: any) => <Element as="th" {...props} />;
const TableBody = (props: any) => <Element as="tbody" {...props} />;
const TableCell = (props: any) => <Element as="td" {...props} />;

export const AddGrantButton = ({ text, createGrantCallback }) => (
  <Button
    color="white"
    marginLeft="auto"
    width="200px"
    onClick={() => showModal('CreateGrantModal', { key: new Date().getTime(), createGrantCallback })}
    isAuto
  >
    <C3>{text}</C3>
  </Button>
);

export const GrantTable = ({ grants, headers, navigateToSource, buttonCallback }) => {
  return (
    <TableContainer width="100%" borderCollapse="collapse">
      <TableHead borderWidth="0px 0px 0.5px 0px" borderColor="#E6E6E6" borderStyle="solid" padding={8}>
        <TableRow>
          {headers.map((title: string, index: number) => (
            <TableHeadCell key={index} width={index === 0 ? '60%' : '10%'} textAlign="left" padding="8px">
              <C3>{title}</C3>
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <Grants grants={grants} navigateToSource={navigateToSource} buttonCallback={buttonCallback} />
      </TableBody>
    </TableContainer>
  );
};

export const Grants = ({ grants, navigateToSource, buttonCallback }) => {
  const formattedDate = (date: number | Date | undefined) =>
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date);

  return grants.map((grant, index: number) => (
    <TableRow key={index} padding="8px" borderWidth={index === 0 ? '0' : '1px 0 0 0'} borderColor="color.gray.30">
      <TableCell width="60%" padding="8px">
        <C3 onClick={() => navigateToSource(grant.id)}>{grant.name}</C3>
      </TableCell>
      <TableCell width="10%" padding="8px">
        <C3>{formattedDate(new Date(grant.createdAt))}</C3>
      </TableCell>
      <TableCell width="10%" padding="8px">
        <C3>{formattedDate(new Date(grant.updatedAt))}</C3>
      </TableCell>
      <TableCell width="10%" padding="8px"></TableCell>
      <TableCell width="10%" padding="8px">
        <Button
          onClick={() => buttonCallback(grant.id)}
          color="white"
          borderRadius={8}
          padding="3px 0"
          width="99px"
          isAuto
        >
          <C4>{i18n.t('button.remove')}</C4>
        </Button>
      </TableCell>
    </TableRow>
  ));
};

export const GrantHeader: React.FC<GrantHeaderProps> = ({ title, handleNavigation, ...props }) => (
  <Horizontal gap={10} {...props}>
    <H3 onClick={handleNavigation}>{i18n.t('page.grant.title')}</H3>
    <H3>{`>`}</H3>
    <H3>{title}</H3>
  </Horizontal>
);
