import advantagespng from './advantages.png';
import assurancespng from './assurances.png';
import benefitspng from './benefits.png';
import competitorspng from './competitors.png';
import ctapng from './cta.png';
import featurespng from './features.png';
import footerpng from './footer.png';
import heropng from './hero.png';
import landingpng from './landing.png';
import listpng from './list.png';
import mastercardpng from './mastercard.png';
import pricingpng from './pricing.png';
import socialpng from './social.png';
import valuepng from './value.png';
import visapng from './visa.png';

export default {
 advantagespng,
 assurancespng,
 benefitspng,
 competitorspng,
 ctapng,
 featurespng,
 footerpng,
 heropng,
 landingpng,
 listpng,
 mastercardpng,
 pricingpng,
 socialpng,
 valuepng,
 visapng,
};
