import { useParams } from 'react-router-dom';
import { AssistantService, ProjectService } from 'src/services/api';

export const useProjectDescriptionsRequests = ({ handleEditorCallback, editorCallback }) => {
  const { projectId } = useParams();

  const getProjectRequest = ProjectService.useProjectControllerRetrieveProjectService({});

  const createSectionRequest = ProjectService.useProjectSectionControllerCreateProjectSectionService({
    onSuccess: () => {
      getProjectRequest.run(projectId);
      handleEditorCallback();
    },
  });
  const updateSectionRequest = ProjectService.useProjectSectionControllerUpdateProjectSectionService({
    onSuccess: () => {
      getProjectRequest.run(projectId);
      handleEditorCallback();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getProjectRequest,
    updateSectionRequest,
    createSectionRequest,
    generateContentRequest,
  };
};
