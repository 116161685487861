import React from 'react';
import { Center, Vertical } from '@app-studio/web';
import { Button, Element } from 'app-studio';

import i18n from 'src/utils/locale';
import { C4, C3, C2 } from 'src/components/Text';

import { formattedDate } from 'src/utils/date';
import { DocumentProps, DocumentButtonProps, DocumentsTableProps } from './grantUploader.props';

export const Document: React.FC<DocumentProps> = ({
  file,
  isDeleting,
  handleDeleteCallback,
  buttonText,
  navigateTo,
  ...props
}) => {
  const style = { textAlign: 'left', padding: '8px' };
  return (
    <TableRow {...props} color={'black'}>
      <TableCell {...style} onClick={() => navigateTo(file.id)}>
        <C3>{file.url}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{file.status}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{formattedDate(new Date(file.createdAt))}</C3>
      </TableCell>
      <TableCell {...style}>
        <C3>{formattedDate(new Date(file.updatedAt))}</C3>
      </TableCell>
      <TableCell {...style}>
        <DocumentButton buttonCallback={handleDeleteCallback} isLoading={isDeleting} text={buttonText} />
      </TableCell>
    </TableRow>
  );
};

const DocumentButton: React.FC<DocumentButtonProps> = ({ buttonCallback, isLoading, text }) => (
  <Button
    color="white"
    borderRadius={8}
    onClick={buttonCallback}
    padding="3px 0"
    width="99px"
    border="none"
    cursor={isLoading ? 'not-allowed' : 'pointer'}
    backgroundColor={isLoading ? 'gray' : 'black'}
    isDisabled={isLoading}
    isAuto
  >
    <C4>{text}</C4>
  </Button>
);

export const DocumentsTable: React.FC<DocumentsTableProps> = ({
  isLoading,
  headers,
  buttonText,
  files,
  navigateTo,
  handleDeleteCallback,
}) => {
  return (
    <Vertical>
      <TableContainer width="100%" borderCollapse="collapse">
        <TableHead borderWidth="0px 0px 0.5px 0px" borderColor="#E6E6E6" borderStyle="solid" padding={8}>
          <TableRow>
            {headers.length > 0 &&
              headers.map((title: string, index: number) => (
                <TableHeadCell key={index} width={index > 1 ? '10%' : '30%'} textAlign="left" padding="8px">
                  <C3>{title}</C3>
                </TableHeadCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index: number) => (
            <Document
              key={index}
              file={file}
              buttonText={buttonText}
              isDeleting={isLoading}
              navigateTo={navigateTo}
              handleDeleteCallback={() => handleDeleteCallback(file.id)}
            />
          ))}
        </TableBody>
      </TableContainer>
    </Vertical>
  );
};

export const Loading = () => (
  <TableRow height="100%" width="100%">
    <TableCell colSpan={6} textAlign="center" padding={20}>
      Loading
    </TableCell>
  </TableRow>
);

export const VideoTranslation = ({
  fileInputRef,
  uploading,
  uploadText,
  handleUpload,
  setDroppedFile,
  setIsDragging,
  isDragging,
}) => {
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setDroppedFile(files[0]);
      console.log('Selected files:', files);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
    e.dataTransfer.dropEffect = 'copy'; // Show green plus cursor
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setDroppedFile(files[0]);
      console.log('Dropped files:', files);
    }
  };

  return (
    <Vertical width="100%" alignItems="center">
      <Center flexDirection="column" borderRadius={8} border="0.5px solid black" width="70%" padding={20} gap={20}>
        <Center
          width="100%"
          height={198}
          position="relative"
          border="0.5px solid black"
          padding="50px 0 30px"
          flexDirection="column"
          gap={20}
          borderRadius={8}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleUploadClick}
          cursor={isDragging ? 'copy' : 'pointer'}
          bg={isDragging ? 'gray.200' : 'white'}
        >
          <C2>{uploadText}</C2>
          <C4 color="#818999">{i18n.t('page.translate.maxSize')}</C4>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        </Center>
        <Center wrap="nowrap" position="relative" width="100%">
          <Button
            color="white"
            padding={5}
            cursor={uploading ? 'not-allowed' : 'pointer'}
            onClick={handleUpload}
            backgroundColor={uploading ? 'theme.tertiary' : 'black'}
            disabled={uploading}
            border="none"
            borderRadius={10}
            isAuto
          >
            <Center width={190} gap={20}>
              <C3>{i18n.t('page.translate.upload')}</C3>
            </Center>
          </Button>
        </Center>
      </Center>
    </Vertical>
  );
};

const TableContainer = (props: any) => <Element as="table" {...props} />;
const TableHead = (props: any) => <Element as="thead" {...props} />;
const TableRow = (props: any) => <Element as="tr" {...props} />;
const TableHeadCell = (props: any) => <Element as="th" {...props} />;
const TableBody = (props: any) => <Element as="tbody" {...props} />;
const TableCell = (props: any) => <Element as="td" {...props} />;
