import React from 'react';
import { Text, TextProps } from 'app-studio';
import { Vertical, Horizontal, Button, View, Center } from '@app-studio/web';
import { CtaProps } from './Cta.interfaces'; // Adjust the import path as necessary
import Icon from 'react-web-vector-icons';

const CtaSection: React.FC<CtaProps> = ({
  mode,
  handlePropsSelection,
  textProps: { header, subHeader },
  designProps = {},
  interactionProps: { button },
}) => {
  const { buttonPosition = 'right' } = designProps;

  // Helper for conditional navigation
  const handleNavigation = ({ path, openInNewTab }: { path: string; openInNewTab: string }) => {
    if (openInNewTab === 'yes') {
      window.open(path, '_blank');
    } else {
      window.location.href = path;
    }
  };

  const alignments: any = {
    left: { content: 'flex-end', text: 'right' },
    center: { content: 'center', text: 'center' },
    right: { content: 'flex-start', text: 'left' },
  };

  const alignContent = alignments[buttonPosition].content;
  const alignText = alignments[buttonPosition].text;

  // Extract common styling and conditions to reduce repetition
  const isTextMode = mode === 'Text' || mode === 'Prompt';
  const isInteractionMode = mode === 'Interaction' || mode === 'Prompt';

  const textStyles = {
    cursor: isTextMode ? 'pointer' : 'not-allowed',
    opacity: isTextMode ? 1 : 0.1,
    border: mode === 'Text' ? '2px dotted gray' : 'none',
  };

  const interactionStyles = {
    opacity: isInteractionMode ? 1 : 0.1,
    cursor: isInteractionMode ? 'pointer' : 'not-allowed',
    borderWidth: mode === 'Interaction' ? '2px' : 'none',
    borderColor: mode === 'Interaction' ? 'gray' : 'none',
    borderStyle: mode === 'Interaction' ? 'dotted' : 'none',
  };

  const containerResponsiveProps: any = {
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    media: {
      mobile: {
        padding: '40px 40px',
      },
      tablet: {
        padding: '40px 60px',
      },
      desktop: {
        padding: '40px 150px',
      },
    },
    ...designProps?.containerProps,
  };

  const contentResponsiveProps: any = {
    width: '100%',
    wrap: 'nowrap',
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'black',
    media: {
      mobile: {
        padding: '40px 30px',
        minHeight: '20vh',
        flexDirection: 'column',
      },
      tablet: {
        padding: '60px 50px',
        minHeight: '25vh',
        flexDirection: buttonPosition === 'center' ? 'column' : 'row',
      },
      desktop: {
        padding: '100px 80px',
        minHeight: '30vh',
        flexDirection: buttonPosition === 'center' ? 'column' : 'row',
      },
    },
  };

  const headlineResponsiveProps: TextProps = {
    color: '#808080',
    alignSelf: alignContent,
    textAlign: alignText,
    fontWeight: 700,
    fontStyle: 'normal',
    media: {
      mobile: {
        fontSize: 20,
        lineHeight: 22,
        width: '100%',
      },
      tablet: {
        fontSize: 32,
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: 40,
        width: '100%',
      },
      desktop: {
        fontSize: 36,
        fontWeight: 700,
        lineHeight: 45,
        width: '100%',
      },
    },
    ...designProps?.headerProps,
    ...textStyles,
  };

  const subheaderResponsiveProps: TextProps = {
    color: '#bfbfbf',
    alignSelf: alignContent,
    textAlign: alignText,
    media: {
      mobile: {
        fontSize: 11,
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: 18,
        width: '90%',
      },
      tablet: {
        fontSize: 16,
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: 22,
        width: '80%',
      },
      desktop: {
        fontSize: 18,
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: 23,
      },
    },
    ...designProps?.subHeaderProps,
    ...textStyles,
  };

  const buttonResponsiveProps: any = {
    borderRadius: 15,
    padding: 0,
    display: 'flex',
    wrap: 'nowrap',
    alignItems: 'center',
    color: 'black',
    backgroundColor: 'white',
    gap: 15,
    media: {
      mobile: {
        fontSize: '8px',
        padding: '8px 16px',
        alignSelf: 'flex-start',
        flex: 1,
        marginBottom: buttonPosition === 'left' ? 20 : 0,
      },
      tablet: {
        fontSize: '14px',
        padding: '10px 20px',
        marginTop: buttonPosition === 'center' ? 40 : 0,
        width: buttonPosition === 'center' ? '100%' : 'fit-content',
      },
      desktop: {
        fontSize: '16px',
        padding: '12px 24px',
        marginTop: buttonPosition === 'center' ? 50 : 0,
        width: buttonPosition === 'center' ? '100%' : 'fit-content',
      },
    },
    ...designProps?.buttonProps,
    ...interactionStyles,
  };

  const buttonContainerResponsiveProps = {
    justifyContent: buttonPosition === 'left' ? 'flex-start' : buttonPosition === 'center' ? 'center' : 'flex-end',
    flex: 1,
    width: '100%',
    media: {
      mobile: {
        alignSelf: 'flex-start',
        marginTop: 20,
        width: '100%',
      },
    },
  };

  const responsiveProps = {
    flex: 2,
    media: {
      mobile: {
        gap: 15,
      },
      tablet: {
        gap: 10,
      },
      desktop: {
        gap: 15,
      },
    },
  };

  return (
    <Horizontal role="cta" {...containerResponsiveProps}>
      <Horizontal {...contentResponsiveProps}>
        {button.text && buttonPosition === 'left' && (
          <Center {...buttonContainerResponsiveProps}>
            <Button
              onClick={() => {
                if (mode === 'Interaction') {
                  handlePropsSelection({
                    key: 'button',
                    interactionProps: { button },
                    designProps,
                  });
                }
                if (mode === 'Prompt') {
                  handleNavigation({ path: button.url ?? '#', openInNewTab: button.openInNewTab ?? 'yes' });
                }
              }}
              isAuto
              {...buttonResponsiveProps}
            >
              <Text>{button.text}</Text>
              {button.icon && button.icon.name && button.icon.font && (
                <Center>
                  <Icon
                    name={button.icon.name}
                    font={button.icon.font}
                    color={designProps.buttonProps?.color ?? 'white'}
                    size={24}
                  />
                </Center>
              )}
            </Button>
          </Center>
        )}
        <Vertical {...responsiveProps}>
          <Text
            onClick={() => {
              if (mode === 'Text') {
                handlePropsSelection({
                  key: 'header',
                  textProps: { header, subHeader },
                  designProps: {
                    ...designProps,
                    headerProps: {
                      ...(designProps.headerProps ?? {}),
                    },
                  },
                });
              }
            }}
            {...headlineResponsiveProps}
          >
            {header.text}
          </Text>
          {subHeader?.text && (
            <Text
              onClick={() => {
                if (mode === 'Text') {
                  handlePropsSelection({
                    key: 'subHeader',
                    textProps: { header, subHeader },
                    designProps: {
                      ...designProps,
                      subHeaderProps: {
                        ...(designProps.subHeaderProps ?? {}),
                      },
                    },
                  });
                }
              }}
              {...subheaderResponsiveProps}
            >
              {subHeader.text}
            </Text>
          )}
        </Vertical>
        {button.text && (buttonPosition === 'right' || buttonPosition === 'center') && (
          <Center {...buttonContainerResponsiveProps}>
            <Button
              onClick={() => {
                if (mode === 'Interaction') {
                  handlePropsSelection({
                    key: 'button',
                    interactionProps: { button },
                    designProps,
                  });
                }
                if (mode === 'Prompt') {
                  handleNavigation({ path: button.url ?? '#', openInNewTab: button.openInNewTab ?? 'yes' });
                }
              }}
              isAuto
              {...buttonResponsiveProps}
            >
              <Text>{button.text}</Text>
              {button.icon && button.icon.name && button.icon.font && (
                <Center>
                  <Icon
                    name={button.icon.name}
                    font={button.icon.font}
                    color={designProps.buttonProps?.color ?? 'white'}
                    size={24}
                  />
                </Center>
              )}
            </Button>
          </Center>
        )}
      </Horizontal>
    </Horizontal>
  );
};

export default CtaSection;
