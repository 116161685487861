import { Button, Horizontal, Vertical, View, Switch } from '@app-studio/web';
import React from 'react';
import { C1, C2, C3, H3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';
import { formatDecimal } from 'src/utils/format';
import i18n from 'src/utils/locale';
import { PlanViewProps } from './plan.props';
import Card from 'src/components/paymentCard';
// import { PlanViewProps } from './plan.props';

export const MainUsage = ({ date }: { date: string }) => {
  return (
    <Vertical padding={'30px 30px 60px'} borderRadius={10} border="1px solid #E7E7EF">
      <C1>{i18n.t('page.planAndUsage.mainUsage.title')}</C1>
      <C2 color="#65647D">{i18n.t('page.planAndUsage.mainUsage.date', { date: date })}</C2>
      <C2 color="#65647D">{i18n.t('page.planAndUsage.mainUsage.reset')}</C2>
      <Horizontal marginTop={20}>
        <UsageStats
          title={i18n.t('page.planAndUsage.mainUsage.credits')}
          current={0}
          total={75}
          borderStyle="solid"
          borderWidth="0 1px 0 0"
          borderColor=" #E7E7EF"
          padding={'0 50px 0 0 '}
        />
        <UsageStats
          padding={'0 50px 0'}
          title={i18n.t('page.planAndUsage.mainUsage.projects')}
          current={0}
          total={25}
        />
      </Horizontal>
    </Vertical>
  );
};

export const Plans: React.FC<PlanViewProps> = ({
  plans,
  isAutoCharge,
  handleCharge,
  setSelectedPlan,
  selectedPlan,
}) => {
  const type = isAutoCharge ? 'autoCharge' : 'buy';
  return (
    <Horizontal gap={50} justifyContent="center" height="fit-content">
      {plans[type].map((plan, index) => (
        <PlanItem
          key={index}
          plan={plan}
          isAutoCharge={isAutoCharge}
          selectedPlan={selectedPlan}
          handleCharge={handleCharge}
          setSelectedPlan={setSelectedPlan}
        />
      ))}
    </Horizontal>
  );
};

const UsageStats = ({ title, current, total, ...props }) => {
  return (
    <Vertical {...props}>
      <C3 color="#65647D">{title}</C3>
      <C3>
        {current} / {total}
      </C3>
    </Vertical>
  );
};

const PlanItem = ({ plan, selectedPlan, handleCharge, setSelectedPlan, isAutoCharge }) => {
  const isSelectedPlan = isAutoCharge && selectedPlan === plan.name;
  return (
    <Vertical
      borderRadius={10}
      border="1px solid #E7E7EF"
      padding="30px"
      width={248}
      backgroundColor={plan.backgroundColor}
    >
      <AssetIcon name={plan.icon} width={24} height={24} marginBottom={20} />

      <Horizontal marginBottom={20} alignItems="flex-end">
        <C2 marginRight={5}>{`$${plan.price} `}</C2>
      </Horizontal>
      <C2 marginBottom={20}>{plan.description}</C2>
      <PlanButton
        alignSelf="center"
        marginTop="50px"
        backgroundColor={isSelectedPlan ? '#E7E7EF' : '#000000'}
        color={isSelectedPlan ? '#000000' : '#FFFFFF'}
        padding={0}
        handleClick={async () => {
          await setSelectedPlan(plan.name);
          handleCharge();
        }}
        buttonLabel={isSelectedPlan ? 'Selected' : plan.buttonLabel}
        isFilled
      />
    </Vertical>
  );
};

export const PlanButton = ({ buttonLabel, handleClick, ...props }) => (
  <Button
    borderRadius={20}
    height="40px"
    color={'#FFFFFF'}
    alignItems="center"
    position="relative"
    onClick={handleClick}
    whiteSpace="nowrap"
    {...props}
  >
    <C3 textAlign="center">{buttonLabel}</C3>
    <AssetIcon name="ToparrowSvg" width={24} height={24} position="absolute" zIndex={10000} right={10} />
  </Button>
);

export const Cards = ({ paymentMethods, defaultPaymentMethodId, handleDelete, handleSetDefault, showAddCardModal }) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <C1>Cards</C1>
      <div style={{ display: 'flex', gap: '20px' }}>
        {paymentMethods.map((method) => (
          <Card
            key={method.id}
            method={method}
            defaultPaymentMethodId={defaultPaymentMethodId}
            handleDelete={handleDelete}
            handleSetDefault={handleSetDefault}
          />
        ))}
      </div>
      <PlanButton
        alignSelf="center"
        padding="0px 50px 0 20px"
        marginTop="10px"
        fontSize="14px"
        handleClick={showAddCardModal}
        buttonLabel={'Add Payment Method'}
        isAuto
      />
    </div>
  );
};
