/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBrandParams } from '../models/CreateBrandParams';
import type { UpdateBrandParams } from '../models/UpdateBrandParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create a brand
 * @param requestBody
 * @returns any brand created
 * @throws ApiError
 */
export const brandControllerCreate = (requestBody: CreateBrandParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/brand`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific brand
 * @param id
 * @returns any brand details fetched
 * @throws ApiError
 */
export const brandControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/brand/${id}`,
    errors: {
      404: `brand not found`,
    },
  });
};

/**
 * As an admin, I want to delete a brand
 * @param id
 * @returns any brand deleted
 * @throws ApiError
 */
export const brandControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/brand/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `brand not found`,
    },
  });
};

/**
 * As an admin, I want to update a brand
 * @param id
 * @param requestBody
 * @returns any brand updated
 * @throws ApiError
 */
export const brandControllerUpdate = (id: string, requestBody: UpdateBrandParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/brand/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a brand identity
 * @returns any brand created
 * @throws ApiError
 */
export const brandControllerRegenerateIndentity = (): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/brand/identity`,
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useBrandControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateBrandParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(brandControllerCreate, { method, ...options });
};

export const useBrandControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(brandControllerGet, { method, ...options });
};

export const useBrandControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(brandControllerDelete, { method, ...options });
};

export const useBrandControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateBrandParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(brandControllerUpdate, { method, ...options });
};

export const useBrandControllerRegenerateIndentityService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(brandControllerRegenerateIndentity, { method, ...options });
};
