import { hideModal, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { PageHeader } from 'src/components/Header';
import { useProjectStates } from './product.state';

import { useProductsRequests } from './product.request';
import { useLocation, useParams } from 'react-router-dom';
import { Section } from 'src/components/Section';
import { useEffect } from 'react';

const ProductPage = () => {
  let section = { id: '', content: '', title: '' };

  const { projectId } = useParams();
  const location = useLocation();
  const { isEditing, setIsEditing } = useProjectStates();

  useEffect(() => {
    getProductRequest.run(projectId);
  }, [location.pathname]);

  const handleEditorCallback = () => {
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const productDescripRequests = useProductsRequests({ handleEditorCallback, editorCallback });

  const { getProductRequest, updateSectionRequest, generateContentRequest } = productDescripRequests;

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        request: generateContentRequest,
        description: content,
        sectionTitle: title,
        sectionContent: content,
        isLoading: isLoading,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  return (
    <Vertical width="100%" padding="60px 40px">
      <PageHeader prefixTitle={i18n.t('page.product.header')} paddingBottom={50} />
      {getProductRequest &&
        getProductRequest.data &&
        getProductRequest.data.productSections.map((section) => (
          <Section
            id={section.id}
            key={section.id}
            sectionId={section.id}
            isEditing={isEditing}
            title={section.title}
            editorIconCallback={handleEditorSelection}
            content={section.content}
          />
        ))}
    </Vertical>
  );
};

export default ProductPage;
