// Card.tsx
import React from 'react';
import { Button, Horizontal, Vertical, View } from '@app-studio/web';
import { AssetImage } from 'src/utils/assets';
interface CardProps {
  method: any;
  defaultPaymentMethodId: string | null;
  handleDelete: (paymentMethodId: string) => void;
  handleSetDefault: (paymentMethodId: string) => void;
}

const Card: React.FC<CardProps> = ({ method, defaultPaymentMethodId, handleDelete, handleSetDefault }) => {
  const isDefault = method.id === defaultPaymentMethodId;
  const name = method.card.brand + 'png';
  return (
    <View
      padding={20}
      borderRadius={10}
      width={277}
      style={{
        border: isDefault ? '2px solid black' : '1px solid #ECECF1',
        backgroundColor: isDefault ? '#f0f0f0' : '#fff',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AssetImage name={method.card.brand === 'mastercard' ? 'mastercardpng' : 'visapng'} width={40} />
        <div style={{ marginLeft: '20px' }}>
          <p style={{ color: '#353740' }}>**** {method.card.last4}</p>
          <p style={{ color: '#6E6E80', fontSize: '12px' }}>
            Expires {method.card.exp_month}/{method.card.exp_year}
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        {isDefault ? (
          <a style={{ color: 'black', cursor: 'default' }}>Default</a>
        ) : (
          <a
            onClick={() => handleSetDefault(method.id)}
            style={{ color: '#10A37F', border: 'none', background: 'none', cursor: 'pointer' }}
          >
            Set as default
          </a>
        )}
        <a
          onClick={() => handleDelete(method.id)}
          style={{ marginLeft: '10px', color: 'red', border: 'none', background: 'none', cursor: 'pointer' }}
        >
          Delete
        </a>
      </div>
    </View>
  );
};

export default Card;
