import React from 'react';
import { Vertical, Horizontal, Center } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import { C3 } from 'src/components/Text';
import { Section } from '../../editor.elements';
import { Config } from '../../editor.props';
import { RequestTask } from 'src/services/api';

interface LeftMenuProps {
  showSections: boolean;
  setShowSections: (show: boolean) => void;
  landingName: string;
  sections: string[];
  tasks: { id: string; status: string; nbOfExecution: number; inputs: any }[];
  components: { [key: string]: any };
  selectedSection: string | null;
  onMoveSection: (fromIndex: number, direction: number) => void;
  onHideSection: (section: Config) => void;
  onDeleteSection: () => void;
  onSectionClick: (index: number) => void;
  onRegenerateSection: (taskId: string) => void;
  onAddSection: () => void;
}

const LeftMenu: React.FC<LeftMenuProps> = ({
  showSections,
  setShowSections,
  landingName,
  sections = [],
  tasks = {},
  components = {},
  selectedSection,
  onMoveSection,
  onHideSection,
  onDeleteSection,
  onSectionClick,
  onRegenerateSection,
  onAddSection,
}) => {
  return (
    <Vertical gap={40}>
      <Horizontal gap={15} alignItems="center" padding="20px 0 20px 20px">
        <AssetIcon
          width={24}
          height={24}
          name="SectionMenuSvg"
          cursor="pointer"
          onClick={() => setShowSections(!showSections)}
        />
        <C3>{landingName}</C3>
      </Horizontal>
      <Vertical gap={30} minWidth={200}>
        {sections.map((id: string, index: number) => {
          const task = tasks[id];
          const component = components[id];
          // console.log('task:', task);
          // console.log('component:', component);
          if (!task) {
            return null;
          }
          return (
            <Section
              key={task.id}
              id={task.id}
              name={component?.name || task?.inputs?.section || ''}
              selectedSection={selectedSection}
              isVisible={component?.isVisible || true}
              isLoading={task && ['IN_PROGRESS', 'PENDING'].includes(task.status)}
              isError={!!(task && task.status === 'ERROR' && task.nbOfExecution && task.nbOfExecution < 2)}
              isDenied={!!(task && task.status === 'ERROR' && task.nbOfExecution && task.nbOfExecution >= 2)}
              onMoveUp={() => onMoveSection(index, 1)}
              onMoveDown={() => onMoveSection(index, -1)}
              onHide={() => onHideSection(task.id)}
              onDelete={onDeleteSection}
              onClick={() => onSectionClick(index)}
              onRegenerate={() => {
                onRegenerateSection(task.id);
              }}
            />
          );
        })}
        <Center
          backgroundColor="black"
          height={41}
          width={65}
          borderRadius={10}
          margin="0px 30px 0px 20px"
          onClick={onAddSection}
        >
          <AssetIcon name="PlusSvg" width={24} height={24} />
        </Center>
      </Vertical>
    </Vertical>
  );
};

export default LeftMenu;
