import { FormikForm, Vertical, FormikTextArea, Horizontal, View, FormikSelect, FormikTextField } from '@app-studio/web';
import { Formik } from 'formik';
import { NextButton, PrevButton } from 'src/components/Button';
import { Label } from 'src/components/Text';

export interface ConfigFormProps {
  keyName?: string;
  initialValues: any;
  onNext: (values: any) => void;
  onPrev?: () => void;
  textAreaStyle?: any;
  placeholder?: string;
  nextButtonName?: string;
  dropdown?: {
    keyName: string;
    label?: string;
    options: { label: string; value: string }[];
  };
  textField?: {
    keyName: string;
    label?: string;
    placeholder: string;
  };
  position?: 'row' | 'column';
}

export const ConfigForm: React.FC<ConfigFormProps> = ({
  keyName,
  initialValues,
  onNext,
  onPrev,
  textAreaStyle,
  placeholder,
  nextButtonName,
  dropdown,
  textField,
  position = 'row',
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onNext}>
      {(formikProps) => (
        <FormikForm>
          <Vertical gap={30}>
            {textField && textField.label && (
              <View
                display="flex"
                flexDirection={position}
                gap={20}
                width="fit-content"
                marginLeft="auto"
                flexWrap={'nowrap'}
                alignItems="center"
              >
                {textField.label && <Label>{textField.label}</Label>}
                <FormikTextField
                  key={textField.keyName}
                  name={textField.keyName}
                  placeholder={textField.placeholder}
                  styles={{
                    box: {
                      borderColor: 'black',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      backgroundColor: 'white',
                      borderRadius: 10,
                      height: 52,
                      width: '400px',
                    },
                    text: { color: 'black' },
                  }}
                />
              </View>
            )}
            {keyName && (
              <FormikTextArea
                key={keyName}
                name={keyName}
                placeholder={placeholder ?? ''}
                height="60vh"
                styles={{
                  box: {
                    borderColor: 'black',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 10,
                  },
                  field: {
                    ...textAreaStyle,
                  },
                  text: { color: 'black' },
                }}
              />
            )}
            {dropdown && dropdown.keyName && (
              <View
                display="flex"
                flexDirection={position}
                gap={20}
                width="fit-content"
                marginLeft="auto"
                flexWrap={'nowrap'}
                alignItems="center"
              >
                {dropdown.label && <Label>{dropdown.label}</Label>}
                <FormikSelect
                  key={dropdown.keyName}
                  name={dropdown.keyName}
                  options={dropdown.options}
                  styles={{
                    box: {
                      borderColor: 'black',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      backgroundColor: 'white',
                      borderRadius: 10,
                      height: 52,
                      width: '400px',
                    },
                    dropDown: { zIndex: 1000000 }, // Ensuring dropdown appears above other elements
                    text: { color: 'black', fontSize: 18, fontWeight: 500, lineHeight: 20 },
                  }}
                />
              </View>
            )}
            <Horizontal gap={20} alignSelf={!!onPrev ? 'center' : 'end'}>
              {!!onPrev && <PrevButton onPrev={onPrev} />}
              <NextButton
                isLoading={formikProps.isSubmitting}
                nextButtonName={nextButtonName}
                handleSubmit={formikProps.handleSubmit}
              />
            </Horizontal>
          </Vertical>
        </FormikForm>
      )}
    </Formik>
  );
};
