import React, { useRef } from 'react';
import { Center, Loader, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from 'src/utils/locale';
import { GrantHeader } from '../grant/grant.element';
import { DocumentsTable, VideoTranslation } from './grantUploader.element';
import { useGrantFileRequests } from './grantUploader.request';
import { useGrantUploaderState } from './grantUploader.state';

export const GrantUploaderPage = () => {
  const { projectId, id } = useParams();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const grantRequests = useGrantUploaderState();

  const { isDragging, setIsDragging, droppedFile, setDroppedFile } = grantRequests;

  const handleUploadCallBackFile = () => {
    setDroppedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const { getGrantRequest, uploadFileRequest, deleteFileRequest } = useGrantFileRequests({ handleUploadCallBackFile });

  const headers = [
    i18n.t('page.grant.url'),
    i18n.t('page.grant.headerStatus'),
    i18n.t('page.grant.created'),
    i18n.t('page.grant.edited'),
    ' ',
  ];

  useMount(() => {
    getGrantRequest.run(id);
  });

  const isLoaded = !getGrantRequest.loader && getGrantRequest.data;

  const handleDeleteCallback = (fileId: string) => {
    deleteFileRequest.run(fileId);
  };

  const handleUpload = () => {
    if (droppedFile) {
      uploadFileRequest.run(id, { file: droppedFile });
    }
  };

  return (
    <Vertical width="100%" gap={50} height="100%" padding="60px 40px">
      <GrantHeader title={getGrantRequest.data?.name ?? ''} />
      <VideoTranslation
        fileInputRef={fileInputRef}
        uploadText={droppedFile?.name ?? i18n.t('page.translate.dragDrop')}
        uploading={uploadFileRequest.loader}
        handleUpload={handleUpload}
        isDragging={isDragging}
        setDroppedFile={setDroppedFile}
        setIsDragging={setIsDragging}
      />
      {isLoaded ? (
        <DocumentsTable
          isLoading={!!deleteFileRequest.loader}
          headers={headers}
          files={getGrantRequest.data?.grantFiles ?? []}
          buttonText={i18n.t('button.remove')}
          navigateTo={(fileId: string) => navigate(`/${projectId}/fund/grant/${id}/file/${fileId}`)}
          handleDeleteCallback={handleDeleteCallback}
        />
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};
