import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getEditorBlocks, getMarkdown } from 'src/utils/markdown/Markdown';
function reformatMarkdown(markdown) {
  // Regular expression to match any text between ** ** followed by a colon
  const specificBoldTextRegex = /\*\*(.+?)\*\*:/g;

  // Replace the matched patterns with markdown h6, ensuring new lines before each heading
  const converted = markdown.replace(specificBoldTextRegex, (match, text) => {
    return `###### ${text}:`;
  });

  return converted;
}

export const Editor = ({ content, onChangeCallback }) => {
  content = reformatMarkdown(content);

  console.error('1', { content });
  const editorRef: any = useRef();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const initializeEditor = useCallback(async () => {
    const EditorJS = (await import('@editorjs/editorjs')).default;
    const Header = (await import('@editorjs/header')).default;
    const List = (await import('@editorjs/list')).default;
    const Embed = (await import('@editorjs/embed')).default;
    const InlineCode = (await import('@editorjs/inline-code')).default;
    const Code = (await import('@editorjs/code')).default;
    const LinkTool = (await import('@editorjs/link')).default;
    const Table = (await import('@editorjs/table')).default;
    const SimpleImage = (await import('@editorjs/simple-image')).default;
    const Checklist = (await import('@editorjs/checklist')).default;
    if (!editorRef.current) {
      const editor = new EditorJS({
        holder: 'editorjs',
        onReady: () => {
          editorRef.current = editor;
        },
        placeholder: 'Start writing your block here...',
        inlineToolbar: true,
        data: {
          time: new Date().getTime(),
          blocks: await getEditorBlocks(content),
        },
        onChange: async () => {
          let editedContent = await editor.saver.save();
          const markdown = getMarkdown({ data: editedContent });
          onChangeCallback(markdown);
        },
        tools: {
          header: Header,
          list: List,
          embed: Embed as any,
          inlineCode: InlineCode,
          code: Code,
          table: Table,
          image: SimpleImage,
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          link: {
            class: LinkTool,
            config: {
              endpoint: '/api/link',
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    const loadEditor = async () => {
      await initializeEditor();
    };
    if (isMounted) {
      loadEditor();
      return () => {
        editorRef.current?.destroy();
        editorRef.current = undefined;
      };
    }
  }, [isMounted, initializeEditor]);

  return <div id="editorjs"></div>;
};
