import React, { useEffect, useState } from 'react';
import { useMount } from 'app-studio';
import { Horizontal, showModal, View } from '@app-studio/web';
import { useNavigate, useParams } from 'react-router-dom';

import { useChatStates } from './editor.state';

import { useAppStore } from 'src/stores/AppStore';

import useChatRequest from './editor.request';
import { generateLandingCode } from './editor.function';

import { Config } from './editor.props';
import EditorPanel from './editor.panel';
import LeftMenu from './components/LeftMenu/LeftMenu.component';
import { handleInspector } from './editor.inspector';

const EditorPage: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const { projectId, id } = useParams();
  const { device, setDevice } = useAppStore();
  const navigate = useNavigate();

  const {
    isInspectorEnabled,
    setIsInspectorEnabled,
    selectedElement,
    setSelectedElement,
    showSections,
    setShowSections,
    sections,
    setSections,
    selectedSection,
    setSelectedSection,
    selectedMode,
    setSelectedMode,
    selectedProps,
    setSelectedProps,
    dirty,
    setDirty,
    landingCode,
    setLandingCode,
  } = useChatStates();

  const {
    fixRevisionRequest,
    getLandingRequest,
    getWorkflowRequest,
    generateContentRequest,
    createLandingHistory,
    updateLandingRequest,
    deleteComponentRequest,
    createComponentTaskRequest,
    resetTaskRequest,
    updateComponentRequest,
    refreshImageRequest,
    updatePropsRequest,
  } = useChatRequest({
    refreshCallback: () => {
      getLandingRequest.run(id);
    },
    closeEditorCallback: () => {
      if (selectedMode.text !== 'Prompt') {
        setSelectedProps(null);
      }
    },
    getLandingCallback: (data) => {
      if (data?.workflowId) {
        getWorkflowRequest.run(data.workflowId);
      }
    },
    getWorkflowCallback: (data) => {
      const tasksInProgress = data.tasks.filter((task) => ['IN_PROGRESS', 'PENDING'].includes(task.status));
      if (['IN_PROGRESS', 'PENDING'].includes(data.status) || tasksInProgress.length > 0) {
        setTimeout(() => getLandingRequest.run(id), 3000);
      }
      setDirty(true);
    },
    generateContentCallback: (data) => {
      createLandingHistory.run({ prompt, landingId: id, code: data });
    },
  });

  const tasks =
    getWorkflowRequest?.data?.tasks.reduce((acc, task) => {
      acc[task.id] = task;
      return acc;
    }, {} as { [key: string]: any }) ?? {};

  const components =
    getLandingRequest?.data?.components.reduce((acc, component) => {
      acc[component.id] = component;
      return acc;
    }, {} as { [key: string]: any }) ?? {};

  useMount(() => {
    getLandingRequest.run(id);
    global.handleSectionDelete = handleSectionDelete;
    global.handleTaskRetry = handleTaskRetry;
    global.handlePropsSelection = handlePropsSelection;
    global.handlePropsSubmit = handlePropsSubmit;
  });

  useEffect(() => {
    handleInspector(isInspectorEnabled, setSelectedElement);
  }, [isInspectorEnabled]);

  useEffect(() => {
    setSelectedProps(null);
  }, [selectedMode]);

  useEffect(() => {
    setDirty(true);
    if (!selectedSection && sections?.length > 0) {
      setSelectedSection(sections[0]);
    }
  }, [selectedMode]);

  useEffect(() => {
    const fetchLandingCode = async () => {
      if (!sections) {
        setLandingCode('');
        return;
      }

      if (dirty && sections.length > 0 && Object.keys(components).length > 0) {
        const code = await generateLandingCode(
          sections,
          components,
          tasks,
          sections.indexOf(selectedSection),
          selectedMode
        );
        setLandingCode(code);
      }
    };

    fetchLandingCode();
  }, [sections, components, tasks, selectedMode]);

  useEffect(() => {
    updateLandingRequest.run(id, { code: landingCode });
    setDirty(false);
  }, [landingCode]);

  const handlePromptSubmit = (input) => {
    setPrompt(input.input);

    if (selectedMode.text === 'Prompt') {
      if (getLandingRequest && getLandingRequest.data?.code) {
        generateContentRequest.run({ prompt: input.input, content: getLandingRequest.data.code });
        setDirty(true);
      }
    }
    if (selectedMode.text === 'Text') {
      if (
        selectedProps &&
        Object.keys(selectedProps).length > 0 &&
        selectedProps.initialValues &&
        selectedProps.key &&
        selectedProps.id
      ) {
        const content = { [selectedProps.key]: selectedProps.initialValues };
        updatePropsRequest.run(selectedProps.id, { prompt: input.input, json: content });
        setDirty(true);
      }
    }
  };

  useEffect(() => {
    setSections(getLandingRequest?.data?.configs ?? []);
  }, [getLandingRequest?.data?.configs]);

  const handlePropsSelection = (props) => {
    setSelectedProps(props);
  };

  const handlePropsSubmit = (props) => {
    const { key, id, ...finalSelectedProps } = props;
    console.log({ selectedSection, finalSelectedProps });
    updateComponentRequest.run(selectedSection, finalSelectedProps);
  };

  const handleTaskRetry = (taskId: string) => {
    setDirty(true);
    resetTaskRequest.run({ taskId });
  };

  const handleSectionDelete = (index) => {
    setDirty(true);
    deleteComponentRequest.run({ id: sections[index] });
  };

  const moveSection = (fromIndex, direction) => {
    console.log('moveSection:', fromIndex, direction);
    const sectionCount = sections?.length ?? 0;
    const toIndex = fromIndex + direction;

    if (fromIndex < 0 || fromIndex >= sectionCount || toIndex < 0 || toIndex >= sectionCount) {
      console.log('Invalid move. Returning without action.');
      return;
    }

    const updatedSections = [...sections];
    const [movedSection] = updatedSections.splice(fromIndex, 1);
    updatedSections.splice(toIndex, 0, movedSection);
    setDirty(true);
    setSections(updatedSections);
  };

  const handleDelete = () => {
    setDirty(true);
    deleteComponentRequest.run(sections[selectedSection]);
  };

  const handleRegenerate = (taskId: string) => {
    setDirty(true);
    resetTaskRequest.run({ taskId, resetNbOfExecution: true });
  };

  const handleHide = (section: Config) => {
    if (sections?.length > 0) {
      const updatedSections = sections.map((item) =>
        item.taskId === section.id ? { ...item, visible: !item.visible } : item
      );
      setDirty(true);
      setSections(updatedSections);
    }
  };

  const handleSectionClick = (index: number) => {
    global.scrollToSection(index);
    setSelectedSection(sections[index]);
  };

  const handleOpenModal = () => {
    if (getLandingRequest.data.defaultConfigs) {
      const sectionKeys = new Set(sections.map((section) => section.key));
      const filteredComponentList = getLandingRequest.data.defaultConfigs.map((config) => {
        const correspondingSection = sections.find((section) => section.key === config.key);
        return {
          ...config,
          isAvailable: !sectionKeys.has(config.key),
          taskId: correspondingSection ? correspondingSection.taskId : null,
          imageUrl: `${config.key.toLowerCase()}png`,
        };
      });

      showModal('AddComponentModal', {
        componentsList: filteredComponentList,
        handleAddComponent: (taskName) => {
          setDirty(true);
          createComponentTaskRequest.run({ taskName, landingId: id });
        },
      });
    }
  };

  const regenerateImage = () => {
    setDirty(true);
    refreshImageRequest.run(id, selectedSection);
  };

  return (
    device &&
    getLandingRequest &&
    getLandingRequest.data && (
      <Horizontal width="100%" height="100%" wrap="nowrap" padding="30px 15px 30px 0">
        {showSections && (
          <LeftMenu
            showSections={showSections}
            setShowSections={setShowSections}
            landingName={getLandingRequest.data.name}
            sections={sections}
            tasks={tasks}
            components={components}
            selectedSection={selectedSection}
            onMoveSection={moveSection}
            onHideSection={handleHide}
            onDeleteSection={handleDelete}
            onSectionClick={handleSectionClick}
            onRegenerateSection={handleRegenerate}
            onAddSection={handleOpenModal}
          />
        )}
        <EditorPanel
          isEditorMenuLoading={!!refreshImageRequest.loader || !!updatePropsRequest.loader}
          selectedProps={selectedProps}
          showSections={showSections}
          setShowSections={setShowSections}
          selectedMode={selectedMode}
          setSelectedMode={setSelectedMode}
          device={device}
          setDevice={setDevice}
          landingName={getLandingRequest.data.name}
          landingCode={landingCode}
          isGeneratingContent={
            !!generateContentRequest.loader ||
            !!fixRevisionRequest.loader ||
            !!updatePropsRequest.loader ||
            !!updateComponentRequest.loader
          }
          isInspectorEnabled={isInspectorEnabled}
          setIsInspectorEnabled={setIsInspectorEnabled}
          onPromptSubmit={handlePromptSubmit}
          handleCancel={() => {
            setSelectedProps(null);
          }}
          handleSubmit={(props) => {}}
          regenerateImage={regenerateImage}
          onNavigateBack={() => navigate(`/${projectId}/dev/landing`)}
        />
      </Horizontal>
    )
  );
};

export default EditorPage;
