import { Vertical, View } from '@app-studio/web';
import { C3 } from './Text';

export const ProgressBar = ({
  usageNumber,
  totalUsage,
  showPercentage,
}: {
  usageNumber: number;
  totalUsage: number;
  showPercentage?: boolean;
}) => {
  const progressBarContainer = {
    width: '100%',
    height: 3,
    backgroundColor: '#E7E7EF',
    borderRadius: 3,
    overflow: 'hidden',
  };

  const progressStyle = {
    height: '100%',
    width: `${(usageNumber / totalUsage) * 100}%`,
    backgroundColor: 'black',
    borderRadius: 3,
  };

  return (
    <Vertical width={'100%'} gap={20}>
      {showPercentage && <C3 alignSelf="center">{`${((usageNumber / totalUsage) * 100).toFixed(0)}% Completed`}</C3>}
      <View {...progressBarContainer}>
        <View {...progressStyle} />
      </View>
    </Vertical>
  );
};
