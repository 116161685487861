import React, { useState, useEffect } from 'react';
import { Modal, hideModal } from '@app-studio/web';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { PUBLIC_STRIPE_KEY } from 'src/configs/AppConfig';
import { Button } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import {
  useAccountControllerCreateSetupIntentService,
  useAccountControllerGetPaymentMethodsService,
} from 'src/services/api/services/AccountService'; // Adjust the import path

const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

const AddCardModal = ({ onClose }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement | null>(null);
  const [clientSecret, setClientSecret] = useState('');

  const { run: runCreateSetupIntent } = useAccountControllerCreateSetupIntentService({
    onSuccess: (res) => {
      setClientSecret(res.clientSecret);
      console.log(res);
    },
  });

  const { run: runGetPaymentMethods } = useAccountControllerGetPaymentMethodsService({
    onSuccess: (res) => {
      onClose();
    },
  });

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      if (!stripeInstance) {
        console.error('Stripe failed to initialize');
        return;
      }
      setStripe(stripeInstance);
      const elements = stripeInstance.elements();
      const card = elements.create('card', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      });
      card.mount('#card-element');
      setCardElement(card);
    };

    initializeStripe();
  }, []);

  const handleCardSubmit = async () => {
    await runCreateSetupIntent();
    // setTimeout(() => {}, 1000);
    if (stripe && cardElement) {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        console.error('Error confirming card setup:', result.error);
      } else {
        await runGetPaymentMethods(); // Fetch the updated list of payment methods
      }
      hideModal();
    }
  };

  return (
    <Modal.Container padding={20} boxShadow="none" border="1px solid black">
      <Modal.Header buttonPosition="none" padding={'20px 0'} position="relative">
        <h2>Add New Card</h2>
        <AssetIcon
          name="BlackCloseSvg"
          width={16}
          height={16}
          position="absolute"
          top={5}
          right={5}
          onClick={hideModal}
          color="black"
        />
      </Modal.Header>
      <Modal.Body border="none">
        <div id="card-element" style={{ padding: '10px', border: '1px solid #ccd0d2', borderRadius: '4px' }}>
          {/* Stripe.js will inject the Card Element here */}
        </div>
        <Button onClick={handleCardSubmit} type="submit" style={{ marginTop: '20px' }}>
          <p>Add New Card</p>
        </Button>
      </Modal.Body>
    </Modal.Container>
  );
};

export default AddCardModal;
