import React from 'react';
//import i18n from 'src/utils/locale';
import { Horizontal, View, Center, Text, Link, FileSVG, showModal, hideModal } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { C2, C3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';
import { useTopLayoutState } from './TopLayout.state';
import { useTopLayoutRequests } from './TopLayout.request';
import i18n from 'src/utils/locale';
import { useNavigate } from 'react-router-dom';
import { ModelSelector, TopLayoutContainer, Usage } from './TopLayout.element';

export const TopLayout = (props) => {
  const navigate = useNavigate();

  const modelProviders = [
    { provider: 'groq', model: 'llama3-70b-8192' },
    { provider: 'gemini', model: 'gemini-1.5-pro' },
    { provider: 'gemini', model: 'gemini-1.5-flash' },
    { provider: 'openai', model: 'gpt-4-1106-preview' },
    { provider: 'openai', model: 'gpt-4o-mini' },
  ];

  const joinedModelProviders = modelProviders.map(({ model, provider }) => ({
    value: model,
    label: `${model}-${provider}`,
  }));

  const { isAuthentificated, user } = useAuthStore(({ isAuthentificated, user }) => ({
    isAuthentificated,
    user,
  }));

  const defaultModelProvider = user ? `${user.model}-${user.provider}` : '';

  const { setModelSelected, modelSelected } = useTopLayoutState(defaultModelProvider);

  const retrieveModel = (formattedModel) => {
    const parts = formattedModel.split('-');
    parts.pop();
    return parts.join('-');
  };

  const handleModelClick = () => {
    showModal('ModelModal', {
      key: new Date().getTime(),
      initialValues: { model: modelSelected },
      field: { name: 'model', options: joinedModelProviders },
      handleSubmit: handleModelSubmit,
      handleCancel: hideModal,
    });
  };

  const handleUsageClick = () => {
    navigate('/plan');
  };

  const handleUpdateCallback = (model) => {
    setModelSelected(model);
    hideModal();
  };

  const { updateUserRequests } = useTopLayoutRequests({ handleUpdateCallback });
  const handleModelSubmit = (event) => {
    const userModel = event.model;
    const modelProvider = modelProviders.find(({ model }) => model === userModel);
    if (user) updateUserRequests.run(user.id, { model: userModel, provider: modelProvider?.provider ?? 'openai' });
  };

  // const toShow = ['/'];

  // if (!toShow.includes(location.pathname)) return null;

  return (
    <TopLayoutContainer {...props}>
      <Center flex={1}>
        <Usage
          plan={i18n.t('page.planAndUsage.sideMenuUsage.title')}
          usageNumber={0}
          totalUsage={3}
          handleClick={handleUsageClick}
        />
      </Center>
      <ModelSelector
        modelText={i18n.t('component.sideMenu.text', { modelName: modelSelected })}
        handleModelClick={handleModelClick}
      />
    </TopLayoutContainer>
  );
};
