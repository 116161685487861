import { LandingService, OfferService } from 'src/services/api';

const useLandingRequest = ({ createLandingCallback }) => {
  const getAllLandingRequest = LandingService.useLandingControllerReadAllService({});

  const createLandingRequest = LandingService.useLandingControllerGenerateService({
    onSuccess: (data) => {
      createLandingCallback(data);
    },
  });

  const getAllOfferRequest = OfferService.useOfferControllerReadAllService({});

  return {
    getAllLandingRequest,
    createLandingRequest,
    getAllOfferRequest,
  };
};
export default useLandingRequest;
