import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FormikSelect, Vertical } from '@app-studio/web';
import { FormButtons } from 'src/components/Button';

interface CustomFormProps {
  initialValues: any;
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  field: {
    name: string;
    label?: string;
    options: string[];
  };
}

export const ModelForm: React.FC<CustomFormProps> = ({ initialValues, handleSubmit, handleCancel, field }) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical alignItems="center">
          <Vertical gap={20} width="100%">
            <FormikSelect
              key={field.name}
              name={field.name}
              options={field.options}
              styles={{
                box: {
                  borderColor: 'black',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  backgroundColor: 'white',
                  height: 52,
                  width: '100%',
                },
                dropDown: { zIndex: 1000000 }, // Ensuring dropdown appears above other elements
                text: { color: 'black', fontSize: 18, fontWeight: 500 },
              }}
            />
            <FormButtons handleCancel={handleCancel} handleButtonSave={formikProps.handleSubmit} type="submit" />
          </Vertical>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
