import React from 'react';
import { ImageProps, TextProps, Text, ViewProps } from 'app-studio';
import { Vertical, Horizontal, View } from '@app-studio/web';
import Icon from 'react-web-vector-icons';

import { FeaturesProps } from './Features.interface';
const FeaturesSection: React.FC<FeaturesProps> = ({
  handlePropsSelection,
  textProps,
  imageProps,
  designProps = {},
  mode,
}) => {
  // Set default for contentPosition here
  const { iconProps, contentPosition = 'center' } = designProps;
  const { header, subHeader, feature1, feature2, feature3, feature4 } = textProps;

  // Create an array of features, filtering out undefined ones
  const featureArray = [feature1, feature2, feature3, feature4].filter(Boolean);

  // Extract common styling and conditions to reduce repetition
  const isTextMode = mode === 'Text' || mode === 'Prompt';
  const isImageMode = mode === 'Image' || mode === 'Prompt';

  const textStyles = {
    cursor: isTextMode ? 'pointer' : 'not-allowed',
    opacity: isTextMode ? 1 : 0.1,
    border: mode === 'Text' ? '2px dotted gray' : 'none',
  };

  const imageStyles = {
    cursor: isImageMode ? 'pointer' : 'not-allowed',
    opacity: isImageMode ? 1 : 0.1,
    border: mode === 'Image' ? '3px dotted gray' : 'none',
  };

  const position = contentPosition === 'center' ? 'center' : contentPosition === 'left' ? 'flex-start' : 'flex-end';
  const alignItems = 'center';

  const containerResponsiveProps: any = {
    wrap: 'nowrap',
    width: '100%',
    justifyContent: position,
    alignItems: alignItems,
    overflow: 'hidden',
    height: '100vh',
    media: {
      mobile: {
        padding: '0px 40px',
        flexDirection: 'column',
        gap: 30,
        height: '100%',
      },
      tablet: {
        padding: '40px 50px',
        gap: 40,
      },
      desktop: {
        padding: '100px 100px',
        gap: 130,
      },
    },
    ...(designProps?.containerProps ?? {}),
  };

  const headerResponsiveProps: TextProps = {
    fontWeight: 'bold',
    fontStyle: 'normal',
    media: {
      mobile: {
        fontSize: 32,
        lineHeight: 30,
      },
      tablet: {
        fontSize: 36,
      },
      desktop: {
        fontSize: 47,
      },
    },
    ...(designProps?.headerProps ?? {}),
    ...textStyles,
  };

  const subheaderResponsiveProps: TextProps = {
    fontStyle: 'normal',
    media: {
      mobile: {
        fontSize: 14,
        lineHeight: 22,
      },
      tablet: {
        fontSize: 16,
        lineHeight: 22,
      },
      desktop: {
        fontSize: 18,
        lineHeight: 23,
      },
    },
    ...(designProps?.subHeaderProps ?? {}),
    ...textStyles,
  };
  const shape = designProps?.imageProps?.shape ?? 'rounded';
  const imageResponsiveProps: ImageProps = {
    borderRadius: shape === 'circle' ? '50%' : shape === 'rounded' ? 15 : 0,
    backgroundImage: `url(${imageProps?.href ?? ''})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: shape === 'circle' ? '45%' : '100%',
    height: shape === 'circle' ? '70%' : '70%',
    margin: 'auto',
    media: {
      mobile: {
        display: 'none',
      },
      tablet: {
        height: '200px',
        width: '200px',
        maxWidth: '400px',
        maxHeight: '400px',
      },
      desktop: {
        height: '300px',
        width: '300px',
        maxWidth: '500px',
        maxHeight: '500px',
      },
    },
    ...imageStyles,
  };

  const contentContainerResponsiveProps: any = {
    gap: 10,
    alignItems: position,
    justifyContent: alignItems,
  };

  const featuresContainerResponsiveProps: ViewProps = {
    gap: 5,
    alignItems: alignItems,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: imageProps?.href ? '100%' : '50%',
  };

  const featureContainerResponsiveProps: ViewProps = {
    flex: '300px',
    padding: '10px' /* Optional padding */,
    margin: '30px 0 0 ',
    gap: 5,
  };

  const featureHeaderResponsiveProps: TextProps = {
    fontWeight: 'bold',
    media: {
      mobile: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: 20,
      },
      tablet: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: 22,
      },
      desktop: {
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 'bold',
      },
    },
    ...(designProps?.featureProps?.text ?? {}),
    ...textStyles,
  };

  const featureSubheaderResponsiveProps: TextProps = {
    fontWeight: 'normal',
    media: {
      mobile: {
        fontSize: 8.84,
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: 14.5,
      },
      tablet: {
        fontSize: 11,
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: 18,
      },
      desktop: {
        fontSize: 14,
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: 22,
      },
    },
    ...(designProps?.featureProps?.subText ?? {}),
    ...textStyles,
  };

  return (
    <Horizontal {...containerResponsiveProps}>
      {imageProps?.href && contentPosition === 'right' && (
        <View
          flex={1}
          {...imageResponsiveProps}
          onClick={() => {
            if (mode === 'Image') {
              handlePropsSelection({
                key: 'image',
                imageProps,
                designProps: {
                  ...designProps,
                  imageProps: {
                    ...(designProps?.imageProps ?? {}),
                    shape: designProps?.imageProps?.shape ?? 'rounded',
                  },
                },
              });
            }
          }}
        />
      )}
      <Vertical flex={1} {...contentContainerResponsiveProps}>
        {header?.text && (
          <Text
            {...headerResponsiveProps}
            onClick={() => {
              if (mode === 'Text') {
                handlePropsSelection({
                  key: 'header',
                  textProps: { header, subHeader, feature1, feature2, feature3, feature4 },
                  designProps: {
                    ...designProps,
                    headerProps: {
                      ...(designProps?.headerProps ?? {}),
                    },
                  },
                });
              }
            }}
          >
            {header.text}
          </Text>
        )}
        {subHeader?.text && (
          <Text
            {...subheaderResponsiveProps}
            onClick={() => {
              if (mode === 'Text') {
                handlePropsSelection({
                  key: 'subHeader',
                  textProps: { header, subHeader, feature1, feature2, feature3, feature4 },
                  designProps: {
                    ...designProps,
                    subHeaderProps: {
                      ...(designProps?.subHeaderProps ?? {}),
                    },
                  },
                });
              }
            }}
          >
            {subHeader.text}
          </Text>
        )}
        <Horizontal {...featuresContainerResponsiveProps}>
          {featureArray?.length > 0 &&
            featureArray.map(
              (feature, index) =>
                feature?.text && (
                  <Vertical {...featureContainerResponsiveProps} key={index}>
                    {feature?.icon?.name && feature?.icon?.font && (
                      <Icon
                        name={feature.icon.name}
                        font={feature.icon.font}
                        color={iconProps?.color ? iconProps.color : 'white'}
                        size={Math.min(iconProps?.size ?? 24, 24)}
                      />
                    )}
                    {feature.text && (
                      <Text
                        {...featureHeaderResponsiveProps}
                        onClick={() => {
                          if (mode === 'Text') {
                            handlePropsSelection({
                              key: `feature${index + 1}`,
                              secondaryKey: 'text',
                              textProps: { header, subHeader, feature1, feature2, feature3, feature4 },
                              designProps: {
                                ...designProps,
                                subHeaderProps: {
                                  ...(designProps?.subHeaderProps ?? {}),
                                },
                              },
                            });
                          }
                        }}
                      >
                        {feature.text}
                      </Text>
                    )}
                    {feature?.subText && (
                      <Text
                        {...featureSubheaderResponsiveProps}
                        onClick={() => {
                          if (mode === 'Text') {
                            handlePropsSelection({
                              key: `feature${index + 1}`,
                              secondaryKey: 'subText',
                              textProps: { header, subHeader, feature1, feature2, feature3, feature4 },
                              designProps: {
                                ...designProps,
                                subHeaderProps: {
                                  ...(designProps?.subHeaderProps ?? {}),
                                },
                              },
                            });
                          }
                        }}
                      >
                        {feature.subText}
                      </Text>
                    )}
                  </Vertical>
                )
            )}
        </Horizontal>
      </Vertical>
      {imageProps?.href && contentPosition === 'left' && (
        <View
          flex={1}
          {...imageResponsiveProps}
          onClick={() => {
            if (mode === 'Image') {
              handlePropsSelection({
                key: 'image',
                imageProps,
                designProps: {
                  ...designProps,
                  imageProps: {
                    ...(designProps?.imageProps ?? {}),
                    shape: designProps?.imageProps?.shape ?? 'rounded',
                  },
                },
              });
            }
          }}
        />
      )}
    </Horizontal>
  );
};

export default FeaturesSection;
