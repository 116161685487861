/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerParams } from '../models/CreateCustomerParams';
import type { CreateCustomerSectionParams } from '../models/CreateCustomerSectionParams';
import type { UpdateCustomerParams } from '../models/UpdateCustomerParams';
import type { UpdateCustomerSectionParams } from '../models/UpdateCustomerSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a customer
 * @param requestBody
 * @returns any Customer created
 * @throws ApiError
 */
export const customerControllerCreateCustomer = (requestBody: CreateCustomerParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/customer`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read a customer
 * @param id
 * @returns any Customer data
 * @throws ApiError
 */
export const customerControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/customer/${id}`,
    errors: {
      404: `Customer does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a customer
 * @param id
 * @returns any customer delete
 * @throws ApiError
 */
export const customerControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/customer/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Customer does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a customer
 * @param id
 * @param requestBody
 * @returns any Customer Updated
 * @throws ApiError
 */
export const customerControllerUpdate = (id: string, requestBody: UpdateCustomerParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/customer/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read all the customer
 * @param projectId
 * @returns any Customer data
 * @throws ApiError
 */
export const customerControllerReadAll = (projectId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/customer/project/${projectId}`,
    errors: {
      404: `Customers does not exists`,
    },
  });
};

/**
 * As a admin, I want to create a section for a customer
 * @param requestBody
 * @returns any CustomerSection created
 * @throws ApiError
 */
export const customerSectionControllerCreate = (requestBody: CreateCustomerSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/customer/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read a section from the customer
 * @param id
 * @returns any CustomerSection data
 * @throws ApiError
 */
export const customerSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/customer/section/${id}`,
    errors: {
      404: `CustomerSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a section for a customer
 * @param id
 * @returns any customerSection delete
 * @throws ApiError
 */
export const customerSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/customer/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `CustomerSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a section for a customer
 * @param id
 * @param requestBody
 * @returns any CustomerSection Updated
 * @throws ApiError
 */
export const customerSectionControllerUpdate = (
  id: string,
  requestBody: UpdateCustomerSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/customer/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useCustomerControllerCreateCustomerService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateCustomerParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerControllerCreateCustomer, { method, ...options });
};

export const useCustomerControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerControllerGet, { method, ...options });
};

export const useCustomerControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerControllerDelete, { method, ...options });
};

export const useCustomerControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateCustomerParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerControllerUpdate, { method, ...options });
};

export const useCustomerControllerReadAllService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (projectId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerControllerReadAll, { method, ...options });
};

export const useCustomerSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateCustomerSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerCreate, { method, ...options });
};

export const useCustomerSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerGet, { method, ...options });
};

export const useCustomerSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerDelete, { method, ...options });
};

export const useCustomerSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateCustomerSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerUpdate, { method, ...options });
};
