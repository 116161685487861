import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Button, FormikTextField, Horizontal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';

interface CustomFormProps {
  initialValues: any;
  handleSubmit: (values: any) => void;
  handleCancel: () => void;
  fields: Array<{
    name: string;
    placeholderKey: string;
  }>;
  submitButtonLabelKey: string;
  cancelButtonLabelKey: string;
}

const styles = {
  color: 'white',
  // backgroundColor: '#9B9EA3',
  width: '200px',
  alignSelf: 'center',
  marginTop: 20,
};
export const CreateForm: React.FC<CustomFormProps> = ({
  initialValues,
  handleSubmit,
  handleCancel,
  fields,
  submitButtonLabelKey,
  cancelButtonLabelKey,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => (
      <FormikForm>
        <Vertical gap={20}>
          {fields.map((field) => (
            <FormikTextField
              key={field.name}
              name={field.name}
              placeholder={i18n.t(field.placeholderKey)}
              styles={{
                box: {
                  borderColor: 'black',
                  borderStyle: 'solid',
                  borderWidth: 1,
                },
                text: { color: 'black' },
              }}
            />
          ))}
          <Horizontal gap={20} width="100%" justifyContent="center">
            <Button
              isDisabled={formikProps.isSubmitting}
              cursor={formikProps.isSubmitting ? 'wait' : 'pointer'}
              opacity={formikProps.isSubmitting ? '0.5' : '1'}
              type="button"
              onClick={handleCancel}
              isAuto
              {...styles}
            >
              {i18n.t(cancelButtonLabelKey)}
            </Button>
            <Button
              isDisabled={formikProps.isSubmitting}
              type="submit"
              onClick={formikProps.handleSubmit}
              cursor={formikProps.isSubmitting ? 'wait' : 'pointer'}
              opacity={formikProps.isSubmitting ? '0.5' : '1'}
              isAuto
              {...styles}
            >
              {i18n.t(submitButtonLabelKey)}
            </Button>
          </Horizontal>
        </Vertical>
      </FormikForm>
    )}
  </Formik>
);
