import React from 'react';
import { Vertical } from '@app-studio/web';
import { Element } from 'app-studio';

import i18n from 'src/utils/locale';
import { C3 } from 'src/components/Text';

import { TableViewProps } from './SourcesTable.props';
import { Source } from '../../Source/Source.component';

export const SourcesTableView: React.FC<TableViewProps> = ({ isLoading, sources, sourceRequests }) => {
  const headers: any = i18n.t('page.newsletter.sourceHeaders');
  return (
    <Vertical>
      <TableContainer width="100%" borderCollapse="collapse">
        <TableHead borderWidth="0px 0px 0.5px 0px" borderColor="#E6E6E6" borderStyle="solid" padding={8}>
          <TableRow>
            {headers.map((title: string, index: number) => (
              <TableHeadCell key={index} width={index > 1 ? '10%' : '30%'} textAlign="left" padding="8px">
                <C3>{title}</C3>
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            sources.map((source, index) => <Source key={index} source={source} sourceRequests={sourceRequests} />)
          ) : (
            <Loading />
          )}
        </TableBody>
      </TableContainer>
    </Vertical>
  );
};

export const Loading = () => (
  <TableRow height="100%" width="100%">
    <TableCell colsSpan={6} textAlign="center" padding={20}>
      Loading
    </TableCell>
  </TableRow>
);

const TableContainer = (props: any) => <Element as="table" {...props} />;
const TableHead = (props: any) => <Element as="thead" {...props} />;
const TableRow = (props: any) => <Element as="tr" {...props} />;
const TableHeadCell = (props: any) => <Element as="th" {...props} />;
const TableBody = (props: any) => <Element as="tbody" {...props} />;
const TableCell = (props: any) => <Element as="td" {...props} />;
