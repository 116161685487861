import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { PageHeader } from 'src/components/Header';
import { useProjectStates } from './customer.state';

import { useCustomersRequests } from './customer.request';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Section } from 'src/components/Section';
import { useEffect } from 'react';
import { H3 } from 'src/components/Text';
import { CreateButton } from 'src/components/Button';
import { Table } from 'src/components/Table';
import { useMount } from 'app-studio';

const CustomerPage = () => {
  let section = { id: '', content: '', title: '' };
  const initialValues = {
    description: '',
  };

  const { projectId, id } = useParams();
  const location = useLocation();
  const { isEditing, setIsEditing } = useProjectStates();
  const headers: any = i18n.t('page.tableHeader');
  const navigate = useNavigate();

  useMount(() => {
    getAllCustomerRequest.run(projectId);
  });

  useEffect(() => {
    if (id) {
      getCustomerRequest.run(id);
    }
  }, [location.pathname]);

  const handleEditorCallback = () => {
    getCustomerRequest.run(id);
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const refresh = () => {
    getAllCustomerRequest.run(projectId);
    hideModal();
  };

  const marketDescripRequests = useCustomersRequests({ handleEditorCallback, editorCallback, refresh });

  const {
    getCustomerRequest,
    updateSectionRequest,
    generateContentRequest,
    getAllCustomerRequest,
    createCustomerRequest,
    deleteCustomerRequest,
  } = marketDescripRequests;

  const areCustomersLoaded = !getAllCustomerRequest.loader && getAllCustomerRequest && getAllCustomerRequest.data;
  const isCustomerLoaded = !getCustomerRequest.loader && getCustomerRequest && getCustomerRequest.data;

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        request: generateContentRequest,
        description: '',
        sectionTitle: title,
        isLoading: isLoading,
        sectionContent: content,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  const navigateTo = (id: string) => {
    navigate(`/${projectId}/project/strategy/customer/${id}`);
  };

  const handleButtonClick = () => {
    showModal(
      'DescribeModal',
      {
        key: new Date().getTime(),
        onNext: (input) => {
          createCustomerRequest.run({ projectId, description: input.description });
        },
        initialValues,
        title: i18n.t('modal.customer.describe'),
        keyName: 'description',
        textAreaStyle: { height: 200, width: '65vw' },
        placeholder:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate, augue ut aliquet ultrices, orci magna ornare urna, eget interdum ex enim in purus. Nam volutpat eros ligula, eu tempor mauris varius a. ',
      },
      { position: 'right' }
    );
  };

  const buttonCallback = (id: string) => deleteCustomerRequest.run(id);
  return (
    <>
      {!id && (
        <Vertical width="100%" gap={50} padding="60px 40px">
          <H3>{i18n.t('page.customer.headerMain')}</H3>
          <CreateButton text={i18n.t('button.create')} handleButtonClick={handleButtonClick} />
          {areCustomersLoaded ? (
            <Table
              headers={headers}
              menu={getAllCustomerRequest.data}
              navigateTo={navigateTo}
              buttonCallback={buttonCallback}
            />
          ) : (
            <Center width={'100%'} height={'100%'}>
              <Loader />
            </Center>
          )}
        </Vertical>
      )}
      {id && (
        <Vertical width="100%" padding="60px 40px">
          <PageHeader
            prefixTitle={i18n.t('page.customer.header')}
            title={getCustomerRequest?.data?.name ?? ''}
            paddingBottom={50}
          />
          {isCustomerLoaded &&
            getCustomerRequest.data.customerSections.map((section) => (
              <Section
                id={section.id}
                key={section.id}
                sectionId={section.id}
                isEditing={isEditing}
                title={section.title}
                editorIconCallback={handleEditorSelection}
                content={section.content}
              />
            ))}
        </Vertical>
      )}
    </>
  );
};

export default CustomerPage;
