import React from 'react';
import { View, ViewProps } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMount } from 'app-studio';

interface PageLayoutProps {
  children?: any;
  shouldIndex?: boolean;
  description?: string;
  title?: string;
  loading?: boolean;
}

const PageLayout: React.FC<PageLayoutProps & ViewProps> = (props) => {
  // const location = useLocation();
  // const { isAuthentificated } = useAuthStore(({ isAuthentificated }) => ({
  //   isAuthentificated,
  // }));
  // const navigate = useNavigate();

  return (
    <View overflow="hidden" {...props} media={{ mobile: { width: '100%' } }}>
      <View width="100%" overflowY="scroll" height="100vh">
        {props.children}
      </View>
    </View>
  );
};

export default PageLayout;
