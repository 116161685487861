import React, { useEffect } from 'react';
import { Center, Horizontal, Vertical, View } from '@app-studio/web';
import { PageHeader } from 'src/components/Header';
import { C4 } from 'src/components/Text';
import { ImageIcon, ImageLogoSkeleton } from './visualIdentity.element';
import { useVisualIdentityStates } from './visualIdentity.state';
import { useVisualIdentityRequests } from './visualIdentity.request';
import { useAppStore } from 'src/stores/AppStore';
import i18n from 'src/utils/locale';

const VisualIdentityPage = () => {
  const { regenerateVisualIdentityRequest } = useVisualIdentityRequests();
  const { project } = useAppStore();

  const { selectedLogo, setSelectedLogo, logos, setLogos, primaryColor, setPrimaryColor, palette, setPalette } =
    useVisualIdentityStates();

  useEffect(() => {
    if (project && project.projectMedias) {
      const logoMedia: any = project.projectMedias.find((media) => media.name === 'logo');
      const colorThemeMedia = project.projectMedias.find((media) => media.name === 'colorTheme');

      // Handle logos
      if (logoMedia && logoMedia.json) {
        try {
          const parsedLogos = logoMedia.json;
          setLogos(parsedLogos);

          // Set the first logo as selected if available
          const firstLogoName = Object.keys(parsedLogos)[0];
          if (firstLogoName) {
            setSelectedLogo({ name: firstLogoName, url: parsedLogos[firstLogoName].url });
          }
        } catch (error) {
          console.error('Failed to parse logo JSON:', error);
        }
      }

      // Handle color theme
      if (colorThemeMedia && colorThemeMedia.content) {
        try {
          const theme = JSON.parse(colorThemeMedia.content);
          setPrimaryColor(theme.primaryHex || '');
          console.log({ palette: theme.paletteHex });
          setPalette(theme.paletteHex || []);
        } catch (error) {
          console.error('Failed to parse color theme JSON:');
        }
      }
    }
  }, [project, setSelectedLogo]);

  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <PageHeader
        isLoading={!!regenerateVisualIdentityRequest.loading}
        prefixTitle={i18n.t('page.visual.header')}
        regenerateClick={() => {
          regenerateVisualIdentityRequest.run();
        }}
        paddingBottom={50}
      />
      <Horizontal width="100%">
        <Vertical width="100%" gap={30} flex={1}>
          <C4>{i18n.t('page.visual.logo')}</C4>
          {selectedLogo?.url ? (
            <Center
              height={186}
              width={218}
              borderRadius={10}
              backgroundColor={
                selectedLogo.name === 'white' ? 'black' : selectedLogo.name === 'color' ? '#D9D9D9' : '#D9D9D9'
              }
            >
              <ImageIcon imageIcon={selectedLogo.url} height={150} width={150} />
            </Center>
          ) : (
            <ImageLogoSkeleton height={186} width={218} />
          )}
          <Horizontal gap={20}>
            {Object.entries(logos).map(([name, logo]: any, index) => (
              <View
                key={index}
                backgroundColor={name === 'white' ? 'black' : name === 'color' ? '#D9D9D9' : '#D9D9D9'}
                border={selectedLogo.name === name ? '2px solid gray' : 'none'}
                borderRadius={10}
                onClick={() => setSelectedLogo({ name, url: logo.url })}
              >
                {logo.url ? (
                  <ImageIcon imageIcon={logo.url} height={58} width={58} />
                ) : (
                  <ImageLogoSkeleton height={58} width={58} />
                )}
              </View>
            ))}
          </Horizontal>
        </Vertical>
        <Vertical width="100%" gap={30} flex={1}>
          <C4>{i18n.t('page.visual.color')}</C4>
          {primaryColor ? (
            <View height={186} width={218} backgroundColor={primaryColor} borderRadius={10} />
          ) : (
            <ImageLogoSkeleton height={186} width={218} />
          )}
          <Horizontal gap={20}>
            {palette.length > 0 &&
              palette.map((color, index) => (
                <View
                  key={index}
                  height={58}
                  width={58}
                  backgroundColor={color}
                  borderRadius={10}
                  border="0.5px solid black"
                  // onClick={() => setSelectedLogo({ name, url: logo.url })}
                />
              ))}
          </Horizontal>
        </Vertical>
      </Horizontal>
    </Vertical>
  );
};

export default VisualIdentityPage;
