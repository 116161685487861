import React from 'react';
import { Vertical, Horizontal, View, Button } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import { C3, C4 } from 'src/components/Text';
import { DeviceSelector, ModeSelector, Prompt, RightMenuEditor } from './editor.elements';
import PageEditor from './components/PageEditor/PageEditor';
import { deviceSize } from './editor.utils';
import i18n from 'src/utils/locale';
import { Mode } from './editor.props';

interface EditorPanelProps {
  isEditorMenuLoading: boolean;
  selectedProps: any;
  selectedMode: Mode;
  setSelectedMode: (mode: Mode) => void;
  showSections: boolean;
  setShowSections: (show: boolean) => void;
  device: string;
  setDevice: (device: string) => void;
  landingName: string;
  landingCode: string;
  isGeneratingContent: boolean;
  isInspectorEnabled: boolean;
  setIsInspectorEnabled: (enabled: boolean) => void;
  onPromptSubmit: (input: { input: string }) => void;
  onNavigateBack: () => void;
  handleCancel: Function;
  handleSubmit: Function;
  regenerateImage: Function;
}

const EditorPanel: React.FC<EditorPanelProps> = ({
  isEditorMenuLoading,
  selectedProps,
  showSections,
  setShowSections,
  selectedMode,
  setSelectedMode,
  device,
  setDevice,
  landingName,
  landingCode,
  isGeneratingContent,
  isInspectorEnabled,
  setIsInspectorEnabled,
  onPromptSubmit,
  onNavigateBack,
  handleCancel,
  handleSubmit,
  regenerateImage,
}) => {
  return (
    <Vertical
      justifyContent={selectedMode?.text === 'Prompt' ? 'space-between' : 'flex-start'}
      gap={30}
      height="100%"
      width="100%"
      overflow="hidden"
      paddingLeft={showSections ? 0 : 30}
    >
      <Horizontal justifyContent="space-between" gap={40} alignItems="center">
        <Horizontal gap={40}>
          {!showSections && (
            <AssetIcon
              name="SectionMenuSvg"
              cursor="pointer"
              width={24}
              height={24}
              onClick={() => setShowSections(!showSections)}
            />
          )}
          <DeviceSelector type={device} setType={setDevice} onScreenClick={() => setShowSections(false)} />
        </Horizontal>

        <ModeSelector selectedMode={selectedMode} setSelectedMode={setSelectedMode} />
        <AssetIcon
          name="BlackCloseSvg"
          width={24}
          height={24}
          cursor="pointer"
          marginRight={20}
          onClick={onNavigateBack}
        />
      </Horizontal>
      <Horizontal height="70vh" paddingRight={selectedMode.text === 'Prompt' ? '20px' : '0px'}>
        {landingCode && (
          <View overflow="auto" borderRadius={10} border="1px solid black" width="100%">
            <View
              id="editor-container"
              width={deviceSize[device].width}
              borderRadius={10}
              margin="auto"
              height={'100%'}
              overflow="auto"
            >
              <PageEditor code={landingCode} />
            </View>
          </View>
        )}
        {selectedMode?.text !== 'Prompt' && (
          <RightMenuEditor
            // key={new Date().getTime()}
            isLoading={isEditorMenuLoading}
            selectedMode={selectedMode?.text.toLocaleLowerCase()}
            selectedProps={selectedProps}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            regenerateImage={regenerateImage}
          />
        )}
      </Horizontal>

      <Horizontal alignItems="center" width="100%">
        {selectedMode?.text !== 'Interaction' && selectedMode?.text !== 'Image' && (
          <View margin="auto">
            <Prompt
              isDisabled={!(selectedProps && Object.keys(selectedProps).length > 0)}
              showSelector={selectedMode?.text === 'Prompt'}
              isLoading={isGeneratingContent}
              initialValues={{ input: '' }}
              isInspectorEnabled={isInspectorEnabled}
              handleSubmit={(input, { setSubmitting }) => {
                onPromptSubmit(input);
                setSubmitting(false);
              }}
              handleSelector={() => setIsInspectorEnabled(!isInspectorEnabled)}
            />
          </View>
        )}
        <Button borderRadius="5.6px" height="32px" width="86px" marginLeft="auto" marginRight={20} isAuto>
          <C4>{i18n.t('action.publish')}</C4>
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export default EditorPanel;
