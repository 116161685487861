import { Button, Horizontal } from '@app-studio/web';
import { Element } from 'app-studio';
import i18n from 'src/utils/locale';
import { C4, C3 } from './Text';
import { ReactComponent as AttachIcon } from 'src/assets/svg/attach.svg';
import { ReactComponent as EyeIcon } from 'src/assets/svg/eye.svg';
import { ReactComponent as MenuIcon } from 'src/assets/svg/menu.svg';

export const Table = ({ isLandingMenu = false, headers, menu, navigateTo, buttonCallback }) => {
  return (
    <TableContainer width="100%" borderCollapse="collapse">
      <TableHead borderWidth="0px 0px 0.5px 0px" borderColor="#E6E6E6" borderStyle="solid" padding={8}>
        <TableRow>
          {headers.map((title: string, index: number) => (
            <TableHeadCell key={index} width={index === 0 ? '30%' : '10%'} textAlign="left" padding="8px">
              <C3>{title}</C3>
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLandingMenu ? (
          <LandingBodyTable navigateTo={navigateTo} menu={menu} buttonCallback={buttonCallback} />
        ) : (
          <BodyTable navigateTo={navigateTo} menu={menu} buttonCallback={buttonCallback} />
        )}
      </TableBody>
    </TableContainer>
  );
};

export const BodyTable = ({ menu, navigateTo, buttonCallback }) => {
  const formattedDate = (date: number | Date | undefined) =>
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date);

  return menu.map((menu, index: number) => (
    <TableRow key={index} padding="8px" borderWidth={index === 0 ? '0' : '1px 0 0 0'} borderColor="color.gray.30">
      <TableCell width="60%" padding="8px">
        <C3 onClick={() => navigateTo(menu.id)}>{menu.name ? menu.name : menu.url}</C3>
      </TableCell>
      <TableCell width="10%" padding="8px">
        <C3>{formattedDate(new Date(menu.createdAt))}</C3>
      </TableCell>
      <TableCell width="10%" padding="8px">
        <Button
          onClick={() => buttonCallback(menu.id)}
          color="white"
          borderRadius={8}
          padding="3px 0"
          width="99px"
          isAuto
        >
          <C4>{i18n.t('button.remove')}</C4>
        </Button>
      </TableCell>
    </TableRow>
  ));
};

export const LandingBodyTable = ({ menu, navigateTo, buttonCallback }) => {
  const formattedDate = (date: number | Date | undefined) =>
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date);

  return menu.map((menu, index: number) => (
    <TableRow key={index} padding="8px" borderWidth={index === 0 ? '0' : '1px 0 0 0'} borderColor="color.gray.30">
      <TableCell width="30%" padding="8px">
        <C3 onClick={() => navigateTo(menu.id)}>{menu.name ?? menu.url}</C3>
      </TableCell>

      <TableCell width="10%" padding="8px">
        <C3>{formattedDate(new Date(menu.createdAt))}</C3>
      </TableCell>
      {/* <TableCell width="10%" padding="8px">
        <Horizontal flex={2} padding="8px" gap={10} wrap="nowrap">
          <AttachIcon onClick={() => {}} />
          <EyeIcon onClick={() => {}} />
          <MenuIcon onClick={() => {}} />
        </Horizontal>
      </TableCell> */}
    </TableRow>
  ));
};

export const TableContainer = (props: any) => <Element as="table" {...props} />;
export const TableHead = (props: any) => <Element as="thead" {...props} />;
export const TableRow = (props: any) => <Element as="tr" {...props} />;
export const TableHeadCell = (props: any) => <Element as="th" {...props} />;
export const TableBody = (props: any) => <Element as="tbody" {...props} />;
export const TableCell = (props: any) => <Element as="td" {...props} />;
