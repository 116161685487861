import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { Header } from 'src/components/Header';
import { useProjectStates } from './projectDescriptions.state';

import { useProjectDescriptionsRequests } from './projectDescriptions.request';
import { useLocation, useParams } from 'react-router-dom';
import { Section } from 'src/components/Section';
import { useEffect } from 'react';

const ProjectDescriptionPage = () => {
  let section = { id: '', content: '', title: '' };

  const { projectId } = useParams();
  const location = useLocation();
  const { isEditing, setIsEditing } = useProjectStates();

  useEffect(() => {
    getProjectRequest.run(projectId);
  }, [location.pathname]);

  const handleEditorCallback = () => {
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ title: section.title, content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const projectDescripRequests = useProjectDescriptionsRequests({ handleEditorCallback, editorCallback });

  const { getProjectRequest, updateSectionRequest, generateContentRequest } = projectDescripRequests;

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        request: generateContentRequest,
        description: content,
        sectionTitle: title,
        isLoading: isLoading,
        sectionContent: content,
        initialValues: { prompt: '', name: section.title },
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const handleEditorSelection = ({ id, title, content }: { id: string; title: string; content: string }) => {
    section.content = content;
    section.id = id;
    section.title = title;

    setIsEditing(true);
    showEditorModal({ title, content });
  };

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ title: section.title, content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleClose = () => {
    hideModal();
    setIsEditing(false);
  };

  console.log('getProjectRequest:', getProjectRequest);
  return (
    <Vertical width="100%" padding="60px 40px">
      <Header title={getProjectRequest?.data?.name ?? ''} paddingBottom={50} />
      {!getProjectRequest.loading ? (
        getProjectRequest &&
        getProjectRequest.data &&
        getProjectRequest.data.projectSections
          .sort((a, b) => {
            const order = ['Description', 'Problem', 'Solution', 'Business Model', 'Competition', 'Vision'];
            return order.indexOf(a.title) - order.indexOf(b.title);
          })
          .map((section) => (
            <Section
              id={section.id}
              key={section.id}
              sectionId={section.id}
              isEditing={isEditing}
              title={section.title}
              editorIconCallback={handleEditorSelection}
              content={section.content}
            />
          ))
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};

export default ProjectDescriptionPage;
