/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateApplicationFileSectionParams } from '../models/CreateApplicationFileSectionParams';
import type { CreateCustomerSectionParams } from '../models/CreateCustomerSectionParams';
import type { CreateMarketSectionParams } from '../models/CreateMarketSectionParams';
import type { CreateNewsletterSectionParams } from '../models/CreateNewsletterSectionParams';
import type { CreateOfferSectionParams } from '../models/CreateOfferSectionParams';
import type { CreateProductSectionParams } from '../models/CreateProductSectionParams';
import type { CreateProjectSectionParams } from '../models/CreateProjectSectionParams';
import type { CreateSectionParams } from '../models/CreateSectionParams';
import type { EditApplicationFileSectionParams } from '../models/EditApplicationFileSectionParams';
import type { UpdateApplicationFileSectionParams } from '../models/UpdateApplicationFileSectionParams';
import type { UpdateCustomerSectionParams } from '../models/UpdateCustomerSectionParams';
import type { UpdateMarketSectionParams } from '../models/UpdateMarketSectionParams';
import type { UpdateNewsletterSectionParams } from '../models/UpdateNewsletterSectionParams';
import type { UpdateOfferSectionParams } from '../models/UpdateOfferSectionParams';
import type { UpdateProductSectionParams } from '../models/UpdateProductSectionParams';
import type { UpdateProjectSectionParams } from '../models/UpdateProjectSectionParams';
import type { UpdateSectionParams } from '../models/UpdateSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a Section
 * @param id
 * @param requestBody
 * @returns any Project Section created
 * @throws ApiError
 */
export const projectSectionControllerCreateProjectSection = (
  id: string,
  requestBody: CreateProjectSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/project/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an projectSection
 * @param id
 * @returns any Section data
 * @throws ApiError
 */
export const projectSectionControllerRetrieveProjectSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/project/section/${id}`,
    errors: {
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a projectSection
 * @param id
 * @returns any projectSection delete
 * @throws ApiError
 */
export const projectSectionControllerRemoveProjectSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/project/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a projectSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const projectSectionControllerUpdateProjectSection = (
  id: string,
  requestBody: UpdateProjectSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/project/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a section
 * @param id
 * @param requestBody
 * @returns any section created
 * @throws ApiError
 */
export const sectionControllerCreateSection = (
  id: string,
  requestBody: CreateSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/grant/file/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific section
 * @param id
 * @returns any section details fetched
 * @throws ApiError
 */
export const sectionControllerRetrieveSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/grant/file/section/${id}`,
    errors: {
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to delete a section
 * @param id
 * @returns any section deleted
 * @throws ApiError
 */
export const sectionControllerRemoveSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/grant/file/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to update a section
 * @param id
 * @param requestBody
 * @returns any section updated
 * @throws ApiError
 */
export const sectionControllerUpdateSection = (
  id: string,
  requestBody: UpdateSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/grant/file/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a Section
 * @param id
 * @param requestBody
 * @returns any Project Section created
 * @throws ApiError
 */
export const applicationFileSectionControllerCreateApplicationFileSection = (
  id: string,
  requestBody: CreateApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/application/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an applicationFileSection
 * @param id
 * @returns any Section data
 * @throws ApiError
 */
export const applicationFileSectionControllerRetrieveApplicationFileSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/application/section/${id}`,
    errors: {
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a applicationFileSection
 * @param id
 * @returns any applicationFileSection delete
 * @throws ApiError
 */
export const applicationFileSectionControllerRemoveApplicationFileSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/application/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a applicationFileSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const applicationFileSectionControllerUpdateApplicationFileSection = (
  id: string,
  requestBody: UpdateApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/application/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to update a applicationFileSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const applicationFileSectionControllerEditApplicationFileSectionContent = (
  id: string,
  requestBody: EditApplicationFileSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/application/section/${id}/content`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a newsletterSection
 * @param id
 * @param requestBody
 * @returns any NewsletterSection created
 * @throws ApiError
 */
export const newsletterSectionControllerGenerate = (
  id: string,
  requestBody: CreateNewsletterSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/newsletter/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an newsletterSection
 * @param id
 * @returns any NewsletterSection data
 * @throws ApiError
 */
export const newsletterSectionControllerRead = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/newsletter/section/${id}`,
    errors: {
      404: `NewsletterSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a newsletterSection
 * @param id
 * @returns any newsletterSection delete
 * @throws ApiError
 */
export const newsletterSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/newsletter/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `NewsletterSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a newsletterSection
 * @param id
 * @param requestBody
 * @returns any NewsletterSection Updated
 * @throws ApiError
 */
export const newsletterSectionControllerUpdate = (
  id: string,
  requestBody: UpdateNewsletterSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/newsletter/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a Section for a market
 * @param requestBody
 * @returns any MarketSection created
 * @throws ApiError
 */
export const marketSectionControllerCreate = (requestBody: CreateMarketSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/market/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read a section of the market
 * @param id
 * @returns any MarketSection data
 * @throws ApiError
 */
export const marketSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/market/section/${id}`,
    errors: {
      404: `MarketSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a section for a market
 * @param id
 * @returns any marketSection delete
 * @throws ApiError
 */
export const marketSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/market/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `MarketSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a section of the market
 * @param id
 * @param requestBody
 * @returns any MarketSection Updated
 * @throws ApiError
 */
export const marketSectionControllerUpdate = (
  id: string,
  requestBody: UpdateMarketSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/market/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, I want to create a section for a customer
 * @param requestBody
 * @returns any CustomerSection created
 * @throws ApiError
 */
export const customerSectionControllerCreate = (requestBody: CreateCustomerSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/customer/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read a section from the customer
 * @param id
 * @returns any CustomerSection data
 * @throws ApiError
 */
export const customerSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/customer/section/${id}`,
    errors: {
      404: `CustomerSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a section for a customer
 * @param id
 * @returns any customerSection delete
 * @throws ApiError
 */
export const customerSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/customer/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `CustomerSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a section for a customer
 * @param id
 * @param requestBody
 * @returns any CustomerSection Updated
 * @throws ApiError
 */
export const customerSectionControllerUpdate = (
  id: string,
  requestBody: UpdateCustomerSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/customer/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a productSection
 * @param requestBody
 * @returns any productSection created
 * @throws ApiError
 */
export const productSectionControllerCreate = (requestBody: CreateProductSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/product/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific productSection
 * @param id
 * @returns any productSection details fetched
 * @throws ApiError
 */
export const productSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/product/section/${id}`,
    errors: {
      404: `productSection not found`,
    },
  });
};

/**
 * As an admin, I want to delete a productSection
 * @param id
 * @returns any productSection deleted
 * @throws ApiError
 */
export const productSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/product/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `productSection not found`,
    },
  });
};

/**
 * As an admin, I want to update a productSection
 * @param id
 * @param requestBody
 * @returns any productSection updated
 * @throws ApiError
 */
export const productSectionControllerUpdate = (
  id: string,
  requestBody: UpdateProductSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/product/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a offerSection
 * @param requestBody
 * @returns any offerSection created
 * @throws ApiError
 */
export const offerSectionControllerCreate = (requestBody: CreateOfferSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/offer/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific offerSection
 * @param id
 * @returns any offerSection details fetched
 * @throws ApiError
 */
export const offerSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/offer/section/${id}`,
    errors: {
      404: `offerSection not found`,
    },
  });
};

/**
 * As an admin, I want to delete a offerSection
 * @param id
 * @returns any offerSection deleted
 * @throws ApiError
 */
export const offerSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/offer/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `offerSection not found`,
    },
  });
};

/**
 * As an admin, I want to update a offerSection
 * @param id
 * @param requestBody
 * @returns any offerSection updated
 * @throws ApiError
 */
export const offerSectionControllerUpdate = (
  id: string,
  requestBody: UpdateOfferSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/offer/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create a section
 * @param requestBody
 * @returns any section created
 * @throws ApiError
 */
export const sectionControllerCreate = (requestBody: CreateSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific section
 * @param id
 * @returns any section details fetched
 * @throws ApiError
 */
export const sectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/section/${id}`,
    errors: {
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to delete a section
 * @param id
 * @returns any section deleted
 * @throws ApiError
 */
export const sectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `section not found`,
    },
  });
};

/**
 * As an admin, I want to update a section
 * @param id
 * @param requestBody
 * @returns any section updated
 * @throws ApiError
 */
export const sectionControllerUpdate = (id: string, requestBody: UpdateSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to get all section
 * @param category
 * @returns any All section fetched
 * @throws ApiError
 */
export const sectionControllerReadAll = (category: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/section/all/${category}`,
    errors: {
      404: `Sections does not exists`,
    },
  });
};

export const useProjectSectionControllerCreateProjectSectionService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateProjectSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerCreateProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerRetrieveProjectSectionService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerRetrieveProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerRemoveProjectSectionService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerRemoveProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerUpdateProjectSectionService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProjectSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerUpdateProjectSection, {
    method,
    ...options,
  });
};

export const useSectionControllerCreateSectionService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerCreateSection, { method, ...options });
};

export const useSectionControllerRetrieveSectionService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerRetrieveSection, { method, ...options });
};

export const useSectionControllerRemoveSectionService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerRemoveSection, { method, ...options });
};

export const useSectionControllerUpdateSectionService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerUpdateSection, { method, ...options });
};

export const useApplicationFileSectionControllerCreateApplicationFileSectionService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerCreateApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerRetrieveApplicationFileSectionService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerRetrieveApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerRemoveApplicationFileSectionService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerRemoveApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerUpdateApplicationFileSectionService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerUpdateApplicationFileSection, { method, ...options });
};

export const useApplicationFileSectionControllerEditApplicationFileSectionContentService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: EditApplicationFileSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(applicationFileSectionControllerEditApplicationFileSectionContent, { method, ...options });
};

export const useNewsletterSectionControllerGenerateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateNewsletterSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(newsletterSectionControllerGenerate, {
    method,
    ...options,
  });
};

export const useNewsletterSectionControllerReadService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(newsletterSectionControllerRead, { method, ...options });
};

export const useNewsletterSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(newsletterSectionControllerDelete, { method, ...options });
};

export const useNewsletterSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateNewsletterSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(newsletterSectionControllerUpdate, { method, ...options });
};

export const useMarketSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateMarketSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerCreate, { method, ...options });
};

export const useMarketSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerGet, { method, ...options });
};

export const useMarketSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerDelete, { method, ...options });
};

export const useMarketSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateMarketSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerUpdate, { method, ...options });
};

export const useCustomerSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateCustomerSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerCreate, { method, ...options });
};

export const useCustomerSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerGet, { method, ...options });
};

export const useCustomerSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerDelete, { method, ...options });
};

export const useCustomerSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateCustomerSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(customerSectionControllerUpdate, { method, ...options });
};

export const useProductSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateProductSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerCreate, { method, ...options });
};

export const useProductSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerGet, { method, ...options });
};

export const useProductSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerDelete, { method, ...options });
};

export const useProductSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProductSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(productSectionControllerUpdate, { method, ...options });
};

export const useOfferSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateOfferSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerCreate, { method, ...options });
};

export const useOfferSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerGet, { method, ...options });
};

export const useOfferSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerDelete, { method, ...options });
};

export const useOfferSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateOfferSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerUpdate, { method, ...options });
};

export const useSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerCreate, { method, ...options });
};

export const useSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerGet, { method, ...options });
};

export const useSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerDelete, { method, ...options });
};

export const useSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerUpdate, { method, ...options });
};

export const useSectionControllerReadAllService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (category: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(sectionControllerReadAll, { method, ...options });
};
