import { useState } from 'react';

export const useVisualIdentityStates = () => {
  const [selectedLogo, setSelectedLogo] = useState<any>(null);

  const [logos, setLogos] = useState<any>({});
  const [primaryColor, setPrimaryColor] = useState<string>('');
  const [palette, setPalette] = useState<string[]>([]);

  return {
    selectedLogo,
    setSelectedLogo,
    logos,
    setLogos,
    primaryColor,
    setPrimaryColor,
    palette,
    setPalette,
  };
};
