import { useParams } from 'react-router-dom';
import { GrantService } from 'src/services/api';

export const useGrantFileRequests = ({ handleUploadCallBackFile }) => {
  const { id } = useParams();
  const getGrantRequest = GrantService.useGrantControllerRetrieveGrantService({});

  const uploadFileRequest = GrantService.useGrantFileUploadControllerCreateFileService({
    onSuccess: () => {
      getGrantRequest.run(id);
      handleUploadCallBackFile();
    },
  });

  const deleteFileRequest = GrantService.useGrantFileUploadControllerRemoveGrantFileService({
    onSuccess: () => {
      getGrantRequest.run(id);
    },
  });

  return {
    getGrantRequest,
    uploadFileRequest,
    deleteFileRequest,
  };
};
