import React from 'react';

import DocPage from 'src/pages/docs/docs.page';
import LoginPage from 'src/pages/auth/login.page';
import RegisterPage from 'src/pages/auth/register.page';
import SettingsPage from 'src/pages/settings/settings.page';

import EditorPage from 'src/pages/editor/editor.page';
import GrantPage from 'src/pages/grant/grant.page';
import { GrantUploaderPage } from 'src/pages/grantUploader/grantUploader.page';
import { DocumentPage } from 'src/pages/document/document.page';
import ProjectDescriptionPage from 'src/pages/ProjectDescriptions/projectDescriptions.page';
import EnterprisePage from 'src/pages/enterprise/enterprise.page';
import ApplicationPage from 'src/pages/application/application.page';
import ApplicationDetailsPage from 'src/pages/applicationDetails/applicationDetails.page';
import ApplicationFilesPage from 'src/pages/applicationFiles/applicationFiles.page';
import PlanPage from 'src/pages/plan/plan.page';
import NewsletterPage from 'src/pages/newsletter/Newsletter.page';
import SourcesPage from 'src/pages/sources/Sources.page';
import NewsletterEditorPage from 'src/pages/newsletterEditor/NewsletterEditor.page';
import ProjectConfigPage from 'src/pages/projectConfig/projectConfig.page';
import HomePage from 'src/pages/home/home.page';
import LandingPage from 'src/pages/landing/landing.page';
import MarketPage from 'src/pages/market/market.page';
import CustomerPage from 'src/pages/customer/customer.page';
import OfferPage from 'src/pages/offer/offer.page';
import ProductPage from 'src/pages/product/product.page';
import BrandPage from 'src/pages/brand/brand.page';
import AccountPage from 'src/pages/account/account.page';
import TestPage from 'src/pages/Test.page';
import VisualIdentityPage from 'src/pages/visualIdentity/visualidentity.page';

export const RouterConfig = [
  { path: '/', element: <HomePage /> },
  { path: '/docs', element: <DocPage /> },
  { path: '/docs/:section', element: <DocPage /> },
  { path: '/docs/:section/:id', element: <DocPage /> },
  { name: 'login', path: '/auth/login', element: <LoginPage /> },
  { name: 'register', path: '/auth/register', element: <RegisterPage /> },
  { name: 'settings', path: '/settings', element: <SettingsPage /> },
  { name: 'account', path: '/account', element: <AccountPage /> },

  { name: 'fund', path: '/:projectId/fund/grant/grant', element: <GrantPage /> },
  { name: 'grandUpload', path: '/:projectId/fund/grant/:id', element: <GrantUploaderPage /> },
  { name: 'file', path: '/:projectId/fund/grant/:id/file/:fileId', element: <DocumentPage /> },
  { name: 'enterprise', path: '/:projectId/fund/grant/enterprise', element: <EnterprisePage /> },
  { name: 'application', path: '/:projectId/fund/grant/application', element: <ApplicationPage /> },
  { name: 'applicationFile', path: '/:projectId/fund/grant/application/files/:id', element: <ApplicationFilesPage /> },
  {
    name: 'applicationFile',
    path: '/:projectId/fund/grant/application/:id/:name/:fileId',
    element: <ApplicationDetailsPage />,
  },

  { name: 'newsletter', path: '/:projectId/social/newsletter', element: <NewsletterPage /> },
  { name: 'sources', path: '/:projectId/social/newsletter/:id/sources', element: <SourcesPage /> },
  { name: 'newslettereditor', path: '/:projectId/social/newsletter/:id', element: <NewsletterEditorPage /> },

  { name: 'project', path: '/:projectId/project/config/:id', element: <ProjectConfigPage /> },
  { name: 'plan', path: '/plan', element: <PlanPage /> },

  { name: 'market', path: '/:projectId/project/strategy/market', element: <MarketPage /> },
  { name: 'customer', path: '/:projectId/project/strategy/customer', element: <CustomerPage /> },
  { name: 'customer', path: '/:projectId/project/strategy/customer/:id', element: <CustomerPage /> },
  { name: 'offer', path: '/:projectId/project/strategy/offer', element: <OfferPage /> },
  { name: 'offer', path: '/:projectId/project/strategy/offer/:id', element: <OfferPage /> },
  { name: 'product', path: '/:projectId/project/strategy/product', element: <ProductPage /> },
  { name: 'brand', path: '/:projectId/project/brand/:category', element: <BrandPage /> },
  { name: 'landing', path: '/:projectId/dev/landing', element: <LandingPage /> },
  { name: 'editor', path: '/:projectId/dev/landing/:id', element: <EditorPage /> },
  { name: 'projectDescription', path: '/:projectId/project/overview', element: <ProjectDescriptionPage /> },
  { name: 'identity', path: '/:projectId/project/brand/visualIdentity', element: <VisualIdentityPage /> },
  { name: 'test', path: '/test', element: <TestPage /> },
];
