import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Button, Horizontal, Vertical, Center } from '@app-studio/web';
import { useAppControllerRootService } from 'src/services/api/services/AppService';
import { useMount } from 'app-studio';
import { injectExtensionAttributesToParseRule } from '@tiptap/core';
import { error } from 'console';
import { useAppStore } from 'src/stores/AppStore';

export const useLoadingLayout = () => {
  const [retry, setRetry] = useState(false);
  const [loadingStage, setLoadingStage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const { setServer } = useAppStore(({ setServer }) => ({
    setServer,
  }));

  const getRoot = useAppControllerRootService({
    onSuccess: (data) => {
      console.log('onSuccess');
      setLoadingStage(5);
      setProgress(100);
      setError(false);
      setTimeout(() => {
        setRetry(false);
      }, 1000);
      setServer();
    },
    onError: (error) => {
      console.log(error);
      if (!retry && loadingStage === 0) {
        setRetry(true);
        setLoadingStage(1);
        setProgress(0);
      }
      if (retry && loadingStage === 3) {
        setError(true);
        setLoadingStage(0);
        setProgress(0);
      }
    },
    onRetry: () => {
      console.log('onRetry');
      if (!retry && loadingStage === 0) {
        setRetry(true);
        setLoadingStage(1);
        setProgress(0);
      }
    },
  });

  useMount(() => {
    getRoot.run();
  });

  return { error, retry, loadingStage, progress, getRoot, setRetry, setLoadingStage, setProgress };
};

const LoadingLayout = ({ online, status, server }: any) => {
  const { error, retry, loadingStage, progress, getRoot, setRetry, setLoadingStage, setProgress } = useLoadingLayout();

  useEffect(() => {
    if (retry) {
      if (loadingStage == 0) {
        setTimeout(() => {
          setLoadingStage(1);
          setProgress(5);
        }, 3000);
      } else if (loadingStage === 1) {
        let interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 50) {
              clearInterval(interval);
              setLoadingStage(2);
              getRoot.run();
              return prevProgress;
            }
            return prevProgress + 7;
          });
        }, 5000);

        return () => clearInterval(interval);
      } else if (loadingStage === 2) {
        let interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 80) {
              clearInterval(interval);
              setLoadingStage(3);
              getRoot.run();
              return prevProgress;
            }
            return prevProgress + 10;
          });
        }, 3000);

        return () => clearInterval(interval);
      }
    }
    if (loadingStage === 3) {
      if (progress < 100 && retry === false) {
        setProgress(100);
      } else {
        getRoot.run();
        setLoadingStage(4);
        setProgress(80);
      }
    }
  }, [retry, loadingStage]);

  const renderLoadingMessage = () => {
    switch (loadingStage) {
      case 1:
        return 'Initializing server...';
      case 2:
        return progress < 50 ? 'Loading...' : 'Initializing modules...';
      case 3:
        return 'Initializing application...';
      default:
        return 'Initializing...';
    }
  };

  console.log({ error, retry, loadingStage, progress });

  if (retry == false) {
    return null;
  }

  return (
    <Center
      backgroundColor="white"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={1000}
      width={'100vw'}
    >
      {error && (
        <>
          <Text fontSize={24} fontWeight="bold" marginBottom={16}>
            An error occured while initializing the application. Please try again.
          </Text>
        </>
      )}
      {!error && (
        <Vertical>
          <Text fontSize={24} fontWeight="bold" marginBottom={16}>
            {renderLoadingMessage()}
          </Text>
          <View width={'50vw'} height={8} backgroundColor="theme.secondary" borderRadius={4} marginBottom={16}>
            <View width={`${progress}%`} height="100%" backgroundColor="color.dark.90" borderRadius={4} />
          </View>
          <Text>{Math.round(progress)}%</Text>
        </Vertical>
      )}
    </Center>
  );
};

export default LoadingLayout;
