import { ApplicationService, AssistantService, GrantService } from 'src/services/api';

export const useApplicationDetailssRequests = ({ editorCallback, updateCallback }) => {
  const getApplicationRequest = ApplicationService.useApplicationControllerRetrieveApplicationService({
    onSuccess: (data) => {
      getGrantRequest.run(data.grantId);
    },
  });

  const getGrantRequest = GrantService.useGrantControllerRetrieveGrantService();

  const updateApplicationFileSection =
    ApplicationService.useApplicationFileSectionControllerUpdateApplicationFileSectionService({
      onSuccess: () => {
        updateCallback();
      },
    });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getApplicationRequest,
    getGrantRequest,
    generateContentRequest,
    updateApplicationFileSection,
  };
};
