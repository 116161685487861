import react from 'react';
import { View } from 'app-studio';

export const ImageIcon = ({ imageIcon, height = 100, width = 75 }) => {
  return (
    <View
      backgroundImage={`url(${imageIcon})`}
      width={width}
      height={height}
      backgroundSize={'90%'}
      backgroundRepeat="no-repeat"
      backgroundPosition={'center center'}
      borderRdaius={10}
    />
  );
};

export const ImageLogoSkeleton = ({ height = 100, width = 100 }) => {
  return <View height={height} width={width} backgroundColor="gray" borderRadius={10} />;
};
