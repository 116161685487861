/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOfferParams } from '../models/CreateOfferParams';
import type { CreateOfferSectionParams } from '../models/CreateOfferSectionParams';
import type { UpdateOfferParams } from '../models/UpdateOfferParams';
import type { UpdateOfferSectionParams } from '../models/UpdateOfferSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create a offer
 * @param requestBody
 * @returns any offer created
 * @throws ApiError
 */
export const offerControllerCreateOffer = (requestBody: CreateOfferParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/offer`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific offer
 * @param id
 * @returns any offer details fetched
 * @throws ApiError
 */
export const offerControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/offer/${id}`,
    errors: {
      404: `offer not found`,
    },
  });
};

/**
 * As an admin, I want to delete a offer
 * @param id
 * @returns any offer deleted
 * @throws ApiError
 */
export const offerControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/offer/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `offer not found`,
    },
  });
};

/**
 * As an admin, I want to update a offer
 * @param id
 * @param requestBody
 * @returns any offer updated
 * @throws ApiError
 */
export const offerControllerUpdate = (id: string, requestBody: UpdateOfferParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/offer/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to get all offer
 * @param projectId
 * @returns any All offer fetched
 * @throws ApiError
 */
export const offerControllerReadAll = (projectId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/offer/project/${projectId}`,
    errors: {
      404: `Offers does not exists`,
    },
  });
};

/**
 * As an admin, I want to create a offerSection
 * @param requestBody
 * @returns any offerSection created
 * @throws ApiError
 */
export const offerSectionControllerCreate = (requestBody: CreateOfferSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/offer/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to read a specific offerSection
 * @param id
 * @returns any offerSection details fetched
 * @throws ApiError
 */
export const offerSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/offer/section/${id}`,
    errors: {
      404: `offerSection not found`,
    },
  });
};

/**
 * As an admin, I want to delete a offerSection
 * @param id
 * @returns any offerSection deleted
 * @throws ApiError
 */
export const offerSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/offer/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `offerSection not found`,
    },
  });
};

/**
 * As an admin, I want to update a offerSection
 * @param id
 * @param requestBody
 * @returns any offerSection updated
 * @throws ApiError
 */
export const offerSectionControllerUpdate = (
  id: string,
  requestBody: UpdateOfferSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/offer/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useOfferControllerCreateOfferService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateOfferParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerControllerCreateOffer, { method, ...options });
};

export const useOfferControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerControllerGet, { method, ...options });
};

export const useOfferControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerControllerDelete, { method, ...options });
};

export const useOfferControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateOfferParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerControllerUpdate, { method, ...options });
};

export const useOfferControllerReadAllService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (projectId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerControllerReadAll, { method, ...options });
};

export const useOfferSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateOfferSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerCreate, { method, ...options });
};

export const useOfferSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerGet, { method, ...options });
};

export const useOfferSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerDelete, { method, ...options });
};

export const useOfferSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateOfferSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(offerSectionControllerUpdate, { method, ...options });
};
