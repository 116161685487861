/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { createLandingComponentParams } from '../models/createLandingComponentParams';
import type { CreateLandingParams } from '../models/CreateLandingParams';
import type { FindLandingParams } from '../models/FindLandingParams';
import type { FixLandingParams } from '../models/FixLandingParams';
import type { UpdateLandingParams } from '../models/UpdateLandingParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create a landing
 * @param requestBody
 * @returns any landing created
 * @throws ApiError
 */
export const landingControllerCreate = (requestBody: CreateLandingParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landing`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to generate the code of the landing page
 * @param requestBody
 * @returns any landing created
 * @throws ApiError
 */
export const landingControllerGenerate = (requestBody: CreateLandingParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landing/generate`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * Create a component
 * @param requestBody
 * @returns any
 * @throws ApiError
 */
export const landingControllerCreateComponentTask = (
  requestBody: createLandingComponentParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landing/component`,
    body: requestBody,
    mediaType: 'application/json',
  });
};

/**
 * As a user, I want to read a specific landing
 * @param id
 * @returns any landing details fetched
 * @throws ApiError
 */
export const landingControllerRead = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/landing/${id}`,
    errors: {
      404: `landing not found`,
    },
  });
};

/**
 * As an admin, I want to delete a landing
 * @param id
 * @returns any landing deleted
 * @throws ApiError
 */
export const landingControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/landing/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `landing not found`,
    },
  });
};

/**
 * As an admin, I want to update a landing
 * @param id
 * @param requestBody
 * @returns any landing updated
 * @throws ApiError
 */
export const landingControllerUpdate = (id: string, requestBody: UpdateLandingParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/landing/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a user, I want to find a specific landing by criteria
 * @param requestBody
 * @returns any History found
 * @throws ApiError
 */
export const landingControllerFind = (requestBody: FindLandingParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landing/find`,
    body: requestBody,
    mediaType: 'application/json',
  });
};

/**
 * As an admin, I want to get all landing
 * @param projectId
 * @returns any All landing fetched
 * @throws ApiError
 */
export const landingControllerReadAll = (projectId: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/landing/project/${projectId}`,
    errors: {
      404: `landings does not exists`,
    },
  });
};

/**
 * As a user, i want to generate a new code for my landing
 * @param id
 * @param requestBody
 * @returns any Landing Code Fixed
 * @throws ApiError
 */
export const landingControllerFix = (id: string, requestBody: FixLandingParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/landing/fix/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      404: `Exemple doesn't exists`,
    },
  });
};

export const useLandingControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateLandingParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerCreate, { method, ...options });
};

export const useLandingControllerGenerateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateLandingParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerGenerate, { method, ...options });
};

export const useLandingControllerCreateComponentTaskService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: createLandingComponentParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerCreateComponentTask, {
    method,
    ...options,
  });
};

export const useLandingControllerReadService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerRead, { method, ...options });
};

export const useLandingControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerDelete, { method, ...options });
};

export const useLandingControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateLandingParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerUpdate, { method, ...options });
};

export const useLandingControllerFindService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: FindLandingParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerFind, { method, ...options });
};

export const useLandingControllerReadAllService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (projectId: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerReadAll, { method, ...options });
};

export const useLandingControllerFixService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: FixLandingParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(landingControllerFix, { method, ...options });
};
