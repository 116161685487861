import { Center, Loader, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';

import i18n from 'src/utils/locale';
import { H3 } from 'src/components/Text';
import { EnterpriseForm } from 'src/forms/EnterpriseForm';
import { useEnterpriseRequests } from './enterprise.request';
import { useAuthStore } from 'src/stores/AuthStore';

const ProjectPage = () => {
  const enterpriseRequests = useEnterpriseRequests();
  const { createEnterpriseRequest, getEnterpriseRequest, updateEnterpriseRequest } = enterpriseRequests;
  const { user } = useAuthStore();

  useMount(() => {
    if (user && user.enterprise) {
      getEnterpriseRequest.run();
    }
  });

  console.log({ user });

  const initialValues = {
    name: getEnterpriseRequest?.data?.name ?? '',
    address: getEnterpriseRequest?.data?.address ?? '',
    siretNumber: getEnterpriseRequest?.data?.siretNumber ?? '',
    phone: getEnterpriseRequest?.data?.phone ?? '',
    creationDate: getEnterpriseRequest?.data?.creationDate ?? '2023-05-30',
  };

  const handleSubmit = (values: any) => {
    if (user && user.enterprise) {
      updateEnterpriseRequest.run(values);
    } else {
      createEnterpriseRequest.run(values);
    }
  };

  const isLoaded = (!getEnterpriseRequest.loader && getEnterpriseRequest.data) || (user && !user.enterprise);

  return isLoaded ? (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <H3>{i18n.t('page.enterprise.enterprise')}</H3>
      <EnterpriseForm
        key={new Date().getTime()}
        isLoading={!!updateEnterpriseRequest.loader}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </Vertical>
  ) : (
    <Center width={'100%'} height={'100%'}>
      <Loader />
    </Center>
  );
};
export default ProjectPage;
