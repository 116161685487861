import React, { useEffect } from 'react';
import { hideModal, Modal } from '@app-studio/web';
import { H3 } from 'src/components/Text';
import { CreateProjectForm, Loading } from 'src/pages/Project/project.element';
import { AssetIcon } from 'src/utils/assets';
import i18n from 'src/utils/locale';
import * as AsyncStorage from 'src/utils/localstorage';
import { useProjectStates } from 'src/pages/Project/projet.state';
import { useProjectRequests } from 'src/pages/Project/project.request';
import { useNavigate } from 'react-router-dom';
import { ConfigForm } from 'src/forms/ConfigForm';
import { useNavLayoutRequests } from 'src/layouts/NavLayout/NavLayout.request';
import { useAppStore } from 'src/stores/AppStore';

enum Steps {
  NameProject,
  DescribeProject,
  Loading,
}

interface StoredState {
  step: Steps;
  data: ProjectData;
}

interface ProjectData {
  name?: string;
  description?: string;
}

export const ProjectModal = ({ hasProjects }) => {
  const { projects, setProjects } = useAppStore();
  const navigate = useNavigate();
  const titles: any[] = i18n.t('page.project.titles') as any[];

  const placeholders = {
    name: i18n.t('page.project.projectName'),
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate, augue ut aliquet ultrices, orci magna ornare urna, eget interdum ex enim in purus. Nam volutpat eros ligula, eu tempor mauris varius a. Morbi sit amet tortor ut augue luctus sollicitudin. Quisque ipsum quam, euismod et sodales vehicula, pretium nec leo. Maecenas condimentum enim ut viverra ornare. Nulla sit amet lobortis justo. Vestibulum at rhoncus metus, at aliquet justo. Morbi vulputate eros at dui aliquam, in interdum lectus hendrerit. Aenean tristique pharetra dolor sed aliquam. Donec quis purus a turpis auctor mollis. Ut sodales fermentum mauris, et consequat dui imperdiet ac. Nunc nec libero quis est molestie ultricies eget et nulla. Vivamus feugiat mauris ultricies mi suscipit, non tincidunt lacus imperdiet.',
    additionalInfo:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate, augue ut aliquet ultrices, orci magna ornare urna, eget interdum ex enim in purus. Nam volutpat eros ligula, eu tempor mauris varius a. Morbi sit amet tortor ut augue luctus sollicitudin. Quisque ipsum quam, euismod et sodales vehicula, pretium nec leo. Maecenas condimentum enim ut viverra ornare. Nulla sit amet lobortis justo. Vestibulum at rhoncus metus, at aliquet justo. Morbi vulputate eros at dui aliquam, in interdum lectus hendrerit. Aenean tristique pharetra dolor sed aliquam. Donec quis purus a turpis auctor mollis. Ut sodales fermentum mauris, et consequat dui imperdiet ac. Nunc nec libero quis est molestie ultricies eget et nulla. Vivamus feugiat mauris ultricies mi suscipit, non tincidunt lacus imperdiet.',
  };

  const defaultProjectData: ProjectData = {
    name: '',
    description: '',
  };

  const defaultStoredState: StoredState = {
    step: Steps.NameProject,
    data: defaultProjectData,
  };

  const createProjectRequestCallback = async (data) => {
    getWorkflowRequest.run(data.workflowId);
  };

  const getWorkflowCallback = async (workflow) => {
    const workflowStatus = workflow.status;
    const tasksInProg = workflow.tasks.filter((task) => task.status === 'IN_PROGRESS');

    if (tasksInProg && tasksInProg.length > 0) {
      const tasks = workflow.tasks.map((task) => task.taskName);
      setTasksInProgress(tasks);
    }

    const isTaskInProgress = tasksInProg.length > 0;
    const tasksCompleted = workflow.tasks.filter((task) => task.status === 'COMPLETED');

    setCurrentUsage(tasksCompleted.length);

    console.log({ workflow, isTaskInProgress, tasksCompleted });

    console.log({ tasksCompleted: tasksCompleted.length, tasksInProg: workflow.tasks.length });
    if (tasksCompleted.length == workflow.tasks.length) {
      navigate(`/${workflow.inputs.project.id}/project/overview`);
      getAllProjectRequest.run();
      await AsyncStorage.remove('@projectData');
      hideModal();
    }

    if (workflowStatus === 'COMPLETED' && !isTaskInProgress) {
      setIsLoading(false);

      setCurrentStep(defaultStoredState.step);
      hideModal();
    } else if ((workflowStatus === 'COMPLETED' && isTaskInProgress) || workflowStatus === 'IN_PROGRESS') {
      setTimeout(() => {
        if (workflow.id) {
          getWorkflowRequest.run(workflow.id);
        }
      }, 3000);
    } else {
      //To handle Error
      hideModal();
    }
  };

  const {
    currentStep,
    setCurrentStep,
    projectData,
    setProjectData,
    isLoading,
    setIsLoading,
    currentUsage,
    setCurrentUsage,
    tasksInProgress,
    setTasksInProgress,
  } = useProjectStates();

  const { createProjectRequest, getWorkflowRequest, getAllProjectRequest } = useProjectRequests({
    createProjectRequestCallback,
    getWorkflowCallback,
  });

  const initialise = async () => {
    const initialState: any = (await AsyncStorage.readObject('@projectData')) || defaultStoredState;
    if (initialState && initialState.step && initialState.data) {
      setCurrentStep(initialState.step);
      setProjectData(initialState.data);
    }
  };

  useEffect(() => {
    initialise();
    // setTimeout(async () => {
    //   await AsyncStorage.remove('@projectData');
    // }, 86400000);
  }, []);

  const nextStep = async (data: any) => {
    const newData = { ...projectData, ...data };
    setProjectData(newData);
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);

    await AsyncStorage.writeObject('@projectData', { step: nextStep, data: newData });

    if (nextStep === Steps.Loading) {
      setIsLoading(true);
      await AsyncStorage.remove('@projectData');
      try {
        createProjectRequest.run({
          name: projectData.name,
          description: data.description,
        });
      } catch (e) {
        await AsyncStorage.remove('@projectData');
      }
    }
  };
  // AsyncStorage.remove('@projectData');
  const prevStep = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  };

  return (
    <Modal.Container boxShadow="none" width="100%" alignItems="center" justifyContent="center" isFullScreen>
      {hasProjects && currentStep !== Steps.Loading && (
        <AssetIcon
          name="BlackCloseSvg"
          cursor="pointer"
          width={24}
          height={24}
          color="black"
          position="absolute"
          zIndex={10000}
          right={50}
          top={30}
          onClick={() => hideModal()}
        />
      )}
      <Modal.Body width="fit-content" border="none">
        {!isLoading && <H3 marginBottom={50}>{titles[currentStep]}</H3>}
        {currentStep === Steps.NameProject && (
          <CreateProjectForm
            initialValues={{ name: projectData.name }}
            placeholder={placeholders.name}
            onNext={(data) => nextStep(data)}
          />
        )}
        {currentStep === Steps.DescribeProject && (
          <ConfigForm
            keyName="description"
            initialValues={{ description: projectData.description }}
            onNext={(data) => nextStep(data)}
            onPrev={prevStep}
            textAreaStyle={{ height: 400, width: '80vw' }}
            placeholder={placeholders.description}
          />
        )}
        {currentStep === Steps.Loading && isLoading && (
          <Loading title={titles[currentStep]} usage={currentUsage} totalUsage={6} tasksInProgress={tasksInProgress} />
        )}
      </Modal.Body>
    </Modal.Container>
  );
};

export default ProjectModal;
