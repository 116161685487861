import { NewsletterService } from 'src/services/api';

export const useNewsletterRequests = ({ createNewsletterRequestCallback }) => {
  const getAllNewsletterRequest = NewsletterService.useNewsletterControllerReadAllService({
    onSuccess: (data) => {
      console.log({ data });
    },
  });

  const createNewsletterRequest = NewsletterService.useNewsletterControllerGenerateService({
    onSuccess: () => {
      createNewsletterRequestCallback();
    },
  });

  return {
    getAllNewsletterRequest,
    createNewsletterRequest,
  };
};
