/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiBody } from '../models/AiBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * redirect to app
 * @param path Redirect url
 * @param userType User Type
 * @param language Languuage
 * @returns any Redirection done
 * @throws ApiError
 */
export const appControllerRedirect = (
  path: string,
  userType: string = 'user',
  language?: string
): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/redirect`,
    query: {
      path: path,
      userType: userType,
      language: language,
    },
  });
};

/**
 * @returns any
 * @throws ApiError
 */
export const appControllerRoot = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/`,
  });
};

/**
 * @param requestBody
 * @returns any
 * @throws ApiError
 */
export const appControllerAi = (requestBody: AiBody): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/ai`,
    body: requestBody,
    mediaType: 'application/json',
  });
};

/**
 * @returns any
 * @throws ApiError
 */
export const appControllerExit = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/exit`,
  });
};

export const useAppControllerRedirectService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (path: string, userType: string, language?: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(appControllerRedirect, { method, ...options });
};

export const useAppControllerRootService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(appControllerRoot, { method, ...options });
};

export const useAppControllerAiService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (requestBody: AiBody) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(appControllerAi, { method, ...options });
};

export const useAppControllerExitService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(appControllerExit, { method, ...options });
};
