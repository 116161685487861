/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMarketParams } from '../models/CreateMarketParams';
import type { CreateMarketSectionParams } from '../models/CreateMarketSectionParams';
import type { UpdateMarketParams } from '../models/UpdateMarketParams';
import type { UpdateMarketSectionParams } from '../models/UpdateMarketSectionParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a market
 * @param requestBody
 * @returns any Market created
 * @throws ApiError
 */
export const marketControllerCreateMarket = (requestBody: CreateMarketParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/market`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an market
 * @param id
 * @returns any Market data
 * @throws ApiError
 */
export const marketControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/market/${id}`,
    errors: {
      404: `Market does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a market
 * @param id
 * @returns any market delete
 * @throws ApiError
 */
export const marketControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/market/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Market does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a market
 * @param id
 * @param requestBody
 * @returns any Market Updated
 * @throws ApiError
 */
export const marketControllerUpdate = (id: string, requestBody: UpdateMarketParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/market/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a Section for a market
 * @param requestBody
 * @returns any MarketSection created
 * @throws ApiError
 */
export const marketSectionControllerCreate = (requestBody: CreateMarketSectionParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/market/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read a section of the market
 * @param id
 * @returns any MarketSection data
 * @throws ApiError
 */
export const marketSectionControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/market/section/${id}`,
    errors: {
      404: `MarketSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a section for a market
 * @param id
 * @returns any marketSection delete
 * @throws ApiError
 */
export const marketSectionControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/market/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `MarketSection does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a section of the market
 * @param id
 * @param requestBody
 * @returns any MarketSection Updated
 * @throws ApiError
 */
export const marketSectionControllerUpdate = (
  id: string,
  requestBody: UpdateMarketSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/market/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useMarketControllerCreateMarketService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateMarketParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketControllerCreateMarket, { method, ...options });
};

export const useMarketControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketControllerGet, { method, ...options });
};

export const useMarketControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketControllerDelete, { method, ...options });
};

export const useMarketControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateMarketParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketControllerUpdate, { method, ...options });
};

export const useMarketSectionControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateMarketSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerCreate, { method, ...options });
};

export const useMarketSectionControllerGetService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerGet, { method, ...options });
};

export const useMarketSectionControllerDeleteService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerDelete, { method, ...options });
};

export const useMarketSectionControllerUpdateService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateMarketSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(marketSectionControllerUpdate, { method, ...options });
};
