export const handleElementInteraction = {
  displayOverlay: (width, height, top, left) => {
    const overlay = document.getElementById('overlay-container');
    if (overlay) {
      Object.assign(overlay.style, {
        position: 'absolute',
        width: `${width}px`,
        height: `${height}px`,
        top: `${top}px`,
        left: `${left}px`,
        display: 'block',
        border: '2px dotted black',
        pointerEvents: 'none',
        zIndex: '1000',
      });
    }
  },
  displayPopUp: (top, left) => {
    const modalContainer = document.getElementById('modal-child');
    if (modalContainer) {
      Object.assign(modalContainer.style, {
        top: `${top + 2}px`,
        left: `${left + 2}px`,
        display: 'block',
      });
    }
  },
  hideElementById: (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none';
    }
  },
  handleElementClick: (setSelectedElement, element: HTMLElement) => {
    setSelectedElement(element);
    const { top, left } = element.getBoundingClientRect();
    handleElementInteraction.displayPopUp(top + window.scrollY, left + window.scrollX);
  },
  handleMouseEnter: (element: HTMLElement) => {
    const { width, height, top, left } = element.getBoundingClientRect();
    handleElementInteraction.displayOverlay(width, height, top, left);
  },
  handleMouseLeave: () => {
    handleElementInteraction.hideElementById('overlay-container');
  },
};

export const handleInspector = (isInspectorEnabled, setSelectedElement) => {
  const formContainer = document.getElementById('editor-container');
  if (!formContainer) return;

  const divElements = formContainer.querySelectorAll('*');
  const handleEnter = (event) => handleElementInteraction.handleMouseEnter(event.target);
  const handleLeave = () => handleElementInteraction.handleMouseLeave();
  const handleClick = (event) => handleElementInteraction.handleElementClick(setSelectedElement, event.target);

  if (isInspectorEnabled) {
    divElements.forEach((div) => {
      div.addEventListener('mouseenter', handleEnter);
      div.addEventListener('mouseleave', handleLeave);
      div.addEventListener('click', handleClick);
    });
  }

  return () => {
    divElements.forEach((div) => {
      div.removeEventListener('mouseenter', handleEnter);
      div.removeEventListener('mouseleave', handleLeave);
      div.removeEventListener('click', handleClick);
    });
  };
};
