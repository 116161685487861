import React from 'react';
import { Modal, Vertical } from '@app-studio/web';
import { AssetIcon } from 'src/utils/assets';
import { EditorForm } from 'src/forms/EditorForm';

interface EditorProps {
  styles?: any;
  description?: string;
  rightButtonText?: string;
  leftButtonText?: string;
  buttonSaveType?: string;
  isLoading?: boolean;
  isAIEditable?: boolean;
  sectionTitle?: string;
  sectionContent: string;
  initialValues: any;
  handleSubmit: (values: any, { setSubmitting }) => void;
  sectionTitleKey?: string;
  handleCancel: () => void;
  handleButtonSave?: () => void;
  onChangeCallback: (value: string) => void;
  [x: string]: any;
}
export const EditorModal = ({
  styles,
  description,
  isLoading,
  isAIEditable = true,
  buttonSaveType,
  sectionTitle,
  sectionContent,
  initialValues,
  handleSubmit,
  sectionTitleKey,
  handleCancel,
  handleButtonSave,
  onChangeCallback,
  rightButtonText,
  leftButtonText,
}: EditorProps) => (
  <Modal.Container padding={0} boxShadow="none" height={'100vh'} position="right" width={'80vw'}>
    <Modal.Body border="none" margin={0} height="100%" padding={0}>
      <Vertical padding="20px 40px" flex={1} backgroundColor="theme.secondary" gap={30} height="100%">
        <AssetIcon name="BlackCloseSvg" width={14} height={14} marginLeft="auto" onClick={handleCancel} />
        <EditorForm
          styles={styles}
          description={description}
          buttonSaveType={buttonSaveType}
          isLoading={isLoading}
          isAIEditable={isAIEditable}
          sectionTitle={sectionTitle}
          sectionTitleKey={sectionTitleKey}
          sectionContent={sectionContent}
          initialValues={initialValues}
          rightButtonText={rightButtonText}
          leftButtonText={leftButtonText}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleButtonSave={handleButtonSave}
          onChangeCallback={onChangeCallback}
        />
      </Vertical>
    </Modal.Body>
  </Modal.Container>
);
