/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateAssistantParams } from '../models/GenerateAssistantParams';
import type { GenerateJsonAssistantParams } from '../models/GenerateJsonAssistantParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to generate an intelli content
 * @param requestBody
 * @returns any Assistant created
 * @throws ApiError
 */
export const assistantControllerGenerateAiContent = (requestBody: GenerateAssistantParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/assistant/content`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to generate an intelli content
 * @param requestBody
 * @returns any Assistant created
 * @throws ApiError
 */
export const assistantControllerGenerateAiJsonContent = (
  requestBody: GenerateJsonAssistantParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/assistant/json`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to generate an intelli content
 * @param requestBody
 * @returns any Assistant created
 * @throws ApiError
 */
export const assistantControllerGenerateAiCode = (requestBody: GenerateAssistantParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/assistant/code`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useAssistantControllerGenerateAiContentService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: GenerateAssistantParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(assistantControllerGenerateAiContent, {
    method,
    ...options,
  });
};

export const useAssistantControllerGenerateAiJsonContentService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: GenerateJsonAssistantParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(assistantControllerGenerateAiJsonContent, {
    method,
    ...options,
  });
};

export const useAssistantControllerGenerateAiCodeService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (requestBody: GenerateAssistantParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(assistantControllerGenerateAiCode, { method, ...options });
};
