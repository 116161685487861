import React from 'react';
import AppProvider from 'src/providers/AppProvider';
import AppLayout from 'src/layouts/AppLayout';
import { RouterLayout } from 'src/layouts/RouterLayout';
import './styles.css';

const App = () => {
  return (
    <AppProvider>
      <RouterLayout />
    </AppProvider>
  );
};

export default App;
