import { useState } from 'react';

export const useGrantUploaderState = () => {
  const [droppedFile, setDroppedFile]: any = useState({});
  const [isDragging, setIsDragging] = useState(false);

  return {
    isDragging,
    setIsDragging,
    droppedFile,
    setDroppedFile,
  };
};
