import { hideModal } from '@app-studio/web';
import {
  AssistantService,
  ComponentRevisionService,
  ComponentService,
  LandingHistoryService,
  LandingService,
  WorkflowService,
} from 'src/services/api';

const useChatRequest = ({
  refreshCallback,
  getLandingCallback,
  getWorkflowCallback,
  generateContentCallback,
  closeEditorCallback,
}) => {
  const createRevisionRequest = ComponentRevisionService.useComponentRevisionControllerCreateService({
    onSuccess: (data) => {
      refreshCallback();
    },
  });
  const fixRevisionRequest = LandingService.useLandingControllerFixService({
    onSuccess: (data) => {
      refreshCallback();
    },
  });

  const getLandingRequest = LandingService.useLandingControllerReadService({
    onSuccess: (data) => {
      getLandingCallback(data);
    },
  });

  const getWorkflowRequest = WorkflowService.useWorkflowControllerGetService({
    onSuccess: (data) => {
      getWorkflowCallback(data);
    },
  });

  const updateLandingRequest = LandingService.useLandingControllerUpdateService({});

  const getAllComponentRequest = ComponentService.useComponentControllerReadAllService({});

  const updateTaskRequest = WorkflowService.useWorkflowControllerUpdateTaskStatusService({
    onSuccess: (data) => {
      refreshCallback();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiCodeService({
    onSuccess: (data) => {
      generateContentCallback(data);
    },
  });

  const createLandingHistory = LandingHistoryService.useLandingHistoryControllerCreateService({});

  const deleteComponentRequest = ComponentService.useComponentControllerDeleteService({});

  const resetTaskRequest = WorkflowService.useWorkflowControllerResetTaskService({
    onSuccess: (data) => {
      refreshCallback();
    },
  });

  const createComponentTaskRequest = LandingService.useLandingControllerCreateComponentTaskService({
    onSuccess: (data) => {
      refreshCallback();
      hideModal();
    },
  });

  const updateComponentRequest = ComponentService.useComponentControllerUpdateService({
    onSuccess: (data) => {
      refreshCallback();
      console.log({ data });
    },
    onError: () => {
      console.log('Error updating component');
    },
  });

  const refreshImageRequest = ComponentService.useComponentControllerRefreshImageService({
    onSuccess: () => {
      refreshCallback();
      closeEditorCallback();
    },
  });

  const updatePropsRequest = ComponentService.useComponentControllerUpdatePropsService({
    onSuccess: (data) => {
      refreshCallback();
      closeEditorCallback();
    },
  });

  return {
    createRevisionRequest,
    fixRevisionRequest,
    getLandingRequest,
    getWorkflowRequest,
    updateLandingRequest,
    getAllComponentRequest,
    updateTaskRequest,
    generateContentRequest,
    createLandingHistory,
    deleteComponentRequest,
    resetTaskRequest,
    createComponentTaskRequest,
    updateComponentRequest,
    refreshImageRequest,
    updatePropsRequest,
  };
};

export default useChatRequest;
