import { useParams } from 'react-router-dom';
import { GrantService } from 'src/services/api';

export const useDocumentRequests = ({ handleEditorCallback }) => {
  const { id } = useParams();

  const getGrantRequest = GrantService.useGrantControllerRetrieveGrantService({});

  const createSectionRequest = GrantService.useSectionControllerCreateSectionService({
    onSuccess: () => {
      getGrantRequest.run(id);
      handleEditorCallback();
    },
  });
  const updateSectionRequest = GrantService.useSectionControllerUpdateSectionService({
    onSuccess: () => {
      getGrantRequest.run(id);
      handleEditorCallback();
    },
  });

  return {
    getGrantRequest,
    updateSectionRequest,
    createSectionRequest,
  };
};
