/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectSupplementaryInfoParams } from '../models/CreateProjectSupplementaryInfoParams';
import type { UpdateProjectSupplementaryInfoParams } from '../models/UpdateProjectSupplementaryInfoParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As an admin, I want to create project supplementary info
 * @param requestBody
 * @returns any Project supplementary info created
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerCreate = (
  requestBody: CreateProjectSupplementaryInfoParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/projecSupplementaryInfo`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read project supplementary info by projectId
 * @param id
 * @returns any Project supplementary info data
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/projecSupplementaryInfo/${id}`,
    errors: {
      404: `Project supplementary info does not exist`,
    },
  });
};

/**
 * As an admin, I want to update project supplementary info
 * @param id
 * @param requestBody
 * @returns any Project supplementary info updated
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerUpdate = (
  id: string,
  requestBody: UpdateProjectSupplementaryInfoParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/projecSupplementaryInfo/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to delete project supplementary info
 * @param id
 * @returns any Project supplementary info deleted
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/projecSupplementaryInfo/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Project supplementary info does not exist`,
    },
  });
};

export const useProjectSupplementaryInfoControllerCreateService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: CreateProjectSupplementaryInfoParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerCreate, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerGetService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerGet, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerUpdateService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProjectSupplementaryInfoParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerUpdate, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerDeleteService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerDelete, {
    method,
    ...options,
  });
};
