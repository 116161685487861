import i18n from 'src/utils/locale';

export function formatCode(code) {
  // Remove imports
  let cleanedCode = code.replace(/import .*?;/g, '');

  // Remove everything from 'export default' onward
  cleanedCode = cleanedCode.substring(0, cleanedCode.lastIndexOf('export default'));

  return cleanedCode.trim().replace('javascript', '').replace('javascript;', ''); // Trim to remove any leading or trailing whitespace
}

export const generateLandingCode = async (sections, components, tasks, selectedIndex, selectedMode) => {
  // Map each section to its corresponding component name

  if (!sections || !components || !tasks) return;
  const componentNames = sections.map((id) => (components[id] ? `${components[id].name}Section` : null));

  let componentsCode = {};

  // Generate code for each component that matches a section
  for (const id of sections) {
    const component = components[id];
    if (component && componentNames.includes(`${component.name}Section`)) {
      componentsCode[`${component.name}Section`] = `${component.interface}\n${formatCode(component.code)}`;
    }
  }

  // Concatenate the code for all components
  const code = componentNames
    .map((componentName) => {
      if (componentsCode[componentName]) return componentsCode[componentName];
    })
    .join('\n\n');

  // Generate JSX for each component based on its visibility and content props
  let componentsJSX = sections
    .map((id, componentIndex) => {
      let content = '';

      const component = components[id];
      const task = tasks[id];

      if (!task || !component) return;
      const isVisible = component.isVisible;
      const componentName = `${component.name}Section`;

      const isError = task && task.status === 'ERROR' && task.nbOfExecution < 2;
      const isDenied = task && task.status === 'ERROR' && task.nbOfExecution >= 2;
      const isLoading =
        task?.id === undefined ||
        task?.status === undefined ||
        task?.status === 'IN_PROGRESS' ||
        task?.status === 'PENDING';
      const isAvailable =
        !isLoading &&
        component &&
        (component.textProps || component.imageProps || component.interactionProps) &&
        task.status !== 'ERROR';

      const display = !!isVisible ? 'block' : 'none';

      const ref = `ref={(el) => (sectionRefs.current[${componentIndex}] = el)}`;

      const divStyle = `style={{ display: '${display}', height: "${isError || isDenied ? '70vh' : 'auto'}" }}`;

      if (isAvailable) {
        const textProps = JSON.stringify(component.textProps) || '{}';
        const imageProps = JSON.stringify(component.imageProps) || '{}';

        const interactionProps = JSON.stringify(component.interactionProps) || '{}';
        const designProps =
          JSON.stringify({ ...component?.designProps, containerProps: component?.designProps?.containerProps ?? {} }) ||
          '{}';

        content = `<${componentName}
        mode={"${selectedMode.text}"}
        key={"component-${id}"}
        textProps={${textProps}}
        imageProps={${imageProps}}
        designProps={${designProps}}
        interactionProps={${interactionProps}}
        handlePropsSubmit={(props)=> window.handlePropsSubmit(props)}
        handlePropsSelection={(props)=> window.handlePropsSelection({...props, id:"${id}"})}/>`;
      } else if (isLoading) {
        const skeletonComponent = `${component.name}Skeleton`;

        content = skeletonComponent ? `<${skeletonComponent}  key={"skeleton-${id}"}  />` : '';
      } else if (isError) {
        content = `<FailedSection
          name={"${id}"}
          key={"failed-${id}"}
          // sections={${Array.isArray(sections) && sections.length ? sections : []}}
          handleNo={()=> window.handleSectionDelete(${componentIndex})}
          handleYes={()=> window.handleTaskRetry("${sections[componentIndex].taskId}")}
          headlineText={"${i18n.t('component.failedSection.headline')}"}
          subheadlineText={"${i18n.t('component.failedSection.subheadline')}"}
          deleteCTAText={"${i18n.t('action.no')}"}
          retryCTAText={"${i18n.t('action.yes')}"}
        />`;
      } else if (isDenied) {
        content = `<CreationDeniedSection
          name={"${id}"}
          key={"denied-${id}"}
          handleDelete={()=> window.handleSectionDelete(${componentIndex})}
          headlineText={"${i18n.t('component.denied.headline')}"}
          buttonText={"${i18n.t('action.delete')}"}
        />`;
      }

      return `<div key={"container-${componentIndex}"} ${ref} ${divStyle}>${content}</div>`;
    })
    .join('\n');

  // Landing page component template
  let landingPageComponent = `
      import React, { useState, useEffect, useRef } from 'react';
      import { Link } from 'react-router-dom';
      import { Text, ViewProps, TextProps, ImageProps, useResponsive } from 'app-studio';
      import { Vertical, Horizontal, Center, Button, View, Table } from '@app-studio/web';
      import Icon from 'react-web-vector-icons';

      interface SkeletonTextProps extends ViewProps {
        textLines?: number;
        height?: number | string;
        width?: number | string;
        backgroundColor?: string;
      }
      interface SkeletonImageProps extends ViewProps {
        height?: number | string;
        width?: number | string;
        borderRadius?: number | string;
        containerWidth?: number | string;
      }

      const ImageSkeleton = ({
        height = 246,
        width = 336,
        borderRadius = 10,
        containerWidth = '50%',
        ...props
      }: SkeletonImageProps) => {
        return (
          <Center width={containerWidth}>
            <View height={height} width={width} borderRadius={borderRadius} backgroundColor="#D9D9D9" {...props} />
          </Center>
        );
      };

      const TextSkeletons = ({
        textLines = 2,
        height = 40,
        width = '100%',
        backgroundColor = '#D9D9D9',
        ...props
      }: SkeletonTextProps) => {
        const linesArray = Array.from({ length: textLines }, (_, index) => index + 1);

        return (
          <Vertical width="100%" gap={10} height="fit-content" alignItems="center" {...props}>
            {linesArray.map((lineNumber) => (
              <View key={lineNumber} width={width} height={height} backgroundColor={backgroundColor} borderRadius={10} />
            ))}
          </Vertical>
        );
      };

      export const HeroSkeleton = () => {
        const containerResponsiveProps: ViewProps = {
          backgroundColor: 'white',
          width: '100%',
          height: '70vh',
          alignItems: 'center',
          gap: 30,
          media: {
            mobile: {
              padding: '40px 40px',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            tablet: {
              padding: '30px 60px',
            },
            desktop: {
              padding: '40px 100px',
            },
          },
        };
        return (
          <Horizontal {...containerResponsiveProps}>
            <Horizontal width="100%">
              <Vertical width="50%" gap={20}>
                <TextSkeletons textLines={1} height={80} />
                <TextSkeletons textLines={2} height={20} />
              </Vertical>
              <ImageSkeleton />
            </Horizontal>
          </Horizontal>
        );
      };

      export const CtaSkeleton = () => {
        const containerResponsiveProps: any = {
          justifyContent: 'center',
          alignItems: 'center',
          media: {
            mobile: {
              padding: '40px 40px',
            },
            tablet: {
              padding: '40px 60px',
            },
            desktop: {
              padding: '40px 150px',
            },
          },
        };

        const contentResponsiveProps: any = {
          width: '100%',
          borderRadius: 25,
          alignItems: 'center',
          backgroundColor: '#D9D9D9',
          media: {
            mobile: {
              padding: '40px 30px',
              minHeight: '20vh',
              flexDirection: 'column',
            },
            tablet: {
              padding: '60px 50px',
              minHeight: '25vh',
            },
            desktop: {
              padding: '80px',
              minHeight: '30vh',
            },
          },
        };

        return (
          <Horizontal role="cta" {...containerResponsiveProps}>
            <Horizontal {...contentResponsiveProps}>
              <Vertical width="100%" gap={20}>
                <TextSkeletons textLines={1} height={80} backgroundColor="white" width="95%" />
                <TextSkeletons textLines={1} height={20} backgroundColor="white" width="60%" />
                <TextSkeletons textLines={1} height={50} backgroundColor="white" marginTop={30} />
              </Vertical>
            </Horizontal>
          </Horizontal>
        );
      };

      export const FeaturesSkeleton = ({}) => {
        const features = [1, 2, 3, 4];

        const containerResponsiveProps: any = {
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          media: {
            mobile: {
              padding: '0px 40px',
              flexDirection: 'column',
              gap: 30,
              height: '100%',
            },
            tablet: {
              padding: '40px 50px',
              gap: 40,
            },
            desktop: {
              padding: '100px 100px',
              gap: 130,
            },
          },
        };

        const contentContainerResponsiveProps: any = {
          gap: 10,
          alignItems: 'center',
          justifyContent: 'center',
        };

        const featuresContainerResponsiveProps: ViewProps = {
          gap: 5,
          // flexWrap: 'wrap',
          alignItems: 'center',
          width: '50%',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
        };

        const featureContainerResponsiveProps: ViewProps = {
          flex: '300px',
          padding: '10px' /* Optional padding */,
          margin: '30px 0 0 ',
          gap: 5,
        };

        return (
          <Horizontal {...containerResponsiveProps}>
            <Vertical flex={1} {...contentContainerResponsiveProps}>
              <TextSkeletons textLines={1} height={80} width="80%" />
              <TextSkeletons textLines={1} height={20} width="50%" />
              <Horizontal {...featuresContainerResponsiveProps}>
                {features?.length > 0 &&
                  features.map(() => (
                    <Vertical {...featureContainerResponsiveProps}>
                      <ImageSkeleton width={24} height={24} borderRadius={2} justifyContent="flex-start" />
                      <TextSkeletons textLines={1} height={60} />
                      <TextSkeletons textLines={2} height={10} />
                    </Vertical>
                  ))}
              </Horizontal>
            </Vertical>
          </Horizontal>
        );
      };

      export const SocialSkeleton = () => {
        const reviews = [1, 2, 3];

        const containerResponsiveProps: ViewProps = {
          backgroundColor: 'white',
          width: '100%',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          media: {
            mobile: {
              padding: '40px 40px',
              gap: 10,
            },
            tablet: {
              padding: '40px 60px',
              gap: 18,
            },
            desktop: {
              padding: '40px 100px',
              gap: 20,
            },
          },
        };

        const reviewsSliderContainerResponsiveProps: any = {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '80%',
          margin: '0 auto',
          overflow: 'hidden',
          media: {
            mobile: {
              width: '90%',
            },
            tablet: {
              width: '80%',
            },
            desktop: {
              width: '80%',
            },
          },
        };

        const profileContainerProps: any = {
          alignItems: 'center',
          wrap: 'nowrap',
          media: {
            mobile: {
              gap: 7,
            },
            tablet: {
              gap: 10,
            },
            desktop: {
              gap: 15,
            },
          },
        };

        const reviewContainerResponsiveProps: any = {
          flex: '0 0 auto',
          boxSizing: 'border-box',
          padding: '10px',
          borderRadius: 10,
          border: '1px solid #E7E7EF',
          justifyContent: 'space-between',
          boxShadow: '0px 4px 8px rgba(0,0,0,0.05)',
          backgroundColor: 'rgba(255,255,255,0.5)',
          height: '100%',
          media: {
            mobile: {
              gap: 8,
              padding: '20px 26px',
              minHeight: '180px',
              minWidth: '180px',
              width: '70%',
            },
            tablet: {
              gap: 10,
              padding: '28px',
              minHeight: '200px',
              minWidth: '200px',
              width: '35%',
            },
            desktop: {
              gap: 15,
              padding: '30px',
              minHeight: '250px',
              minWidth: '250px',
              width: '25%',
            },
          },
        };

        return (
          <Vertical role="social" {...containerResponsiveProps}>
            <Vertical width="100%">
              <TextSkeletons textLines={1} height={80} width="80%" />
              <Center gap={50} margin="20px 0">
                <ImageSkeleton width={50} height={50} borderRadius="50%" containerWidth="fit-content" />
                <ImageSkeleton width={50} height={50} borderRadius="50%" containerWidth="fit-content" />
              </Center>
            </Vertical>
            <View {...reviewsSliderContainerResponsiveProps}>
              <Center width="100%" gap={15}>
                {reviews.map((_, index) => (
                  <Vertical key={index} {...reviewContainerResponsiveProps}>
                    <TextSkeletons textLines={5} height={10} width="80%" />
                    <Horizontal {...profileContainerProps}>
                      <ImageSkeleton width={40} height={40} borderRadius="50%" containerWidth="fit-content" />
                      <Vertical width="100%" gap={10}>
                        <TextSkeletons textLines={1} height={10} />
                        <TextSkeletons textLines={1} height={10} />
                      </Vertical>
                    </Horizontal>
                  </Vertical>
                ))}
              </Center>
            </View>
          </Vertical>
        );
      };

      export const FooterSkeleton = () => {
        return (
          <Vertical backgroundColor="#D9D9D9" height="300px" padding="60px 100px" width="100%" gap={30}>
            <Horizontal width="100%">
              <Vertical width="100%" gap={20}>
                <Horizontal width="100%" gap={10}>
                  <ImageSkeleton
                    width={50}
                    height={50}
                    borderRadius="50%"
                    backgroundColor="white"
                    containerWidth="fit-content"
                  />
                  <TextSkeletons textLines={1} height={50} backgroundColor="white" />
                </Horizontal>
                <TextSkeletons textLines={1} height={20} backgroundColor="white" />
                <Horizontal width="100%" marginTop="auto" gap={20}>
                  {[1, 2, 3].map((_, index) => (
                    <ImageSkeleton
                      key={index}
                      width={30}
                      height={30}
                      borderRadius="10px"
                      backgroundColor="white"
                      containerWidth="fit-content"
                    />
                  ))}
                </Horizontal>
              </Vertical>
              <Vertical width="100%" gap={30}>
                <TextSkeletons textLines={1} height={20} backgroundColor="white" width="60%" />
                <TextSkeletons textLines={4} height={10} backgroundColor="white" width="60%" />
              </Vertical>
              <Vertical width="100%" gap={30}>
                <TextSkeletons textLines={1} height={20} backgroundColor="white" width="60%" />
                <TextSkeletons textLines={3} height={10} backgroundColor="white" width="60%" />
              </Vertical>
            </Horizontal>
            <TextSkeletons textLines={1} height={2} backgroundColor="white" width="100%" />

            <TextSkeletons textLines={1} height={10} backgroundColor="white" width="50%" />
          </Vertical>
        );
      };

      export const FailedSection = ({
        name,
        handleNo,
        handleYes,
        headlineText,
        subheadlineText,
        deleteCTAText,
        retryCTAText,
        taskId
      }: {
        name: string;
        headlineText: string;
        subheadlineText: string;
        deleteCTAText: string;
        retryCTAText: string;
        handleNo: Function;
        handleYes: Function;
        taskId?: string;
      }) => {
        const headlineTypo = {
          fontSize: '19px',
          fontWeight: 400,
          lineHeight: '22px',
        };
        const subHeadlineTypo = {
          fontSize: '27px',
          fontWeight: 400,
          lineHeight: '22px',
        };

        const buttonTypo = {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
        };

        return (
          <Vertical gap={20} width="100%" height="100%" justifyContent="center" alignItems="center">
            <Text {...headlineTypo}>
              {headlineText} : {name}
            </Text>
            <Text {...subHeadlineTypo}>{subheadlineText}</Text>
            <Horizontal gap={50} marginTop={30}>
              <Button id={"no-${selectedIndex}"} padding="10px 40px" onClick={() => handleNo()} isAuto>
                <Text {...buttonTypo}>{deleteCTAText}</Text>
              </Button>
              <Button id={"yes-${selectedIndex}"} padding="10px 40px" onClick={() => handleYes()} isAuto>
                <Text {...buttonTypo}> {retryCTAText}</Text>
              </Button>
            </Horizontal>
          </Vertical>
        );
      };

      export const CreationDeniedSection = ({
        name,
        handleDelete,
        buttonText,
        headlineText,
        taskId,
      }: {
        name: string;
        taskId?: string;
        handleDelete: Function;
        buttonText: string;
        headlineText: string;
      }) => {
        const headlineTypo = {
          fontSize: '19px',
          fontWeight: 400,
          lineHeight: '22px',
        };
        const buttonTypo = {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
        };
        return (
          <Vertical gap={30} width="100%" height="100%" justifyContent="center" alignItems="center">
            <Text {...headlineTypo}>
              {headlineText} : {name}
            </Text>
            <Button padding="10px 40px" onClick={()=>handleDelete(taskId)} isAuto>
              <Text {...buttonTypo}>{buttonText}</Text>
            </Button>
          </Vertical>
        );
      };

      ${code}

      // Container styles for the landing page
      const landingContainer: ViewProps = {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      };

      // Landing page component
      const LandingPage = () => {
        const sectionRefs = useRef([]);



        useEffect(() => {
          // Set global sectionRefs
          window.sectionRefs = sectionRefs;
          window.scrollToSection = scrollToSection;
          scrollToSection(${selectedIndex})
        }, [sectionRefs]);

        const scrollToSection = (index: number) => {
          if (index >= 0 && sectionRefs?.current) {
            const section: any = sectionRefs.current[index];
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
            }
          }
        };

        return (
          <View {...landingContainer}>

            ${componentsJSX}
          </View>
        );
      };

      export default LandingPage;
      `;

  return landingPageComponent;
};
