import { useParams } from 'react-router-dom';
import { AssistantService, BrandService, SectionService } from 'src/services/api';

export const useBrandsRequests = ({ handleEditorCallback, editorCallback }) => {
  const { projectId } = useParams();

  const getBrandRequest = BrandService.useBrandControllerGetService({});

  const createBrandRequest = BrandService.useBrandControllerCreateService({
    onSuccess: (data) => {
      getBrandRequest.run(data.projectId);
    },
  });

  const updateSectionRequest = SectionService.useSectionControllerUpdateService({
    onSuccess: () => {
      getBrandRequest.run(projectId);
      handleEditorCallback();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getBrandRequest,
    updateSectionRequest,
    generateContentRequest,
    createBrandRequest,
  };
};
