import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import { Button, FormikSelect, Select, Vertical } from '@app-studio/web';
import { FileSVG, FormikPassword, FormikTextField, FormikForm } from '@app-studio/web';
import * as Yup from 'yup';

import { Center } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import { Label } from 'src/components/Label';
import { FormItem } from 'src/components/Form';
import { AuthService } from 'src/services/api';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(i18n.t('form.email.required')),
  password: Yup.string().required(i18n.t('form.password.required')).min(4, i18n.t('form.password.short')),
  name: Yup.string().required(i18n.t('form.name.required')),
});

const RegisterForm = (FormProps: any) => {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const { loading, run } = AuthService.useAuthUserControllerSignUpService({
    onSuccess: (data, params) => {
      login(
        {
          email: params[0].email,
          password: params[0].password,
        },
        () => navigate('/')
      );
    },
  });
  return (
    <Formik
      onSubmit={(values) => {
        run(values);
      }}
      initialValues={{
        email: '',
        password: '',
        name: '',
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, handleSubmit, isSubmitting, values, handleChange }) => {
        return (
          <FormikForm {...FormProps}>
            <Vertical gap={10}>
              <Label>{i18n.t('form.name.label')}</Label>
              <FormikTextField
                placeholder={i18n.t('form.name.placeholder')}
                name="name"
                type="text"
                shape={'rounded'}
                variant={'outline'}
              />
              <Label>{i18n.t('form.email.label')}</Label>
              <FormikTextField
                placeholder={i18n.t('form.email.placeholder')}
                name="email"
                type="email"
                shape={'rounded'}
                variant={'outline'}
              />

              <Label>{i18n.t('form.password.label')}</Label>
              <FormikPassword
                placeholder={i18n.t('form.password.placeholder')}
                name="password"
                shape={'rounded'}
                variant={'outline'}
              />
              <Center marginVertical={20}>
                <Button isLoading={loading} type="submit" width="100%" onClick={handleSubmit} isDisabled={loading}>
                  {i18n.t('button.register')}
                </Button>
              </Center>
            </Vertical>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
