import React, { useEffect, useState } from 'react';
import { Vertical } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { Header } from 'src/components/Header';
import { useProjectConfigStates } from './projectConfig.state';
import { useProjectConfigRequests } from './projectConfig.request';
import { useMount } from 'app-studio';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorForm } from 'src/forms/EditorForm';

const ProjectConfigPage = () => {
  const defaultDescription =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate, augue ut aliquet ultrices, orci magna ornare urna, eget interdum ex enim in purus. Nam volutpat eros ligula, eu tempor mauris varius a. Morbi sit amet tortor ut augue luctus sollicitudin. Quisque ipsum quam, euismod et sodales vehicula, pretium nec leo. Maecenas condimentum enim ut viverra ornare. Nulla sit amet lobortis justo. Vestibulum at rhoncus metus';

  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedSection, setSelectedSection } = useProjectConfigStates();
  const [position, setPosition] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [formKey, setFormKey] = useState(0); // Unique key for re-rendering

  useMount(() => {
    getProjectRequest.run(id);
  });

  const updateSectionCallback = (data) => {
    if (getProjectRequest && getProjectRequest.data && getProjectRequest.data.projectSections) {
      if (position >= 0 && position < 2) {
        setPosition((prevPosition) => prevPosition + 1);
      }

      if (position === 1) {
        navigate(`/project/${data.projectId}`);
      }
    }
  };

  const getProjectCallback = (data) => {
    setSelectedSection(data.projectSections[0]);
  };

  const generateContentCallback = (content: string) => {
    setFormKey((prevKey) => prevKey + 1);
    console.log({ content });
    setCurrentAnswer(content);
  };

  const onChangeCallback = (editedContent: string) => {
    setCurrentAnswer(editedContent);
  };

  const projectDescripRequests = useProjectConfigRequests({
    updateSectionCallback,
    generateContentCallback,
    getProjectCallback,
  });
  const { getProjectRequest, updateSectionRequest, generateContentRequest } = projectDescripRequests;

  useEffect(() => {
    if (getProjectRequest && getProjectRequest.data && getProjectRequest.data.projectSections) {
      setSelectedSection(getProjectRequest.data.projectSections[position]);
      setCurrentAnswer(getProjectRequest.data.projectSections[position].content);
    }
    setCurrentAnswer('');
    setFormKey((prevKey) => prevKey + 1);
  }, [position]);

  const handleButtonSave = () => {
    updateSectionRequest.run(selectedSection.id, { content: currentAnswer });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { prompt } = values;
    generateContentRequest.run({ prompt, content: currentAnswer });
    setSubmitting(false);
  };

  const handleClose = () => {
    if (position > 0) {
      setPosition((prevPosition) => prevPosition - 1);
    }
  };

  const getDescription = (position) => {
    return !!getProjectRequest?.data.projectSections[position].content
      ? getProjectRequest?.data.projectSections[position].content
      : defaultDescription;
  };
  return (
    <Vertical width="100%" padding="60px 60px" backgroundColor="theme.secondary" height={'100%'}>
      <Header title={i18n.t('page.projectConfig.title')} paddingBottom={30} />
      <Vertical padding="20px 0" flex={1} gap={30} height="100%">
        {!getProjectRequest.loader && getProjectRequest.data && getProjectRequest.data.projectSections && (
          <EditorForm
            key={formKey}
            isLoading={false}
            isAIEditable={true}
            description={getDescription(position)}
            sectionTitle={selectedSection.title}
            sectionContent={currentAnswer}
            initialValues={{ prompt: '', name: selectedSection.title }}
            handleSubmit={handleSubmit}
            handleCancel={handleClose}
            handleButtonSave={handleButtonSave}
            onChangeCallback={onChangeCallback}
            rightButtonText={i18n.t('action.next')}
            leftButtonText={i18n.t('action.prev')}
            //   buttonIsDisabled={!currentAnswer}
            styles={{
              editor: { minHeight: '300px !important', maxHeight: '350px !important', marginBottom: 30 },
              aiForm: { borderRadius: 36 },
              aiContainer: { width: '80%', alignSelf: 'center' },
              buttons: { width: '100%', justifyContent: 'center', alignItems: 'center', gap: 50 },
              button: {
                width: 150,
                height: 45,
                borderRadius: 12,
              },
            }}
          />
        )}
      </Vertical>
    </Vertical>
  );
};

export default ProjectConfigPage;
