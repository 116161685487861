import { Center, View, ViewProps } from '@app-studio/web';
import type { SvgNameProp } from 'src/types/svg';
import IconList from 'src/assets/svg';
import { useTheme } from 'app-studio';
interface IconProps {
  name: SvgNameProp;
  color?: string;
  size?: number;
}

export const SVGIcon = ({ name, size = 20, color = 'black', ...props }: IconProps & ViewProps) => {
  if (IconList[name] === undefined) {
    console.warn(`icon : ${name} doesn't exist`);
    return null;
  }
  const { getColor } = useTheme();
  const Icon = IconList[name];

  const fill = getColor(color);

  return (
    <Center {...props} size={size}>
      <Icon fill={typeof fill === 'string' ? fill : 'black'} />
    </Center>
  );
};
