import { ProjectMediaService, ProjectService, UserService } from 'src/services/api';
import { useAppStore } from 'src/stores/AppStore';
import { useNavLayoutState } from './NavLayout.state';
import { useEffect } from 'react';
import { useMount } from 'app-studio';

export const useNavLayoutRequests = () => {
  const { setProjects } = useAppStore();

  useMount(() => {
    getAllProjectRequest.run();
  });

  const createProjectRequest = ProjectService.useProjectControllerGenerateService({
    onSuccess: (data) => {
      getAllProjectRequest.run();
    },
  });

  const getAllProjectRequest = ProjectService.useProjectControllerListAllProjectsService({
    onSuccess: (data) => {
      setProjects(data);
    },
  });

  return {
    createProjectRequest,
    getAllProjectRequest,
  };
};
