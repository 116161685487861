import React from 'react';
//import i18n from 'src/utils/locale';
import { Horizontal, View, Center, Text, Link, FileSVG } from '@app-studio/web';
import { useLocation } from 'react-router-dom';
import { useAuthStore } from 'src/stores/AuthStore';
import { C2 } from 'src/components/Text';

export const HeaderLayout = () => {
  const location = useLocation();

  const { isAuthentificated, user } = useAuthStore(({ isAuthentificated, user }) => ({
    isAuthentificated,
    user,
  }));

  // const toShow = ['/'];

  // if (!toShow.includes(location.pathname)) return null;

  return (
    <HeaderContainer>
      <Center gap={30}>
        <Link href="/">
          <FileSVG src="/assets/logo.svg" />
        </Link>
        {/* <Link href="/docs">
          <C2>Docs</C2>
        </Link> */}
      </Center>
      <View width="60vw"></View>
      <Center verticalAlign={'center'}>
        {isAuthentificated && user ? <Link href="/account">{user.name}</Link> : <Link href="/auth/login">Login</Link>}
      </Center>
    </HeaderContainer>
  );
};

const HeaderContainer = (props) => (
  <Horizontal
    boxSizing={'border-box'}
    gap={8}
    alignItems={'center'}
    justifyContent={'space-between'}
    height={100}
    paddingHorizontal={20}
    paddingVertical={10}
    media={{
      mobile: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    }}
    width="100vw"
    flexWrap="nowrap"
    background={'rgba(255, 255, 255, 1)'}
    borderBottom={'1px solid #ededee'}
    {...props}
    {...props}
  />
);
