import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';
import { H3 } from 'src/components/Text';
import { CreateButton } from 'src/components/Button';
import { Table } from 'src/components/Table';

import { useApplicationRequests } from './application.request';
import { useAppStore } from 'src/stores/AppStore';
import { request } from 'http';

const ApplicationPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { project } = useAppStore();
  const headers: any = i18n.t('page.tableHeader');
  const applicationRequests = useApplicationRequests();

  const {
    getAllApplicationRequest,
    createApplicationRequest,
    getAllProjectRequest,
    getAllGrantRequest,
    deleteApplicationRequest,
  } = applicationRequests;

  useMount(() => {
    getAllApplicationRequest.run();
    getAllProjectRequest.run();
    getAllGrantRequest.run(projectId);
  });

  const isLoaded =
    !getAllApplicationRequest.loader &&
    !getAllProjectRequest.loader &&
    !getAllGrantRequest.loader &&
    !createApplicationRequest.loader &&
    getAllProjectRequest &&
    getAllProjectRequest.data &&
    getAllGrantRequest &&
    getAllGrantRequest.data &&
    getAllApplicationRequest &&
    getAllApplicationRequest.data;

  const grantOptions = getAllGrantRequest?.data?.map((grant) => ({ label: grant.name, value: grant.id })) ?? [];

  const fields = [
    {
      name: 'grant',
      label: i18n.t('page.application.labelGrant'),
      options: grantOptions,
    },
  ];

  const initialValues = {
    grant:
      getAllGrantRequest && getAllGrantRequest.data && getAllGrantRequest.data[0] ? getAllGrantRequest?.data[0].id : '',
  };

  const createApplicationCallback = ({ grant }) => {
    createApplicationRequest.run({ name: `${grant.name}-${project.name}`, projectId: project.id, grantId: grant.id });
    hideModal();
  };

  const navigateTo = (id: string) => {
    navigate(`/${projectId}/fund/grant/application/files/${id}`);
  };

  const handleSubmit = (event: any) => {
    if (isLoaded) {
      createApplicationCallback({
        grant: getAllGrantRequest.data.find((grant) => grant.id === event.grant),
      });
    }
  };

  const handleButtonClick = () => {
    // Use a unique key to force remount
    if (getAllGrantRequest && getAllGrantRequest.data && getAllProjectRequest && getAllProjectRequest.data) {
      showModal(
        'CreateApplicationModal',
        {
          key: new Date().getTime(),
          initialValues: initialValues,
          fields: fields,
          handleSubmit: handleSubmit,
          handleCancel: hideModal,
          request: createApplicationRequest,
        },
        { position: 'right' }
      );
    }
  };
  const buttonCallback = (id: string) => {
    deleteApplicationRequest.run(id);
  };

  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <H3>{i18n.t('page.application.application')}</H3>
      <CreateButton text={i18n.t('button.create')} handleButtonClick={handleButtonClick} />
      {isLoaded ? (
        <Table
          headers={headers}
          menu={getAllApplicationRequest.data}
          navigateTo={navigateTo}
          buttonCallback={buttonCallback}
        />
      ) : (
        <Center width={'100%'} height={'100%'}>
          <Loader />
        </Center>
      )}
    </Vertical>
  );
};
export default ApplicationPage;
