import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import { Button, Vertical } from '@app-studio/web';
import { FileSVG, FormikPassword, FormikTextField, FormikForm } from '@app-studio/web';
import * as Yup from 'yup';
import { isDev } from 'src/utils/env';

import { AuthService } from 'src/services/api';
import { Center } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import { Label } from 'src/components/Label';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(i18n.t('form.email.required')),

  password: Yup.string().required(i18n.t('form.password.required')).min(4, i18n.t('form.password.short')),
});

const LoginForm = () => {
  const { setToken } = useAuthStore();
  const navigate = useNavigate();

  const { loading, run } = AuthService.useAuthUserControllerSignInService({
    onSuccess: ({ token, me }) => {
      if (token) {
        setToken(token, me, () => navigate('/'));
      }
    },
  });
  return (
    <Formik
      onSubmit={(values) => {
        run(values);
      }}
      validationSchema={validationSchema}
      initialValues={
        isDev()
          ? {
              email: 'test@studiolabs.io',
              password: 'studiolabs',
            }
          : { email: '', password: '' }
      }
    >
      {({ handleSubmit }) => {
        return (
          <FormikForm>
            <Vertical gap={10}>
              <Label>{i18n.t('form.email.label')}</Label>
              <FormikTextField
                placeholder={i18n.t('form.email.placeholder')}
                name="email"
                type="email"
                shape={'rounded'}
                variant={'outline'}
                rightIcon={<FileSVG src={'/assets/email.svg'} />}
              />
              <Label>{i18n.t('form.password.label')}</Label>
              <FormikPassword
                placeholder={i18n.t('form.password.placeholder')}
                name="password"
                shape={'rounded'}
                variant={'outline'}
              />

              <Center marginVertical={20}>
                <Button isLoading={loading} type="submit" width="100%" onClick={handleSubmit} isDisabled={loading}>
                  {i18n.t('button.login')}
                </Button>
              </Center>
            </Vertical>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
