import { useState, useEffect } from 'react';
import {
  useAccountControllerGetPaymentMethodsService,
  useAccountControllerDeletePaymentMethodService,
  useAccountControllerSetDefaultPaymentMethodService,
  useAccountControllerManualChargeService,
  useAccountControllerUseCreditsService,
  useAccountControllerUpdateAutoChargeSettingsService,
} from 'src/services/api/services/AccountService'; // Adjust the import path
import { Button, showModal } from '@app-studio/web';
import { v4 as uuidv4 } from 'uuid';

export const usePlanStates = ({ defaultPeriod }: { defaultPeriod: string }) => {
  const [selected, setSelected] = useState<string>(defaultPeriod);
  return { selected, setSelected };
};

export const usePaymentMethodsState = () => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string>('plan_1');
  const [credits, setCredits] = useState<number | null>(0);
  const [message, setMessage] = useState<string | null>('');
  const [autoChargeEnabled, setAutoChargeEnabled] = useState(false);
  const [autoChargeThreshold, setAutoChargeThreshold] = useState<number>(0);
  const [autoChargePlan, setAutoChargePlan] = useState<string>('plan_1');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { run: runGetPaymentMethods } = useAccountControllerGetPaymentMethodsService({
    onSuccess: (res) => {
      setPaymentMethods(res.paymentMethods);
      setDefaultPaymentMethodId(res.defaultPaymentMethodId);
    },
  });

  const { run: runDeletePaymentMethod } = useAccountControllerDeletePaymentMethodService({
    onSuccess: (res) => {
      setPaymentMethods((prevMethods) => prevMethods.filter((method) => method.id !== res.paymentMethodId));
    },
  });

  const { run: runSetDefaultPaymentMethod } = useAccountControllerSetDefaultPaymentMethodService({
    onSuccess: (res) => {
      setDefaultPaymentMethodId(res.paymentMethodId);
    },
  });

  const { run: runManualCharge } = useAccountControllerManualChargeService({
    onSuccess: (res) => {
      console.log(`Charge created successfully. Payment Intent ID: ${res.paymentIntentId}`);
    },
  });

  const { run: runUseCredits } = useAccountControllerUseCreditsService({
    onSuccess: (res) => {
      setMessage('Credits used successfully.');
    },
  });

  const { run: runUpdateAutoChargeSettings } = useAccountControllerUpdateAutoChargeSettingsService({
    onSuccess: (res) => {
      setMessage('Auto-charge settings updated successfully.');
    },
  });

  useEffect(() => {
    runGetPaymentMethods();
  }, []);

  return {
    paymentMethods,
    defaultPaymentMethodId,
    selectedPlan,
    credits,
    message,
    autoChargeEnabled,
    autoChargeThreshold,
    autoChargePlan,
    isModalOpen,
    setSelectedPlan,
    setCredits,
    setMessage,
    setAutoChargeEnabled,
    setAutoChargeThreshold,
    setAutoChargePlan,
    setIsModalOpen,
    handleDelete: async (paymentMethodId) => {
      await runDeletePaymentMethod(paymentMethodId);
      await runGetPaymentMethods();
    },
    handleSetDefault: async (paymentMethodId) => {
      await runSetDefaultPaymentMethod(paymentMethodId);
      await runGetPaymentMethods();
    },
    handleCharge: () => {
      if (!defaultPaymentMethodId) {
        alert('No default payment method set.');
        return;
      }
      const idempotencyKey = uuidv4();
      runManualCharge(defaultPaymentMethodId, selectedPlan, idempotencyKey);
    },
    handleUpdateAutoChargeSettings: () => {
      runUpdateAutoChargeSettings(autoChargeEnabled, autoChargeThreshold, autoChargePlan);
    },
    handleUseCredits: () => {
      runUseCredits(credits);
    },
    showAddCardModal: () => {
      showModal('AddCardModal', { onClose: runGetPaymentMethods });
    },
  };
};
