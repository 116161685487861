import React from 'react';
import { Button, Horizontal, showModal, Vertical } from '@app-studio/web';
import { C3 } from 'src/components/Text';

import { AssetIcon } from 'src/utils/assets';
import { TableProps } from './Newsletter.props';

export const AddNewsButton = ({ text, createNewsCallback }) => (
  <Button
    color="white"
    marginLeft="auto"
    icon={<AssetIcon name="MagicSvg" />}
    iconPosition="right"
    width="200px"
    onClick={() => showModal('CreateNewsletterModal', { key: new Date().getTime(), createNewsCallback })}
    isAuto
  >
    <C3>{text}</C3>
  </Button>
);

export const NewsTable: React.FC<TableProps> = ({ isLoaded, newsletters, headers, navigateToSource }) => (
  <Vertical>
    <Horizontal
      fontWeight="bold"
      padding="8px"
      borderWidth="0px 0px 0.5px 0px"
      borderColor="color.gray.30"
      borderStyle="solid"
    >
      {headers.map((title: string, index: number) => (
        <C3 key={index} flex={index > 1 ? 1 : 4} padding="8px">
          {title}
        </C3>
      ))}
    </Horizontal>
    {isLoaded && <Newsletter newsletters={newsletters} navigateToSource={navigateToSource} />}
  </Vertical>
);

export const Newsletter = ({ newsletters, navigateToSource }) => {
  const formattedDate = (date: number | Date | undefined) =>
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date);

  return newsletters.map((newsletter, index: number) => (
    <Horizontal
      key={index}
      padding="8px"
      borderWidth={index === 0 ? '0' : '1px 0 0 0'}
      borderColor="color.gray.30"
      onClick={() => navigateToSource(newsletter.id)}
    >
      <C3 flex={4} padding="8px">
        {newsletter.name}
      </C3>
      <C3 flex={4} padding="8px">
        {newsletter.sources?.length ?? 0}
      </C3>
      <C3 flex={1} padding="8px">
        {formattedDate(new Date(newsletter.createdAt))}
      </C3>
      <C3 flex={1} padding="8px">
        {formattedDate(new Date(newsletter.updatedAt))}
      </C3>
    </Horizontal>
  ));
};
