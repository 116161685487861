import React, { useState } from 'react';
import { Modal, hideModal, Horizontal, Vertical, TextField } from '@app-studio/web';

import { AssetImage } from 'src/utils/assets';
import { H3, H4, Label } from 'src/components/Text';
import i18n from 'src/utils/locale';

export const AddComponentModal = ({
  componentsList,
  handleAddComponent,
}: {
  componentsList: any[];
  handleAddComponent: any;
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredComponents =
    componentsList?.length > 0
      ? componentsList.filter((component) => component.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : [];

  return (
    <Modal.Container boxShadow="none" width="100%" padding="50px 80px" overflow="auto" isFullScreen>
      <Modal.Header position="relative" buttonPosition="right" onClose={hideModal} iconSize="lg">
        <H3 alignSelf="flex-start"> {i18n.t('modal.addComponent.header')}</H3>
      </Modal.Header>
      <Modal.Body border="none">
        <Vertical width="100%" height="100%">
          <Horizontal margin="50px 0" wrap={'wrap'} alignItems="center" gap={40}>
            <Label fontWeight={400}>Browse Components:</Label>
            <TextField
              name="browse"
              variant="none"
              type="text"
              placeholder="Type to search..."
              onChange={(e) => setSearchTerm(e)}
              shadow={{ boxShadow: 'rgba(0, 0, 0, 0.20) 0px 2px 6px' }}
              width="60vw"
            />
          </Horizontal>
          <Horizontal gap={40} flexWrap={'wrap'} marginTop={40} width="100%" justifyContent="space-between">
            {filteredComponents.length > 0 &&
              filteredComponents.map((component, index) => (
                <Vertical
                  key={index}
                  alignItems="center"
                  gap={20}
                  cursor={component.isAvailable && !component.taskId ? 'pointer' : 'not-allowed'}
                  opacity={component.isAvailable && !component.taskId ? 1 : 0.3}
                  onClick={
                    component.isAvailable && !component.taskId ? () => handleAddComponent(component.key) : () => {}
                  }
                >
                  <AssetImage name={component.imageUrl} width={200} height={170} />
                  <H4 fontWeight={400}>{component.name}</H4>
                </Vertical>
              ))}
          </Horizontal>
        </Vertical>
      </Modal.Body>
    </Modal.Container>
  );
};
