import React, { useState } from 'react';
import {
  Modal,
  hideModal,
  Horizontal,
  Vertical,
  TextField,
  Center,
  Loader,
  View,
  showModal,
  Text,
} from '@app-studio/web';

import { AssetImage } from 'src/utils/assets';
import { C4, H3, H4, Label } from 'src/components/Text';
import i18n from 'src/utils/locale';
import { useMount } from 'app-studio';
import { ProjectService } from 'src/services/api';
import { useNavigate } from 'react-router-dom';
import { SvgNameProp } from 'src/types/svg';
import { SVGIcon } from 'src/components/Icon';
import { useAppStore } from 'src/stores/AppStore';

export const ListProjectModal = ({ handleAddComponent }: { handleAddComponent: any }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const getAllProjectRequest = ProjectService.useProjectControllerListAllProjectsService({});

  useMount(() => {
    getAllProjectRequest.run();
  });

  const navigate = useNavigate();

  const { project, setProject } = useAppStore(({ project, setProject }) => ({ project, setProject }));

  const projects = getAllProjectRequest.data ? getAllProjectRequest.data : [];

  return (
    <Modal.Container boxShadow="none" width="100%" padding="50px 80px" overflow="auto" isFullScreen>
      <Modal.Header position="relative" buttonPosition="right" onClose={hideModal} iconSize="lg">
        <H3 alignSelf="flex-start"> {i18n.t('modal.listProjectModal.header')}</H3>
      </Modal.Header>
      <Modal.Body border="none">
        <Vertical width="100%" height="100%">
          <Horizontal gap={20} flexWrap={'wrap'} marginTop={40} width="100%" wrap="wrap">
            {projects.map((projectItem) => {
              const href = `/${projectItem.id}/project/overview`;
              return (
                <Center key={projectItem.id}>
                  <Vertical>
                    <AppIcon
                      isHighlighted={project === projectItem.id}
                      href={href}
                      icon={
                        projectItem?.projectMedias?.find((media) => media.name === 'logo')?.json.color.url ??
                        'WhiteRocketSvg'
                      }
                      handleClick={() => {
                        setProject(projectItem);
                        navigate(href);
                        hideModal();
                      }}
                    />

                    <C4 textAlign="center">{projectItem.name}</C4>
                  </Vertical>
                </Center>
              );
            })}
            <Vertical
              padding={20}
              wrap="wrap"
              borderRadius={10}
              marginRight={5}
              opacity={0.8}
              alignItems="center"
              textAlign="center"
              backgroundColor="black"
              onClick={() => {
                showModal('ProjectModal', { key: new Date().getTime(), hasProjects: true });
              }}
            >
              <Center height={130}>
                <Text textAlign="center" color="white" fontSize={40}>
                  +
                </Text>
              </Center>
              <C4 textAlign="center" color="white">
                {i18n.t('modal.listProjectModal.addProject')}
              </C4>
            </Vertical>
          </Horizontal>
        </Vertical>
      </Modal.Body>
    </Modal.Container>
  );
};

export const AppIcon = ({
  isHighlighted = false,
  href,
  icon,
  handleClick,
  ...props
}: {
  isHighlighted?: boolean;
  href: string;
  icon: SvgNameProp;
  handleClick: Function;
  [x: string]: any;
}) => {
  const isimageIcon = icon.includes('https://res.cloudinary.com');
  return (
    <Vertical
      key={href}
      padding={isimageIcon ? 0 : '15px 5px'}
      wrap="wrap"
      borderRadius={10}
      marginRight={5}
      opacity={0.8}
      alignItems="center"
      textAlign="center"
      onClick={() => {
        handleClick({ href });
      }}
      {...props}
    >
      {icon.includes('https://res.cloudinary.com') ? (
        <ImageIcon imageIcon={icon} height={150} width={150} />
      ) : (
        <SVGIcon name={icon} size={150} />
      )}
    </Vertical>
  );
};

const ImageIcon = ({ imageIcon, height = 80, width = 80 }) => {
  return (
    <View
      backgroundImage={`url(${imageIcon})`}
      width={width}
      height={height}
      backgroundRepeat="no-repeat"
      backgroundPosition={'center center'}
      backgroundSize={'80%'}
      borderRdaius={10}
    />
  );
};
