import React from 'react';
import { Formik } from 'formik';
import { Text } from 'app-studio';

import { Button, Center, FormikForm, FormikTextField, Horizontal, Loader, Vertical, View } from '@app-studio/web';

import { AssetIcon } from 'src/utils/assets';
import { Device, Mode } from './editor.props';
import { C3, C4, C5 } from 'src/components/Text';
import PopupForm from 'src/forms/PopupForm';
import i18n from 'src/utils/locale';

import { ImagePropsEditorForm } from 'src/forms/ImagePropsEditorForm';
import { ButtonPropsEditorForm } from 'src/forms/ButtonPropsEditorForm';
import { TextPropsEditorForm } from 'src/forms/TextPropsEditorForm';

export const RightMenuEditor = ({
  isLoading,
  selectedMode = 'interaction',
  selectedProps,
  handleSubmit,
  handleCancel,
  regenerateImage,
}) => {
  const { secondaryKey, callback = () => {}, initialValues } = selectedProps ?? {};

  // Check if selectedProps is not empty
  const isNotEmpty = !!selectedProps && Object.keys(selectedProps).length > 0;

  const handleFormSubmit = (updatedValues, { setSubmitting }) => {
    callback(updatedValues);
    setSubmitting(false);
  };

  return (
    <Vertical height="70vh" margin="10px 0px 0px 20px" width="200px">
      {!isLoading ? (
        <>
          {selectedMode === 'interaction' &&
            (isNotEmpty ? (
              <RightMenuInteractionEditor
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleFormSubmit}
              />
            ) : (
              <Vertical gap={10} width={160} alignItems="center">
                <AssetIcon name={'SelectorHtmlSvg'} height={20} width={20} />
                <C5 color={'#929CAD'}>{i18n.t('page.landing.interactionEditor')}</C5>
              </Vertical>
            ))}
          {selectedMode === 'text' &&
            (isNotEmpty ? (
              <RightMenuTextEditor
                secondaryKey={secondaryKey}
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleFormSubmit}
              />
            ) : (
              <Vertical gap={10} width={160} alignItems="center">
                <AssetIcon name={'SelectorHtmlSvg'} height={20} width={20} />
                <C5 color={'#929CAD'}>{i18n.t('page.landing.contentEditor')}</C5>
              </Vertical>
            ))}

          {selectedMode === 'image' &&
            (isNotEmpty ? (
              <RightMenuImageEditor
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleFormSubmit}
                regenerateImage={regenerateImage}
              />
            ) : (
              <Vertical gap={10} width={160} alignItems="center">
                <AssetIcon name={'SelectorHtmlSvg'} height={20} width={20} />
                <C5 color={'#929CAD'}>{i18n.t('page.landing.contentEditor')}</C5>
              </Vertical>
            ))}
        </>
      ) : (
        <Center width="100%" height="100%">
          <Loader loaderColor="gray" size="xl" />
        </Center>
      )}
    </Vertical>
  );
};

export const RightMenuTextEditor = ({ secondaryKey, initialValues, handleSubmit, handleCancel }) => {
  initialValues =
    secondaryKey === 'subText'
      ? {
          subText: initialValues?.subText ?? '',
        }
      : { text: initialValues?.text ?? '' };

  return (
    <Vertical height="100%">
      <TextPropsEditorForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        field={{
          header: 'Text',
          headerPlaceholder: 'Write Text...',
        }}
      />
    </Vertical>
  );
};

export const RightMenuImageEditor = ({ regenerateImage, initialValues, handleSubmit, handleCancel }) => {
  return (
    <Vertical height="100%">
      <ImagePropsEditorForm
        regenerateImage={regenerateImage}
        initialValues={{
          href: initialValues?.href ?? 'https://',
          shape: initialValues?.shape ?? 'rounded',
        }}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        field={{
          header: 'Url',
          headerPlaceholder: 'https://',
          shape: 'Shape',
          shapeOptions: [
            {
              label: 'Circle',
              value: 'circle',
            },
            { label: 'Rounded', value: 'rounded' },
            { label: 'Square', value: 'square' },
          ],
        }}
      />
    </Vertical>
  );
};
export const RightMenuInteractionEditor = ({ initialValues, handleSubmit, handleCancel }) => {
  return (
    <Vertical height="100%">
      <ButtonPropsEditorForm
        initialValues={{
          text: initialValues?.text ?? '',
          url: initialValues?.url ?? 'https://',
          openInNewTab: initialValues?.openInNewTab,
        }}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        field={{
          header: 'Text',
          headerPlaceholder: 'Button Text',
          url: 'Url',
          window: 'Open a New Window',
          options: [
            {
              label: 'No',
              value: 'no',
            },
            { label: 'Yes', value: 'yes' },
          ],
        }}
      />
    </Vertical>
  );
};

export const ModeSelector = ({ selectedMode, setSelectedMode }: { selectedMode: Mode; setSelectedMode: Function }) => {
  const modes: Mode[] = [
    { text: 'Prompt', icon: 'PromptSvg' },
    { text: 'Interaction', icon: 'SelectorHtmlSvg' },
    { text: 'Text', icon: 'ContentSvg' },
    { text: 'Image', icon: 'ImageSvg' },
  ];
  return (
    <Horizontal borderRadius={10} backgroundColor="color.gray.20" padding={10}>
      {modes.map((mode) => (
        <Horizontal
          gap={20}
          padding="10px 30px"
          backgroundColor={mode.text === selectedMode.text ? 'white' : 'inherit'}
          borderRadius={10}
          onClick={() => setSelectedMode(mode)}
        >
          <AssetIcon name={mode.icon} height={20} width={20} onClick={() => {}} />
          <C4>{mode.text}</C4>
        </Horizontal>
      ))}
    </Horizontal>
  );
};

export const DeviceSelector = ({ type, setType, onScreenClick, ...props }) => {
  const devices: Device[] = [
    { name: 'desktop', icon: 'DesktopSvg' },
    { name: 'tablet', icon: 'TabletSvg' },
    { name: 'mobile', icon: 'MobileSvg' },
    { name: 'screen', icon: 'ScreenSvg' },
  ];

  return (
    <Horizontal {...props}>
      {devices.map((device, index) => (
        <View key={device.name}>
          <View padding={'5px 8px'} backgroundColor={type === device.name ? '#DCDCDC' : 'transparent'} borderRadius={5}>
            <AssetIcon
              name={device.icon}
              height={20}
              width={20}
              onClick={() => {
                setType(device.name);
                if (device.name === 'screen') {
                  onScreenClick();
                }
              }}
            />
          </View>
        </View>
      ))}
    </Horizontal>
  );
};

export const Prompt: React.FC<any> = ({
  isDisabled = false,
  showSelector = true,
  isInspectorEnabled = false,
  isLoading,
  handleSelector,
  handleSubmit,
  initialValues,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(props: any) => (
        <FormikForm>
          <View justifyContent={'space-between'} alignItems={'center'}>
            <FormikTextField
              name="input"
              color="black"
              shape="pillShaped"
              width={'50vw'}
              height={'58px'}
              variant="outline"
              disabled={isLoading}
              rightChild={
                <SendButton
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isSubmitting={props.isSubmitting}
                  handleSubmit={props.handleSubmit}
                />
              }
              leftChild={
                showSelector ? (
                  <View height={24} width={24}>
                    {isLoading ? (
                      <Loader loaderColor="gray" />
                    ) : (
                      <AssetIcon
                        name="SelectorHtmlSvg"
                        width={!isInspectorEnabled ? 20 : 24}
                        height={!isInspectorEnabled ? 20 : 24}
                        margin="5px 0px"
                        onClick={handleSelector}
                      />
                    )}
                  </View>
                ) : null
              }
              on={{ focus: { outline: 'none' } }}
              isClearable
              styles={{
                box: { borderColor: '#E6E6E6', borderRadius: 36, padding: '0 20px' },
              }}
            />
          </View>
        </FormikForm>
      )}
    </Formik>
  );
};

const SendButton = ({ isSubmitting, isDisabled, isLoading, handleSubmit }) => {
  return (
    <Button
      shape="pillShaped"
      type="submit"
      onClick={handleSubmit}
      disabled={isSubmitting || isLoading || isDisabled}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      backgroundColor={'black'}
      paddingLeft={'0.5rem'}
      paddingRight={'0.5rem'}
      fontSize={'0.875rem'}
      fontWeight={'600'}
      color={'#ffffff'}
      boxShadow={'0 1px 2px 0 rgba(0, 0, 0, 0.05)'}
      cursor={isLoading || isDisabled ? 'not-allowed' : 'pointer'}
      opacity={isLoading || isDisabled ? '0.5' : '1'}
      width={'40px'}
      height={'40px'}
    >
      <View display={'flex'} alignItems={'center'} justifyContent={'center'} height={'24px'} width={'24px'}>
        {isLoading ? <Loader loaderColor="gray" /> : <AssetIcon name="Send_arrowSvg" white="white" />}
      </View>
    </Button>
  );
};

export const Section = ({
  name,
  id,
  isVisible,
  isLoading = false,
  isError = false,
  isDenied = false,
  selectedSection,
  onClick,
  onDelete,
  onHide,
  onMoveDown,
  onMoveUp,
  onRegenerate,
}: {
  isVisible: boolean;
  isLoading?: boolean;
  name: string;
  id: string;
  onClick: Function;
  isError: boolean;
  isDenied: boolean;
  selectedSection: any;
  onDelete: Function;
  onHide: Function;
  onMoveDown: Function;
  onMoveUp: Function;
  onRegenerate: Function;
}) => {
  const isSelected = id === selectedSection;

  // Function to stop propagation
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Vertical
      gap={18}
      backgroundColor={isSelected ? '#ECEAEA' : 'inherit'}
      padding={isSelected ? '10px 30px 15px 20px' : '0px 30px 0px 20px'}
      onClick={onClick}
      cursor={isLoading ? 'wait' : 'pointer'}
    >
      <Horizontal gap={15} alignItems="center" cursor="pointer">
        <Center
          height={41}
          width={65}
          backgroundColor={isLoading ? 'black' : isError ? '#F1F1F1' : 'gray'}
          borderRadius={10}
        >
          {isLoading && <Loader loaderColor="white" />}
          {isDenied && <AssetIcon name="RedCrossSvg" width={20} height={20} />}
          {isError && <AssetIcon name="BlackWarningSvg" width={20} height={20} />}
        </Center>
        <C3 whiteSpace="nowrap" color={isLoading ? '#888686' : 'black'}>
          {name}
        </C3>
      </Horizontal>
      {isSelected && (
        <Horizontal justifyContent="space-between">
          <AssetIcon
            name={isLoading ? 'GrayBottomArrowSvg' : 'DownArrowSvg'}
            width={16}
            height={16}
            onClick={(event) => {
              stopPropagation(event);

              onMoveUp();
            }}
          />
          <AssetIcon
            name={isLoading ? 'GrayUpArrowSvg' : 'UpArrowSvg'}
            width={16}
            height={16}
            onClick={(event) => {
              stopPropagation(event);
              onMoveDown();
            }}
          />
          <AssetIcon
            name={isLoading ? 'GrayRefreshSvg' : 'RefreshSvg'}
            width={16}
            height={16}
            onClick={(event) => {
              stopPropagation(event);
              onRegenerate();
            }}
          />
          <AssetIcon
            name={isVisible ? (isLoading ? 'GrayCloseEyeSvg' : 'HideEyeSvg') : isLoading ? 'GrayOpenEyeSvg' : 'EyeSvg'}
            width={16}
            height={16}
            onClick={(event) => {
              stopPropagation(event);
              onHide();
            }}
          />
          <AssetIcon
            name={isLoading ? 'GrayBinSvg' : 'DustinSvg'}
            width={16}
            height={16}
            onClick={(event) => {
              stopPropagation(event);
              onDelete();
            }}
          />
        </Horizontal>
      )}
    </Vertical>
  );
};

export const FailedSection = ({
  name,
  handleNo,
  handleYes,
  headlineText,
  subheadlineText,
  deleteCTAText,
  retryCTAText,
  sections,
}: {
  name: string;
  headlineText: string;
  subheadlineText: string;
  deleteCTAText: string;
  retryCTAText: string;
  handleNo: Function;
  handleYes: Function;
  sections?: any[];
}) => {
  const headlineTypo = {
    fontSize: '19px',
    fontWeight: 400,
    lineHeight: '22px',
  };
  const subHeadlineTypo = {
    fontSize: '27px',
    fontWeight: 400,
    lineHeight: '22px',
  };

  const buttonTypo = {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  };
  return (
    <Vertical gap={20} width="100%" height="100%" justifyContent="center" alignItems="center">
      <Text {...headlineTypo}>
        {headlineText} : {name}
      </Text>
      <Text {...subHeadlineTypo}>{subheadlineText}</Text>
      <Horizontal gap={50} marginTop={30}>
        <Button padding="10px 40px" onClick={() => handleNo()} isAuto>
          <Text {...buttonTypo}>{deleteCTAText}</Text>
        </Button>
        <Button padding="10px 40px" onClick={(sections) => handleYes()} isAuto>
          <Text {...buttonTypo}> {retryCTAText}</Text>
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const CreationDeniedSection = ({
  name,
  handleDelete,
  buttonText,
  headlineText,
}: {
  name: string;
  handleDelete: Function;
  buttonText: string;
  headlineText: string;
}) => {
  const headlineTypo = {
    fontSize: '19px',
    fontWeight: 400,
    lineHeight: '22px',
  };
  const buttonTypo = {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  };
  return (
    <Vertical gap={30} width="100%" height="100%" justifyContent="center" alignItems="center">
      <Text {...headlineTypo}>
        {headlineText} : {name}
      </Text>
      <Button padding="10px 40px" onClick={handleDelete} isAuto>
        <Text {...buttonTypo}>{buttonText}</Text>
      </Button>
    </Vertical>
  );
};

export const Selector = ({ initialValues, setIsInspectorEnabled, hideElementById, handleButtonSubmit }) => {
  return (
    <>
      <View id="overlay-container" />
      <PopupForm
        initialValues={initialValues}
        label={i18n.t('form.chat')}
        handleButtonCancel={() => {
          setIsInspectorEnabled(false);
          hideElementById('modal-child');
        }}
        handleButtonSubmit={handleButtonSubmit}
      />
    </>
  );
};
