import { Horizontal, Vertical, Switch, Text, Button } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { H3, C4, C3 } from 'src/components/Text';
import { useState } from 'react';
import { MainUsage, Plans, Cards } from './plan.element';
import { usePaymentMethodsState } from 'src/pages/plan/plan.state';

const PlanPage = () => {
  const plans: any = i18n.t('page.planAndUsage.plans');
  const paymentMethodsState = usePaymentMethodsState();

  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <H3>{i18n.t('page.planAndUsage.plan')}</H3>
      <MainUsage date={'May 1, 2024 - May 31, 2024'} />
      <C3>Auto recharge settings</C3>
      <Horizontal>
        <Switch
          value={paymentMethodsState.autoChargeEnabled}
          onChange={(e) => {
            paymentMethodsState.setAutoChargeEnabled(!paymentMethodsState.autoChargeEnabled);
          }}
          marginRight={20}
          colorScheme="#10A37F"
        />
        <C4>Yes, automatically recharge my card when my credit balance falls below a threshold </C4>
      </Horizontal>
      {paymentMethodsState.autoChargeEnabled && (
        <Horizontal gap={40} justifyContent="center" alignItems="center">
          <C4> When credit balance goes below </C4>

          <input
            type="number"
            id="auto-charge-threshold"
            value={paymentMethodsState.autoChargeThreshold}
            onChange={(e) => paymentMethodsState.setAutoChargeThreshold(Number(e.target.value))}
            style={{ padding: '10px', border: '1px solid #ccd0d2', borderRadius: '4px' }}
          />
          <Button
            onClick={paymentMethodsState.handleUpdateAutoChargeSettings}
            style={{ Padding: '0px', width: 'auto' }}
          >
            Update Auto-Charge Settings
          </Button>
        </Horizontal>
      )}

      <Plans
        plans={plans}
        isAutoCharge={paymentMethodsState.autoChargeEnabled}
        selectedPlan={paymentMethodsState.selectedPlan}
        handleCharge={paymentMethodsState.handleCharge}
        setSelectedPlan={paymentMethodsState.setSelectedPlan}
      />
      <Cards
        paymentMethods={paymentMethodsState.paymentMethods}
        defaultPaymentMethodId={paymentMethodsState.defaultPaymentMethodId}
        handleDelete={paymentMethodsState.handleDelete}
        handleSetDefault={paymentMethodsState.handleSetDefault}
        showAddCardModal={paymentMethodsState.showAddCardModal}
      />
    </Vertical>
  );
};
export default PlanPage;
