import { useParams } from 'react-router-dom';
import { AssistantService, ProductService } from 'src/services/api';

export const useProductsRequests = ({ handleEditorCallback, editorCallback }) => {
  const { projectId } = useParams();

  const getProductRequest = ProductService.useProductControllerGetService({});

  const updateSectionRequest = ProductService.useProductSectionControllerUpdateService({
    onSuccess: () => {
      getProductRequest.run(projectId);
      handleEditorCallback();
    },
  });

  const generateContentRequest = AssistantService.useAssistantControllerGenerateAiContentService({
    onSuccess: (data) => {
      editorCallback(data);
    },
  });

  return {
    getProductRequest,
    updateSectionRequest,
    generateContentRequest,
  };
};
