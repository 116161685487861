import React, { useEffect } from 'react';
import { Horizontal, showModal, Vertical } from '@app-studio/web';
import { View, useMount } from 'app-studio';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AppMenu,
  AddProjectMenu,
  AccountMenu,
  SectionMenu,
  AssistantMenu,
  MainApp,
  AssitantActionMenu,
} from './NavLayout.element';
import { useNavLayoutRequests } from './NavLayout.request';

import { useAppStore } from 'src/stores/AppStore';

export const NavLayout = ({ left, children, ...props }) => {
  const { projects, projectsById, device, setProject, project } = useAppStore();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = location.pathname.split('/');
  let projectId = paths[1];
  let sectionName = paths[2];
  let assistantName = paths[3];
  let assistantActionName = paths[4];

  // console.log('projectId:', projectId);
  // console.log('sectionName:', sectionName);
  // console.log('assistantName:', assistantName);

  const userRequests = useNavLayoutRequests();
  const { getAllProjectRequest } = userRequests;

  useEffect(() => {
    if (projects) {
      if (projectId) {
        if (projectsById[projectId]) {
          setProject(projectsById[projectId]);
        }
      } else {
        if (projects[0]) {
          setProject(projects[0]);
        }
      }
    }
  }, [projects, projectId]);

  useEffect(() => {
    if (projects) {
      if (projects.length == 0 && project == null) {
        openModal({ hasProjects: false });
      }
    }
  }, [project, projects]);

  const openModal = ({ hasProjects }) => {
    showModal('ProjectModal', { key: new Date().getTime(), hasProjects });
  };

  const handleAddProjectClick = () => {
    openModal({ hasProjects: true });
  };

  const handleAppClick = ({ href }) => {
    navigate(href);
  };

  const handleActionClick = ({ href }) => {
    navigate(href);
  };

  const handleAssistantClick = ({ href }) => {
    navigate(href);
  };

  if (location.pathname == '/') {
    if (projects && projects.length > 0) {
      navigate(`/${projects[0].id}/project/overview`);
    }
    children = <View flex={1} />;
  }

  const isFullScreen = device === 'screen';

  return (
    <Vertical width={'100vw'} {...props}>
      <Horizontal>
        <Horizontal height={80}>{projectId && <MainApp project={project} handleClick={handleAppClick} />}</Horizontal>
        {!isFullScreen && left}
      </Horizontal>
      <Horizontal>
        {!isFullScreen && (
          <Vertical>
            <Horizontal height={'100vh'}>
              <Vertical width={100} height={'90vh'}>
                {/* {projects && <AppMenu projects={projects} selected={project?.id} handleAppClick={handleAppClick} />} */}
                {project && (
                  <SectionMenu project={project} selected={sectionName} handleActionClick={handleActionClick} />
                )}
                <View flex={1} />
                <AddProjectMenu onClick={handleAddProjectClick} />
                <AccountMenu onClick={() => navigate('/account')} />
              </Vertical>
              {/* {project && <SectionMenu project={project} selected={sectionName} handleActionClick={handleActionClick} />} */}
              {project && sectionName && (
                <AssistantMenu
                  project={project}
                  sectionName={sectionName}
                  selected={assistantName}
                  handleAssistantClick={handleAssistantClick}
                />
              )}

              {project && sectionName && (
                <AssitantActionMenu
                  project={project}
                  assistantName={assistantName}
                  selected={assistantActionName}
                  handleAssistantClick={handleAssistantClick}
                />
              )}
            </Horizontal>
          </Vertical>
        )}

        <Vertical flex={1}>{children}</Vertical>
      </Horizontal>
    </Vertical>
  );
};

export default NavLayout;
