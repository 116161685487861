import React from 'react';
import { Button, Horizontal, showModal } from '@app-studio/web';

import i18n from 'src/utils/locale';
import { C3, H3 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';

import { SourceHeaderProps, AddSourceButtonProps, GenerateEditButtonProps } from './Sources.props';

export const SourceHeader: React.FC<SourceHeaderProps> = ({ title }) => (
  <Horizontal>
    <H3>{i18n.t('page.newsletter.title', { separator: '>', title })}</H3>
  </Horizontal>
);

export const AddSourceButton: React.FC<AddSourceButtonProps> = ({ createSourceCallback }) => (
  <Button
    color="white"
    marginLeft="auto"
    iconPosition="right"
    width="200px"
    onClick={() => showModal('AddSourceModal', { key: new Date().getTime(), createSourceCallback })}
    isAuto
  >
    <C3>{i18n.t('button.addSource')}</C3>
  </Button>
);

export const GenerateEditButton: React.FC<GenerateEditButtonProps> = ({ isLoading, text, generateContentCallback }) => (
  <Button
    color="white"
    alignSelf="center"
    marginTop="auto"
    icon={<AssetIcon name="MagicSvg" />}
    iconPosition="right"
    width="200px"
    cursor={isLoading ? 'not-allowed' : 'pointer'}
    backgroundColor={isLoading ? 'gray' : 'black'}
    isDisabled={isLoading}
    onClick={generateContentCallback}
    isAuto
  >
    <C3>{text}</C3>
  </Button>
);
