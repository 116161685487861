import { hideModal, Vertical } from '@app-studio/web';
import { useMount } from 'app-studio';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import i18n from 'src/utils/locale';
import { H3 } from 'src/components/Text';
import { useNewsletterRequests } from './Newsletter.request';
import { AddNewsButton, NewsTable } from './Newsletter.element';
import { useEffect } from 'react';

const NewsletterPage = () => {
  const { projectId } = useParams();

  const location = useLocation();
  useEffect(() => {
    getAllNewsletterRequest.run(projectId);
  }, [location.pathname]);

  const navigate = useNavigate();
  const headers: any = i18n.t('page.newsletter.headers');

  const createNewsletterRequestCallback = () => {
    getAllNewsletterRequest.run(projectId);
    hideModal();
  };

  const newsletterRequests = useNewsletterRequests({ createNewsletterRequestCallback });
  const { getAllNewsletterRequest, createNewsletterRequest } = newsletterRequests;

  useMount(() => {
    getAllNewsletterRequest.run(projectId);
  });

  const createNewsCallback = (name: string) => {
    createNewsletterRequest.run({ name, projectId });
  };

  const navigateToSource = (id: string) => {
    navigate(`/${projectId}/social/newsletter/${id}/sources`);
  };

  return (
    <Vertical width="100%" gap={50} padding="60px 40px">
      <H3>{i18n.t('page.newsletter.newsletter')}</H3>
      <AddNewsButton text={i18n.t('button.create')} createNewsCallback={createNewsCallback} />
      <NewsTable
        isLoaded={!getAllNewsletterRequest.loader && getAllNewsletterRequest.data}
        headers={headers}
        newsletters={getAllNewsletterRequest.data}
        navigateToSource={navigateToSource}
      />
    </Vertical>
  );
};
export default NewsletterPage;
