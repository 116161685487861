import { hideModal } from '@app-studio/web';
import { ApplicationService, GrantService, ProjectService } from 'src/services/api';

export const useApplicationRequests = () => {
  const getAllApplicationRequest = ApplicationService.useApplicationControllerListAllApplicationsService({});

  const createApplicationRequest = ApplicationService.useApplicationControllerCreateApplicationService({
    onSuccess: () => {
      getAllApplicationRequest.run();
    },
  });

  const getAllGrantRequest = GrantService.useGrantControllerListAllGrantsService({});
  const getAllProjectRequest = ProjectService.useProjectControllerListAllProjectsService();
  const deleteApplicationRequest = ApplicationService.useApplicationControllerRemoveApplicationService({
    onSuccess: () => {
      getAllApplicationRequest.run();
    },
  });
  return {
    getAllApplicationRequest,
    createApplicationRequest,
    getAllProjectRequest,
    getAllGrantRequest,
    deleteApplicationRequest,
  };
};
