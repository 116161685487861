import React from 'react';
import { Helmet } from 'react-helmet';
import { Horizontal, Vertical, View } from '@app-studio/web';
import LeftSide from 'src/layouts/LeftLayout';

import { Modal } from '@app-studio/web';
import PageLayout from 'src/layouts/PageLayout';

import modals from 'src/modals';

import GlobalStyle from 'src/global.style';
import styled from 'src/utils/styled';
import { useAppStore } from 'src/stores/AppStore';
import { useLocation } from 'react-router';
import { APP_NAME } from 'src/configs/AppConfig';
import { useLocaleStore } from 'src/stores/LocaleStore';
import { HeaderLayout } from './HeaderLayout';
import { GoogleFonts, IconFonts } from 'src/assets/fonts';
import { useAuthStore } from 'src/stores/AuthStore';
import { TopLayout } from './TopLayout/TopLayout';
import NavLayout from './NavLayout/NavLayout';
import { loadSpace } from '@usersnap/browser';
import LoadingLayout from './LoadingLayout';
require('react-web-vector-icons/fonts');

const AppLayout = (props: { children: React.ReactNode; online: boolean; status: string }) => {
  const { children, online, status } = props;
  const location = useLocation();

  const { ready, server, device } = useAppStore(({ ready, server, device }) => ({
    ready,
    server,
    device,
  }));

  const { language } = useLocaleStore(({ language }) => ({
    language,
  }));

  const { isAuthentificated } = useAuthStore(({ isAuthentificated }) => ({ isAuthentificated }));

  const metaprops: any = {
    htmlAttributes: { lang: language },
    title: APP_NAME,
    meta: [
      { charset: 'UTF-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
    ],
    link: GoogleFonts.map((font) => ({
      href: 'https://fonts.googleapis.com/css2?family=' + font.replace(' ', '+') + '&display=swap',
      rel: 'stylesheet',
    })),
  };

  const showHeader = location.pathname == '/';
  const showLeftSide = location.pathname.indexOf('/auth') >= 0;
  const isLandingPage = /^\/[^/]+\/dev\/landing\/[^/]+$/.test(location.pathname);

  const showApp = !isAuthentificated || showLeftSide || isLandingPage;

  const spaceApiKey = '1492e646-b54f-4109-8aaa-021d0080bb15';

  loadSpace(spaceApiKey).then((api) => {
    api.init();
  });
  return (
    <>
      <Helmet {...metaprops} />
      {ready && server && (
        <View width="100vw">
          {showApp && (
            <>
              {showHeader && <HeaderLayout />}
              <Horizontal wrap="nowrap">
                {showLeftSide && <LeftSide />}
                <Vertical wrap="nowrap" flex={1} overflowX="hidden">
                  <PageLayout width={location.pathname.indexOf('/auth') >= 0 ? '50vw' : '100%'} backgroundColor="white">
                    {children}
                  </PageLayout>
                </Vertical>
              </Horizontal>
            </>
          )}
          {!showApp && (
            <Horizontal
              width="100vw"
              height="100vh"
              maxHeight="100vh"
              backgroundColor="color.dark.90"
              overflow="hidden"
            >
              <NavLayout left={<TopLayout height={80} />}>
                <PageLayout width={'100%'} backgroundColor="white" borderTopLeftRadius={10}>
                  {children}
                </PageLayout>
              </NavLayout>
            </Horizontal>
          )}
        </View>
      )}
      <Modal.Layout modals={modals} />
      <LoadingLayout online={online} status={status} server={server} />
      <FontStyle />
      <GlobalStyle />
    </>
  );
};
const FontStyle = styled.div`
  ${IconFonts.map(
    (font) => `
    @font-face {
      font-family: '${font}';
      src: url(${require(`react-web-vector-icons/fonts/${font}.ttf`)});
    }


  `
  ).join('')}
`;

export default AppLayout;
