import { useNavigate, useParams } from 'react-router-dom';
import { useMount } from 'app-studio';
import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';

import { useNewsletterEditorRequests } from '../newsletterEditor/NewsletterEditor.request';
import { HeaderButtons, Section } from '../newsletterEditor/NewsletterEditor.element';

import i18n from 'src/utils/locale';
import { useNewsletterEditorStates } from './NewsletterEditor.state';

const NewsletterEditorPage = () => {
  let section = { id: '', content: '' };

  const { projectId, id } = useParams();
  const navigate = useNavigate();
  const { isEditing, setIsEditing } = useNewsletterEditorStates();

  const refresh = () => {
    getNewsletterRequest.run(id);
    setIsEditing(false);
    hideModal();
  };

  const editorCallback = (content: string) => {
    section.content = content;
    showEditorModal({ content });
  };

  const newsletterRequests = useNewsletterEditorRequests({ refresh, editorCallback });

  const { getNewsletterRequest, updateSectionRequest, createContentRequest, generateContentRequest } =
    newsletterRequests;

  useMount(() => {
    getNewsletterRequest.run(id);
  });

  const handleRegenerate = () => {
    createContentRequest.run(id, { language: 'english' });
  };

  const isLoaded = !getNewsletterRequest.loader && !createContentRequest.loader && getNewsletterRequest.data;

  const handleSubmit = async (values) => {
    const { prompt } = values;
    showEditorModal({ content: section.content, isLoading: true });
    generateContentRequest.run({ prompt, content: section.content });
  };

  const handleButtonSave = () => {
    updateSectionRequest.run(section.id, { content: section.content });
  };

  const onChangeCallback = (editedContent: string) => {
    section.content = editedContent;
  };

  const handleEditorSelection = ({ id, content }: { id: string; content: string }) => {
    section.content = content;
    section.id = id;
    setIsEditing(true);
    showEditorModal({ content });
  };
  const handleClose = () => {
    setIsEditing(false);
    hideModal();
  };

  const showEditorModal = ({
    title,
    content,
    isLoading = false,
  }: {
    title?: string;
    content: string;
    isLoading?: boolean;
  }) => {
    showModal(
      'EditorModal',
      {
        key: new Date().getTime(),
        handleOnClick: handleClose,
        sectionContent: content,
        initialValues: {
          prompt: '',
        },
        isLoading: isLoading,
        handleSubmit: handleSubmit,
        handleCancel: handleClose,
        handleButtonSave: handleButtonSave,
        onChangeCallback: onChangeCallback,
      },
      { position: 'right', onClose: handleClose }
    );
  };

  return (
    <Vertical width="100%" height="90vh" gap={20} paddingTop={20}>
      <Vertical width="100%" height="100%" overflowY="auto">
        <HeaderButtons
          isLoading={createContentRequest.loader}
          handleRegenerate={handleRegenerate}
          generateText={getNewsletterRequest?.data?.content ? i18n.t('button.regenerate') : i18n.t('button.generate')}
          navigateTo={() => navigate(`/${projectId}/social/newsletter/${id}/sources`)}
        />
        <Vertical>
          {isLoaded ? (
            getNewsletterRequest.data.sections.map((section) => (
              <Section
                id={section.id}
                key={section.id}
                sectionId={section.id}
                isEditing={isEditing}
                editorCallback={handleEditorSelection}
                content={section.content}
              />
            ))
          ) : (
            <Center width={'100%'} height={'100%'}>
              <Loader />
            </Center>
          )}
        </Vertical>
      </Vertical>
    </Vertical>
  );
};

export default NewsletterEditorPage;
