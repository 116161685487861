import { hideModal } from '@app-studio/web';
import { useParams } from 'react-router-dom';
import { GrantService } from 'src/services/api';

export const useGrantRequests = () => {
  const { projectId } = useParams();

  const getAllGrantRequest = GrantService.useGrantControllerListAllGrantsService({});

  const createGrantRequest = GrantService.useGrantControllerCreateGrantService({
    onSuccess: () => {
      getAllGrantRequest.run(projectId);
      hideModal();
    },
  });

  const deleteGrantRequest = GrantService.useGrantControllerRemoveGrantService({
    onSuccess: () => {
      getAllGrantRequest.run(projectId);
    },
  });

  return {
    getAllGrantRequest,
    deleteGrantRequest,
    createGrantRequest,
  };
};
