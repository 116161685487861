/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEnterpriseParams } from '../models/CreateEnterpriseParams';
import type { UpdateEnterpriseParams } from '../models/UpdateEnterpriseParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a enterprise
 * @param requestBody
 * @returns any Enterprise created
 * @throws ApiError
 */
export const enterpriseControllerCreateEnterprise = (requestBody: CreateEnterpriseParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/enterprise`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an enterprise
 * @returns any Enterprise data
 * @throws ApiError
 */
export const enterpriseControllerRetrieveEnterprise = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/enterprise`,
    errors: {
      404: `Enterprise does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a enterprise
 * @returns any enterprise delete
 * @throws ApiError
 */
export const enterpriseControllerRemoveEnterprise = (): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/enterprise/delete`,
    errors: {
      403: `Incorrect credentials`,
      404: `Enterprise does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a enterprise
 * @param requestBody
 * @returns any Enterprise Updated
 * @throws ApiError
 */
export const enterpriseControllerUpdateEnterprise = (requestBody: UpdateEnterpriseParams): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/enterprise/update`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

export const useEnterpriseControllerCreateEnterpriseService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: CreateEnterpriseParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(enterpriseControllerCreateEnterprise, {
    method,
    ...options,
  });
};

export const useEnterpriseControllerRetrieveEnterpriseService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(enterpriseControllerRetrieveEnterprise, {
    method,
    ...options,
  });
};

export const useEnterpriseControllerRemoveEnterpriseService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(enterpriseControllerRemoveEnterprise, {
    method,
    ...options,
  });
};

export const useEnterpriseControllerUpdateEnterpriseService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: UpdateEnterpriseParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(enterpriseControllerUpdateEnterprise, {
    method,
    ...options,
  });
};
