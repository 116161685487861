import { ApplicationService, GrantService } from 'src/services/api';

export const useApplicationFilesRequests = () => {
  const getGrantRequest = GrantService.useGrantControllerRetrieveGrantService({});

  const getApplicationRequest = ApplicationService.useApplicationControllerRetrieveApplicationService({
    onSuccess: (data) => {
      getGrantRequest.run(data.grantId);
    },
  });

  const deleteApplicationRequest =
    ApplicationService.useApplicationFileSectionControllerRemoveApplicationFileSectionService({
      onSuccess: (data) => {
        getGrantRequest.run(data.grantId);
      },
    });

  return {
    getGrantRequest,
    getApplicationRequest,
  };
};
