import { ReactComponent as AddAppSvg } from './AddApp.svg';
import { ReactComponent as LogoAppStudioSvg } from './LogoAppStudio.svg';
import { ReactComponent as WhiteArrowUpSvg } from './WhiteArrowUp.svg';
import { ReactComponent as AddSvg } from './add.svg';
import { ReactComponent as AddProjectSvg } from './addProject.svg';
import { ReactComponent as Add_circleSvg } from './add_circle.svg';
import { ReactComponent as AiSvg } from './ai.svg';
import { ReactComponent as AppsSvg } from './apps.svg';
import { ReactComponent as ArrowLogoSvg } from './arrowLogo.svg';
import { ReactComponent as AttachSvg } from './attach.svg';
import { ReactComponent as Attach_fileSvg } from './attach_file.svg';
import { ReactComponent as Back_arrowSvg } from './back_arrow.svg';
import { ReactComponent as BlackCloseSvg } from './blackClose.svg';
import { ReactComponent as BlackWarningSvg } from './blackWarning.svg';
import { ReactComponent as BrandSvg } from './brand.svg';
import { ReactComponent as Bxs_editSvg } from './bxs_edit.svg';
import { ReactComponent as Charm_refreshSvg } from './charm_refresh.svg';
import { ReactComponent as Check_circleSvg } from './check_circle.svg';
import { ReactComponent as Chevron_leftSvg } from './chevron_left.svg';
import { ReactComponent as Chevron_rightSvg } from './chevron_right.svg';
import { ReactComponent as CloseSvg } from './close.svg';
import { ReactComponent as CommentsSvg } from './comments.svg';
import { ReactComponent as ContentSvg } from './content.svg';
import { ReactComponent as CubeSvg } from './cube.svg';
import { ReactComponent as DartSvg } from './dart.svg';
import { ReactComponent as DesktopSvg } from './desktop.svg';
import { ReactComponent as DollarSvg } from './dollar.svg';
import { ReactComponent as DownArrowSvg } from './downArrow.svg';
import { ReactComponent as Down_arrowSvg } from './down_arrow.svg';
import { ReactComponent as DustbinSvg } from './dustbin.svg';
import { ReactComponent as DustinSvg } from './dustin.svg';
import { ReactComponent as EditSvg } from './edit.svg';
import { ReactComponent as EnterpriseSvg } from './enterprise.svg';
import { ReactComponent as ErrorSvg } from './error.svg';
import { ReactComponent as Expand_lessSvg } from './expand_less.svg';
import { ReactComponent as Expand_moreSvg } from './expand_more.svg';
import { ReactComponent as EyeSvg } from './eye.svg';
import { ReactComponent as FacebookSvg } from './facebook.svg';
import { ReactComponent as Favorite_emptySvg } from './favorite_empty.svg';
import { ReactComponent as Favorite_fullSvg } from './favorite_full.svg';
import { ReactComponent as FilesSvg } from './files.svg';
import { ReactComponent as FolderSvg } from './folder.svg';
import { ReactComponent as FoldersSvg } from './folders.svg';
import { ReactComponent as Forward_arrowSvg } from './forward_arrow.svg';
import { ReactComponent as FreeSvg } from './free.svg';
import { ReactComponent as FullscreenSvg } from './fullscreen.svg';
import { ReactComponent as Fullscreen_exitSvg } from './fullscreen_exit.svg';
import { ReactComponent as FundSvg } from './fund.svg';
import { ReactComponent as GrayBinSvg } from './grayBin.svg';
import { ReactComponent as GrayBottomArrowSvg } from './grayBottomArrow.svg';
import { ReactComponent as GrayCloseEyeSvg } from './grayCloseEye.svg';
import { ReactComponent as GrayOpenEyeSvg } from './grayOpenEye.svg';
import { ReactComponent as GrayRefreshSvg } from './grayRefresh.svg';
import { ReactComponent as GrayUpArrowSvg } from './grayUpArrow.svg';
import { ReactComponent as HelpSvg } from './help.svg';
import { ReactComponent as HideEyeSvg } from './hideEye.svg';
import { ReactComponent as HomeSvg } from './home.svg';
import { ReactComponent as HourSvg } from './hour.svg';
import { ReactComponent as Hourglass_emptySvg } from './hourglass_empty.svg';
import { ReactComponent as Hourglass_fullSvg } from './hourglass_full.svg';
import { ReactComponent as Icon_park_outline_addSvg } from './icon_park_outline_add.svg';
import { ReactComponent as IdentitySvg } from './identity.svg';
import { ReactComponent as ImageSvg } from './image.svg';
import { ReactComponent as InfoSvg } from './info.svg';
import { ReactComponent as InsightSvg } from './insight.svg';
import { ReactComponent as InstagramSvg } from './instagram.svg';
import { ReactComponent as InstawhiteSvg } from './instawhite.svg';
import { ReactComponent as KeySvg } from './key.svg';
import { ReactComponent as LaunchSvg } from './launch.svg';
import { ReactComponent as LinkSvg } from './link.svg';
import { ReactComponent as LinkedinSvg } from './linkedin.svg';
import { ReactComponent as LoadSvg } from './load.svg';
import { ReactComponent as LoadingSvg } from './loading.svg';
import { ReactComponent as LockSvg } from './lock.svg';
import { ReactComponent as MagicSvg } from './magic.svg';
import { ReactComponent as MarketSvg } from './market.svg';
import { ReactComponent as MenuSvg } from './menu.svg';
import { ReactComponent as MessageSvg } from './message.svg';
import { ReactComponent as MetawhiteSvg } from './metawhite.svg';
import { ReactComponent as MobileSvg } from './mobile.svg';
import { ReactComponent as ModelEditSvg } from './modelEdit.svg';
import { ReactComponent as NewsletterSvg } from './newsletter.svg';
import { ReactComponent as OfferSvg } from './offer.svg';
import { ReactComponent as PauseSvg } from './pause.svg';
import { ReactComponent as PitchSvg } from './pitch.svg';
import { ReactComponent as Play_arrowSvg } from './play_arrow.svg';
import { ReactComponent as Play_circle_filledSvg } from './play_circle_filled.svg';
import { ReactComponent as Play_circle_outlineSvg } from './play_circle_outline.svg';
import { ReactComponent as PlusSvg } from './plus.svg';
import { ReactComponent as ProSvg } from './pro.svg';
import { ReactComponent as ProjectSvg } from './project.svg';
import { ReactComponent as PromptSvg } from './prompt.svg';
import { ReactComponent as RedCrossSvg } from './redCross.svg';
import { ReactComponent as RefreshSvg } from './refresh.svg';
import { ReactComponent as ScheduleSvg } from './schedule.svg';
import { ReactComponent as ScreenSvg } from './screen.svg';
import { ReactComponent as ScrollUpSvg } from './scrollUp.svg';
import { ReactComponent as SearchSvg } from './search.svg';
import { ReactComponent as Search2Svg } from './search2.svg';
import { ReactComponent as SectionMenuSvg } from './sectionMenu.svg';
import { ReactComponent as SelectorSvg } from './selector.svg';
import { ReactComponent as SelectorHtmlSvg } from './selectorHtml.svg';
import { ReactComponent as SendSvg } from './send.svg';
import { ReactComponent as Send_arrowSvg } from './send_arrow.svg';
import { ReactComponent as SettingSvg } from './setting.svg';
import { ReactComponent as ShareSvg } from './share.svg';
import { ReactComponent as SolarCalendarSvg } from './solarCalendar.svg';
import { ReactComponent as StarSvg } from './star.svg';
import { ReactComponent as Stars_circleSvg } from './stars_circle.svg';
import { ReactComponent as StrategySvg } from './strategy.svg';
import { ReactComponent as SwitchSvg } from './switch.svg';
import { ReactComponent as SyncSvg } from './sync.svg';
import { ReactComponent as Tabler_editSvg } from './tabler_edit.svg';
import { ReactComponent as TabletSvg } from './tablet.svg';
import { ReactComponent as TagSvg } from './tag.svg';
import { ReactComponent as TargetSvg } from './target.svg';
import { ReactComponent as TemplateSvg } from './template.svg';
import { ReactComponent as TickSvg } from './tick.svg';
import { ReactComponent as ToparrowSvg } from './toparrow.svg';
import { ReactComponent as TranslateSvg } from './translate.svg';
import { ReactComponent as TranslateIconSvg } from './translateIcon.svg';
import { ReactComponent as UpArrowSvg } from './upArrow.svg';
import { ReactComponent as UploadSvg } from './upload.svg';
import { ReactComponent as VideoUploadSvg } from './videoUpload.svg';
import { ReactComponent as VisibilitySvg } from './visibility.svg';
import { ReactComponent as Visibility_offSvg } from './visibility_off.svg';
import { ReactComponent as Volume_downSvg } from './volume_down.svg';
import { ReactComponent as Volume_muteSvg } from './volume_mute.svg';
import { ReactComponent as Volume_offSvg } from './volume_off.svg';
import { ReactComponent as Volume_upSvg } from './volume_up.svg';
import { ReactComponent as WarningSvg } from './warning.svg';
import { ReactComponent as WhiteCartSvg } from './whiteCart.svg';
import { ReactComponent as WhiteDollarSvg } from './whiteDollar.svg';
import { ReactComponent as WhiteFilesSvg } from './whiteFiles.svg';
import { ReactComponent as WhiteFolderSvg } from './whiteFolder.svg';
import { ReactComponent as WhiteFoldersSvg } from './whiteFolders.svg';
import { ReactComponent as WhiteLetterSvg } from './whiteLetter.svg';
import { ReactComponent as WhiteMagicPenSvg } from './whiteMagicPen.svg';
import { ReactComponent as WhiteProductSvg } from './whiteProduct.svg';
import { ReactComponent as WhiteProfileSvg } from './whiteProfile.svg';
import { ReactComponent as WhiteRocketSvg } from './whiteRocket.svg';
import { ReactComponent as WhiteScrollDownSvg } from './whiteScrollDown.svg';
import { ReactComponent as WhiteScrollUpSvg } from './whiteScrollUp.svg';
import { ReactComponent as WhiteTagSvg } from './whiteTag.svg';
import { ReactComponent as WhiteTargetSvg } from './whiteTarget.svg';
import { ReactComponent as Youtubewhite2Svg } from './youtubewhite2.svg';

export default {
 AddAppSvg,
 LogoAppStudioSvg,
 WhiteArrowUpSvg,
 AddSvg,
 AddProjectSvg,
 Add_circleSvg,
 AiSvg,
 AppsSvg,
 ArrowLogoSvg,
 AttachSvg,
 Attach_fileSvg,
 Back_arrowSvg,
 BlackCloseSvg,
 BlackWarningSvg,
 BrandSvg,
 Bxs_editSvg,
 Charm_refreshSvg,
 Check_circleSvg,
 Chevron_leftSvg,
 Chevron_rightSvg,
 CloseSvg,
 CommentsSvg,
 ContentSvg,
 CubeSvg,
 DartSvg,
 DesktopSvg,
 DollarSvg,
 DownArrowSvg,
 Down_arrowSvg,
 DustbinSvg,
 DustinSvg,
 EditSvg,
 EnterpriseSvg,
 ErrorSvg,
 Expand_lessSvg,
 Expand_moreSvg,
 EyeSvg,
 FacebookSvg,
 Favorite_emptySvg,
 Favorite_fullSvg,
 FilesSvg,
 FolderSvg,
 FoldersSvg,
 Forward_arrowSvg,
 FreeSvg,
 FullscreenSvg,
 Fullscreen_exitSvg,
 FundSvg,
 GrayBinSvg,
 GrayBottomArrowSvg,
 GrayCloseEyeSvg,
 GrayOpenEyeSvg,
 GrayRefreshSvg,
 GrayUpArrowSvg,
 HelpSvg,
 HideEyeSvg,
 HomeSvg,
 HourSvg,
 Hourglass_emptySvg,
 Hourglass_fullSvg,
 Icon_park_outline_addSvg,
 IdentitySvg,
 ImageSvg,
 InfoSvg,
 InsightSvg,
 InstagramSvg,
 InstawhiteSvg,
 KeySvg,
 LaunchSvg,
 LinkSvg,
 LinkedinSvg,
 LoadSvg,
 LoadingSvg,
 LockSvg,
 MagicSvg,
 MarketSvg,
 MenuSvg,
 MessageSvg,
 MetawhiteSvg,
 MobileSvg,
 ModelEditSvg,
 NewsletterSvg,
 OfferSvg,
 PauseSvg,
 PitchSvg,
 Play_arrowSvg,
 Play_circle_filledSvg,
 Play_circle_outlineSvg,
 PlusSvg,
 ProSvg,
 ProjectSvg,
 PromptSvg,
 RedCrossSvg,
 RefreshSvg,
 ScheduleSvg,
 ScreenSvg,
 ScrollUpSvg,
 SearchSvg,
 Search2Svg,
 SectionMenuSvg,
 SelectorSvg,
 SelectorHtmlSvg,
 SendSvg,
 Send_arrowSvg,
 SettingSvg,
 ShareSvg,
 SolarCalendarSvg,
 StarSvg,
 Stars_circleSvg,
 StrategySvg,
 SwitchSvg,
 SyncSvg,
 Tabler_editSvg,
 TabletSvg,
 TagSvg,
 TargetSvg,
 TemplateSvg,
 TickSvg,
 ToparrowSvg,
 TranslateSvg,
 TranslateIconSvg,
 UpArrowSvg,
 UploadSvg,
 VideoUploadSvg,
 VisibilitySvg,
 Visibility_offSvg,
 Volume_downSvg,
 Volume_muteSvg,
 Volume_offSvg,
 Volume_upSvg,
 WarningSvg,
 WhiteCartSvg,
 WhiteDollarSvg,
 WhiteFilesSvg,
 WhiteFolderSvg,
 WhiteFoldersSvg,
 WhiteLetterSvg,
 WhiteMagicPenSvg,
 WhiteProductSvg,
 WhiteProfileSvg,
 WhiteRocketSvg,
 WhiteScrollDownSvg,
 WhiteScrollUpSvg,
 WhiteTagSvg,
 WhiteTargetSvg,
 Youtubewhite2Svg,
};
