/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectParams } from '../models/CreateProjectParams';
import type { CreateProjectSectionParams } from '../models/CreateProjectSectionParams';
import type { CreateProjectSupplementaryInfoParams } from '../models/CreateProjectSupplementaryInfoParams';
import type { GenerateProjectParams } from '../models/GenerateProjectParams';
import type { UpdateProjectParams } from '../models/UpdateProjectParams';
import type { UpdateProjectSectionParams } from '../models/UpdateProjectSectionParams';
import type { UpdateProjectSupplementaryInfoParams } from '../models/UpdateProjectSupplementaryInfoParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request, upload } from '../core/request';
import { useRequest, UseRequestOption, UseRequestProperties } from '@app-studio/react-request';

/**
 * As a admin, i want to create a project
 * @param requestBody
 * @returns any Project created
 * @throws ApiError
 */
export const projectControllerCreate = (requestBody: CreateProjectParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/project`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read all the project
 * @returns any Project data
 * @throws ApiError
 */
export const projectControllerListAllProjects = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/project`,
    errors: {
      404: `Projects does not exists`,
    },
  });
};

/**
 * I want to read an project
 * @param id
 * @returns any Project data
 * @throws ApiError
 */
export const projectControllerRetrieveProject = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/project/${id}`,
    errors: {
      404: `Project does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a project
 * @param id
 * @returns any project delete
 * @throws ApiError
 */
export const projectControllerRemoveProject = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/project/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Project does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a project
 * @param id
 * @param requestBody
 * @returns any Project Updated
 * @throws ApiError
 */
export const projectControllerUpdateProject = (
  id: string,
  requestBody: UpdateProjectParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/project/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to generate the project details
 * @param requestBody
 * @returns any Project Details generated successfully
 * @throws ApiError
 */
export const projectControllerGenerate = (requestBody: GenerateProjectParams): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/project/generate`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As a admin, i want to create a Section
 * @param id
 * @param requestBody
 * @returns any Project Section created
 * @throws ApiError
 */
export const projectSectionControllerCreateProjectSection = (
  id: string,
  requestBody: CreateProjectSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/project/${id}/section`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read an projectSection
 * @param id
 * @returns any Section data
 * @throws ApiError
 */
export const projectSectionControllerRetrieveProjectSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/project/section/${id}`,
    errors: {
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to delete a projectSection
 * @param id
 * @returns any projectSection delete
 * @throws ApiError
 */
export const projectSectionControllerRemoveProjectSection = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/project/section/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Section does not exists`,
    },
  });
};

/**
 * As a admin, i want to update a projectSection
 * @param id
 * @param requestBody
 * @returns any Section Updated
 * @throws ApiError
 */
export const projectSectionControllerUpdateProjectSection = (
  id: string,
  requestBody: UpdateProjectSectionParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/project/section/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to create project supplementary info
 * @param requestBody
 * @returns any Project supplementary info created
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerCreate = (
  requestBody: CreateProjectSupplementaryInfoParams
): CancelablePromise<any> => {
  return __request({
    method: 'POST',
    path: `/projecSupplementaryInfo`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * I want to read project supplementary info by projectId
 * @param id
 * @returns any Project supplementary info data
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerGet = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/projecSupplementaryInfo/${id}`,
    errors: {
      404: `Project supplementary info does not exist`,
    },
  });
};

/**
 * As an admin, I want to update project supplementary info
 * @param id
 * @param requestBody
 * @returns any Project supplementary info updated
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerUpdate = (
  id: string,
  requestBody: UpdateProjectSupplementaryInfoParams
): CancelablePromise<any> => {
  return __request({
    method: 'PATCH',
    path: `/projecSupplementaryInfo/${id}`,
    body: requestBody,
    mediaType: 'application/json',
    errors: {
      401: `Incorrect credentials`,
    },
  });
};

/**
 * As an admin, I want to delete project supplementary info
 * @param id
 * @returns any Project supplementary info deleted
 * @throws ApiError
 */
export const projectSupplementaryInfoControllerDelete = (id: string): CancelablePromise<any> => {
  return __request({
    method: 'DELETE',
    path: `/projecSupplementaryInfo/${id}`,
    errors: {
      403: `Incorrect credentials`,
      404: `Project supplementary info does not exist`,
    },
  });
};

export const useProjectControllerCreateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: CreateProjectParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerCreate, { method, ...options });
};

export const useProjectControllerListAllProjectsService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerListAllProjects, { method, ...options });
};

export const useProjectControllerRetrieveProjectService = ({ method = 'GET', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerRetrieveProject, { method, ...options });
};

export const useProjectControllerRemoveProjectService = ({ method = 'DELETE', ...options }: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerRemoveProject, { method, ...options });
};

export const useProjectControllerUpdateProjectService = ({ method = 'PATCH', ...options }: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProjectParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerUpdateProject, { method, ...options });
};

export const useProjectControllerGenerateService = ({ method = 'POST', ...options }: UseRequestOption = {}): {
  run: (requestBody: GenerateProjectParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectControllerGenerate, { method, ...options });
};

export const useProjectSectionControllerCreateProjectSectionService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: CreateProjectSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerCreateProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerRetrieveProjectSectionService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerRetrieveProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerRemoveProjectSectionService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerRemoveProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSectionControllerUpdateProjectSectionService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProjectSectionParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSectionControllerUpdateProjectSection, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerCreateService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (requestBody: CreateProjectSupplementaryInfoParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerCreate, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerGetService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerGet, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerUpdateService = ({
  method = 'PATCH',
  ...options
}: UseRequestOption = {}): {
  run: (id: string, requestBody: UpdateProjectSupplementaryInfoParams) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerUpdate, {
    method,
    ...options,
  });
};

export const useProjectSupplementaryInfoControllerDeleteService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (id: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(projectSupplementaryInfoControllerDelete, {
    method,
    ...options,
  });
};
