import React from 'react';
import { useMount } from 'app-studio';
import { Center, hideModal, Loader, showModal, Vertical } from '@app-studio/web';
import { useNavigate, useParams } from 'react-router-dom';

import useLandingRequest from './landing/landing.request';
import { Head, PageNavigation } from './landing/landing.element';

import i18n from 'src/utils/locale';
import { Table } from 'src/components/Table';

const LandingPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const createLandingCallback = (data) => {
    hideModal();
    navigate(`/${projectId}/dev/landing/${data.id}`);
  };

  const onNext = (offerId: string, name: string, data) => {
    const offer = data.find((offer) => offer.id === offerId);

    if (offer && offer.id) {
      createLandingRequest.run({ projectId, name, offerId: offer.id });
    }
  };

  const componentRequests = useLandingRequest({ createLandingCallback });

  const { getAllLandingRequest, createLandingRequest, getAllOfferRequest } = componentRequests;

  useMount(() => {
    getAllLandingRequest.run(projectId);
    getAllOfferRequest.run(projectId);
  });

  const onCreateButton = () => {
    const options =
      getAllOfferRequest &&
      getAllOfferRequest.data &&
      getAllOfferRequest.data.map(({ id, name }) => ({ value: id, label: name }));
    showModal(
      'DescribeModal',
      {
        key: new Date().getTime(),
        onNext: ({ dropdown, name }) => onNext(dropdown, name, getAllOfferRequest.data),
        initialValues: { name: '', dropdown: '' },
        position: 'row',
        textField: {
          keyName: 'name',
          label: 'Name',
          placeholder: 'e.g App-Studio',
        },
        dropdown: {
          keyName: 'dropdown',
          label: i18n.t('page.landing.offer'),
          options,
        },
      },
      { position: 'right' }
    );
  };

  const navigateTo = (id: string) => {
    navigate(`/${projectId}/dev/landing/${id}`);
  };

  return getAllLandingRequest && getAllLandingRequest.data ? (
    <Vertical width="100%">
      <Vertical width="100%" padding="60px 40px">
        <Head title={i18n.t('page.landing.title')} handleCreateButton={onCreateButton} />
        <Table
          isLandingMenu={true}
          headers={['Name', 'Creation Date']}
          menu={getAllLandingRequest.data}
          navigateTo={navigateTo}
          buttonCallback={() => {}}
        />
      </Vertical>
      <PageNavigation />
    </Vertical>
  ) : (
    <Center width={'100%'} height={'100%'}>
      <Loader />
    </Center>
  );
};

export default LandingPage;
