import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import {
  Button,
  Horizontal,
  Left,
  FormikForm,
  FormikTextField,
  FormikSelect,
  FormikSwitch,
  FormikDatePicker,
} from '@app-studio/web';
import * as Yup from 'yup';
import { AccountService } from 'src/services/api';
import { useAuthStore } from 'src/stores/AuthStore';
import { Label } from 'src/components/Label';
import { View } from 'app-studio';
import { FormItem } from 'src/components/Form';

const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().email(i18n.t('form.email.required')),
  name: Yup.string().nullable().required(i18n.t('form.lastName.required')),
  birthdate: Yup.string().nullable().required(i18n.t('form.birthdate.required')),
});

const AccountForm = (FormProps: any) => {
  const { user } = useAuthStore();

  if (!user) return null;

  const { birthdate, email, name } = user;

  const { loading, run } = AccountService.useAccountControllerUpdateService({
    onSuccess: (res) => {
      useAuthStore.getState().refresh();
    },
  });

  return (
    <Formik
      onSubmit={(values) => {
        run(values);
      }}
      validationSchema={validationSchema}
      initialValues={{
        email,
        name,
        birthdate,
      }}
    >
      {({ errors, handleSubmit, isSubmitting, values, handleChange }) => {
        return (
          <FormikForm>
            <View display="flex" flexWrap="wrap" gap={10}>
              <FormItem>
                <Label>{i18n.t('form.name.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.name.placeholder')}
                  name="name"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              {/* <FormItem>
                <Label>{i18n.t('form.email.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.email.placeholder')}
                  name="email"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem> */}
              {/* 
              <FormItem>
                <Label>{i18n.t('form.birthdate.label')}</Label>
                <FormikDatePicker
                  placeholder={i18n.t('form.birthdate.placeholder')}
                  name="birthdate"
                  type="number"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem> */}
            </View>

            <Left marginVertical={20}>
              <Button isLoading={loading} type="submit" width={200} onClick={handleSubmit}>
                {i18n.t('button.updateAccount')}
              </Button>
            </Left>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default AccountForm;
