import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FormikTextField, Vertical, View } from '@app-studio/web';

import { SendButton, FormButtons } from 'src/components/Button';
import { Editor } from 'src/components/Editor.component';
import { Label, C4 } from 'src/components/Text';
import { marked } from 'marked';

interface CustomFormProps {
  styles?: any;
  description?: string;
  rightButtonText?: string;
  leftButtonText?: string;
  buttonSaveType?: string;
  isLoading?: boolean;
  isAIEditable?: boolean;
  sectionTitle?: string;
  sectionContent: string;
  initialValues: any;
  handleSubmit: (values: any, { setSubmitting }) => void;
  sectionTitleKey?: string;
  handleCancel: () => void;
  handleButtonSave?: () => void;
  onChangeCallback: (value: string) => void;
  [x: string]: any;
}

export const EditorForm: React.FC<CustomFormProps> = ({
  styles,
  description,
  isAIEditable = true,
  buttonSaveType,
  sectionTitle,
  sectionContent,
  initialValues,
  handleSubmit,
  sectionTitleKey,
  handleCancel,
  handleButtonSave,
  onChangeCallback,
  rightButtonText,
  leftButtonText,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps) => (
        <FormikForm>
          <Vertical width="100%" height="90vh">
            {sectionTitle && (
              <Vertical gap={20} marginBottom={30}>
                <Label marginLeft={sectionTitleKey || description ? 0 : 30}>{sectionTitle}</Label>
                {description && <C4 dangerouslySetInnerHTML={{ __html: marked.parse(description) }} />}
                {sectionTitleKey && (
                  <FormikTextField
                    key={sectionTitleKey}
                    name={sectionTitleKey}
                    isClearable={false}
                    styles={{
                      box: {
                        borderColor: 'black',
                        backgroundColor: 'white',
                        borderStyle: 'solid',
                        borderWidth: 1,
                      },
                      field: { color: 'black', fontSize: 18, lineHeight: 23, fontWeight: 700 },
                    }}
                  />
                )}
              </Vertical>
            )}
            <Vertical
              backgroundColor="white"
              minHeight={250}
              overflow="auto"
              borderRadius={10}
              marginBottom={50}
              {...(styles?.editor ?? {})}
            >
              <Editor content={sectionContent} onChangeCallback={onChangeCallback} />
            </Vertical>
            {isAIEditable && (
              <View width={'100%'} {...(styles?.aiContainer ?? {})}>
                <FormikTextField
                  name="prompt"
                  color={isDisabled || formikProps.isSubmitting ? 'gray' : 'black'}
                  shape="pillShaped"
                  height={'58px'}
                  variant="outline"
                  disabled={isDisabled || formikProps.isSubmitting}
                  cursor={isDisabled || formikProps.isSubmitting ? 'wait' : 'auto'}
                  rightChild={
                    <SendButton
                      isLoading={isDisabled}
                      handleSubmit={() => {
                        setIsDisabled(true);
                        formikProps.handleSubmit();
                      }}
                    />
                  }
                  on={{ focus: { outline: 'none' } }}
                  isClearable={false}
                  styles={{
                    box: { borderColor: '#E6E6E6', backgroundColor: 'white', ...(styles?.aiForm ?? {}) },
                  }}
                />
              </View>
            )}
            <FormButtons
              styles={styles}
              type={buttonSaveType}
              leftButtonText={leftButtonText}
              rightButtonText={rightButtonText}
              isLoading={isDisabled}
              handleCancel={handleCancel}
              handleButtonSave={() => {
                setIsDisabled(true);
                if (handleButtonSave) handleButtonSave();
              }}
            />
          </Vertical>
        </FormikForm>
      )}
    </Formik>
  );
};
