import { Button, Horizontal, Vertical, View } from '@app-studio/web';
import { useNavigate } from 'react-router-dom';
import { AssetIcon } from 'src/utils/assets';
import i18n from 'src/utils/locale';
import { H3 } from './Text';

export const Header = ({ title, prefixTitle = '', ...props }) => (
  <Horizontal {...props}>
    {prefixTitle && <H3>{prefixTitle}</H3>}
    {prefixTitle && title && <H3 marginHorizontal={10}>{`>`}</H3>}
    {title && <H3>{title}</H3>}
  </Horizontal>
);

export const PageHeader = ({ isLoading = false, prefixTitle, title = '', regenerateClick = () => {}, ...props }) => {
  const navigate = useNavigate();
  return (
    <Horizontal {...props}>
      <Horizontal gap={10}>
        <H3
          onClick={() => {
            navigate(-1);
          }}
        >
          {prefixTitle}
        </H3>
        {title && title != '' && <H3>{`>`}</H3>}
        {title && <H3>{title}</H3>}
      </Horizontal>
      <Button marginLeft="auto" isDisabled={isLoading} padding={10} borderRadius={10} onClick={regenerateClick} isAuto>
        <AssetIcon name="LoadingSvg" width={20} height={20} color="white" />
      </Button>
    </Horizontal>
  );
};
export const SectionPageHeader = ({ TextComponent, textProps, editorCallback }) => {
  return (
    <Vertical gap={5}>
      <Horizontal wrap="nowrap" gap={10}>
        <TextComponent {...textProps} />
        <AssetIcon name="Bxs_editSvg" width={24} height={24} onClick={editorCallback} />
      </Horizontal>
    </Vertical>
  );
};
