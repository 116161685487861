// button.tsx
import React from 'react';
import { View } from 'app-studio';

import i18n from 'src/utils/locale';
import { FileSVG, Horizontal, Center, Button } from '@app-studio/web';
import { useNavigate } from 'react-router-dom';
import { C4, C3 } from './Text';
import { AssetIcon } from 'src/utils/assets';

export const BackToHomeButton = (props: any) => {
  const navigate = useNavigate();
  return (
    <Horizontal {...props}>
      <BackIconContainer onClick={() => navigate('/')}>
        <FileSVG src="/assets/back.svg" />
      </BackIconContainer>
      <BackLink>{i18n.t('button.backtoHome')}</BackLink>
    </Horizontal>
  );
};

const BackLink = (props) => <Center padding={10} font={'500 16px '} color={'rgba(19, 15, 38, 1)'} {...props} />;

const BackIconContainer = (props) => (
  <View
    boxSizing={'border-box'}
    alignItems={'center'}
    justifyContent={'center'}
    width={48}
    height={48}
    padding={15}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(16, 24, 40, 0.05)'}
    {...props}
  />
);

export const CreateButton = ({
  text,
  handleButtonClick,
  isLoading,
}: {
  text: string;
  handleButtonClick: Function;
  isLoading?: boolean;
}) => (
  <Button
    backgroundColor={isLoading ? 'gray' : 'black'}
    color="white"
    marginLeft="auto"
    width="200px"
    onClick={handleButtonClick}
    isDisabled={isLoading}
    isAuto
  >
    <C4>{text}</C4>
  </Button>
);

export const FormButtons = ({
  styles,
  leftButtonText = i18n.t('action.cancel') as string,
  rightButtonText = i18n.t('action.save') as string,
  isLoading = false,
  handleCancel,
  handleButtonSave,
  type = 'button',
  ...props
}: {
  styles?: any;
  leftButtonText?: string;
  rightButtonText?: string;
  isLoading?: boolean;
  handleCancel: Function;
  handleButtonSave: Function;
  type?: string;
}) => {
  const buttonStyle = {
    color: 'white',
    width: '150px',
    height: '40px',
    alignSelf: 'center',
    cursor: isLoading ? 'not-allowed' : 'pointer',
  };

  const clicks = type === 'submit' ? {} : { onClick: handleButtonSave };
  return (
    <Horizontal gap={20} width="fit-content" marginTop={20} marginLeft="auto" {...(styles?.buttons ?? {})} {...props}>
      <Button
        type="button"
        onClick={handleCancel}
        backgroundColor={isLoading ? 'gray' : '#9B9EA3'}
        {...buttonStyle}
        isDisabled={isLoading}
        isAuto
        {...(styles?.button ?? {})}
      >
        <C4>{leftButtonText}</C4>
      </Button>
      <Button
        type={type}
        {...buttonStyle}
        isDisabled={isLoading}
        backgroundColor={isLoading ? 'gray' : 'black'}
        isAuto
        {...clicks}
        {...(styles?.button ?? {})}
      >
        <C4>{rightButtonText}</C4>
      </Button>
    </Horizontal>
  );
};

export const SendButton = ({ isLoading, handleSubmit, ...props }) => (
  <Button
    type="submit"
    shape="pillShaped"
    isDisabled={isLoading}
    backgroundColor={'black'}
    padding={'0.5rem'}
    cursor={isLoading ? 'wait' : 'pointer'}
    opacity={isLoading ? '0.5' : '1'}
    width={'40px'}
    height={'40px'}
    icon={<AssetIcon name="WhiteArrowUpSvg" height={'14px'} width={'14px'} />}
    onClick={handleSubmit}
    {...props}
  />
);

export const NextButton = ({
  isLoading,
  handleSubmit,
  nextButtonName,
}: {
  isLoading?: boolean;
  handleSubmit: Function;
  nextButtonName?: string;
}) => {
  return (
    <Button
      type="submit"
      onClick={handleSubmit}
      isDisabled={isLoading}
      cursor={isLoading ? 'wait' : 'pointer'}
      opacity={isLoading ? '0.5' : '1'}
      color="white"
      width="150px"
      height="40px"
      alignSelf="end"
      marginTop={20}
      borderRadius={13.5}
      isAuto
    >
      <C3> {nextButtonName ? nextButtonName : i18n.t('action.next')}</C3>
    </Button>
  );
};

export const PrevButton = ({ onPrev }) => (
  <Button
    type="button"
    onClick={onPrev}
    color="white"
    width="150px"
    height="40px"
    backgroundColor="rgba(16, 16, 16, 0.69)"
    marginTop={20}
    borderRadius={13.5}
    isAuto
  >
    <C3>{i18n.t('action.prev')}</C3>
  </Button>
);
