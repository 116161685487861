import { create } from 'zustand';
import * as AsyncStorage from 'src/utils/localstorage';
import { AuthService, OpenAPI } from 'src/services/api';
import { setToken } from 'src/utils/request';
import { useAppStore } from './AppStore';
import { isBrowser } from 'app-studio';
import { bool } from 'yup';

type User = {
  id: string;
  blocked: boolean;
  createdAt: string;
  image?: any;
  imageUrl?: string;
  inactive: boolean;
  language: string;
  birthdate: string;
  email: string;
  enterprise: string;
  model: string;
  name: string;
  provider: string;
  role: string;
  updatedAt: string;
};

type AuthState = {
  isAuthentificated: boolean;
  token: boolean | string;
  device: boolean;
  user: null | User;
  location: { lon: number; lat: number } | null;
  setLocation: (location: any) => void;
  setToken: (token: string, me?: any, onSuccess?: Function) => void;
  login: (params: any, onSuccess?: Function) => void;
  logout: () => void;
  refresh: () => void;
};

export const useAuthStore = create<AuthState>((set, get) => ({
  isAuthentificated: false,
  token: false,
  device: false,
  user: null,
  location: null,
  setLocation: (location) => set({ location }),
  setToken: async (token, me, onSuccess) => {
    try {
      if (token) {
        setToken(token);
        await AsyncStorage.write('@token', token);
        //   await AsyncStorage.write('@email', email);

        const storedToken = await AsyncStorage.read('@token');

        try {
          set({ token: storedToken, isAuthentificated: true });

          OpenAPI.HEADERS = {
            Authorization: `Bearer ${token}`,
          };
          if (me) {
            set({ user: me });
          } else {
            const response: any = await AuthService.authUserControllerMe();
            if (response.data) {
              set({ user: response.data });
            }
          }
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          console.warn(e);
          set({ isAuthentificated: false, token: false });
        }
      } else {
        await AsyncStorage.remove('@token');
        set({ token: false });
      }
      useAppStore.getState().setReady();
    } catch (error) {
      console.warn(error);
      set({ isAuthentificated: false, token: false });
      useAppStore.getState().setReady();
    }
  },
  refresh: async () => {
    const user = get().user;
    if (user) {
      try {
        const response: any = await AuthService.authUserControllerMe();

        if (response) {
          set({ user: response.data });
        }

        console.log('response', response);
        useAppStore.getState().setReady();
      } catch (e) {
        console.warn(e);
        set({ isAuthentificated: false, token: false });
      }
    }
  },
  login: async (params, onSuccess) => {
    try {
      const { token } = await AuthService.authUserControllerSignIn(params);
      useAuthStore.getState().setToken(token, onSuccess);
    } catch (error) {
      console.warn(error);
      // Handle error
    }
  },
  onLoginSucceed: async (token) => {},
  logout: async () => {
    set({ isAuthentificated: false, token: false, user: null });
    OpenAPI.HEADERS = {};
    await AsyncStorage.remove('@token');
    await AsyncStorage.remove('@email');
    await AsyncStorage.remove('@projectData');
    ['selectedApp', 'apps', 'itemOpened', 'openAssistant'].map((key) => AsyncStorage.remove(`@${key}`));

    return true;
  },
}));

export const isAuthentificated = () => {
  try {
    return useAuthStore.getState().isAuthentificated;
  } catch {}
  return false;
};

(async () => {
  const token = await AsyncStorage.read('@token');
  const email = await AsyncStorage.read('@email');
  if (typeof token == 'string') {
    useAuthStore.getState().setToken(token);
  } else {
    useAppStore.getState().setReady();
  }
})();
