import React from 'react';
import { Modal, hideModal } from '@app-studio/web';

import { H4 } from 'src/components/Text';
import { AssetIcon } from 'src/utils/assets';
import i18n from 'src/utils/locale';
import { CreateForm } from 'src/forms/CreateForm';

export const AddSourceModal = ({ createSourceCallback }) => {
  const handleSubmit = (event) => {
    createSourceCallback(event.source);
  };

  const initialValues = {
    source: '',
  };

  return (
    <Modal.Container padding={20} boxShadow="none" border="1px solid black">
      <Modal.Header buttonPosition="none" padding={'20px 0'} position="relative">
        <H4>{i18n.t('page.newsletter.addSource')}</H4>
        <AssetIcon
          name="BlackCloseSvg"
          width={16}
          height={16}
          position="absolute"
          top={5}
          right={5}
          onClick={hideModal}
          color="black"
        />
      </Modal.Header>
      <Modal.Body border="none">
        <CreateForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleCancel={hideModal}
          fields={[{ name: 'source', placeholderKey: 'page.newsletter.sourcePlaceholder' }]}
          submitButtonLabelKey="button.add"
          cancelButtonLabelKey="action.cancel"
        />
      </Modal.Body>
    </Modal.Container>
  );
};
